/* eslint-disable default-case */
import React from 'react';
import strings from 'utils/strings';
import './styles.scss';
import moment from 'moment';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import {updateObject} from '../../../utils/reducerUtils';
import addMomentsToTestResults from '../../../utils/addMomentsToTestResults';
import getBatchedTestResults from '../../../utils/getBatchedTestResults';

import getGroupedTestAdminClassResults from '../../../api/getGroupedTestAdminClassResults';
import getAllClasses from '../../../api/getAllClasses';
import deleteClasses from '../../../api/deleteClasses';
import addClassesToGroup from '../../../api/addClassesToGroup';
import roundWithDecimals from 'utils/roundWithDecimals';
import updateLogEvent from 'api/updateLogEvent';
import InformationPane from '../../../components/InformationPane';
import getTestsAverageScore from 'api/getTestsAverageScore';

import {
	COMPLETED,
	DISTRIBUTED,
	NOT_STARTED,
	STARTED,
	TEST_TYPES,
	TEST_TYPE_NAMES,
} from 'constants/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faTrash} from '@fortawesome/free-solid-svg-icons';

const processTestResults = (testResults, recentKey) => {
	const testResultsWithStatus = testResults.map((testResult) => {
		let status;
		if (testResult.completedAt) {
			status = COMPLETED;
		} else if (testResult.usedAt) {
			status = STARTED;
		} else if (testResult.distributedAt) {
			status = DISTRIBUTED;
		} else {
			status = NOT_STARTED;
		}

		return updateObject(testResult, {
			status,
		});
	});

	const testResultsWithMoments = addMomentsToTestResults(
		testResultsWithStatus
	);

	const batchedTestResults = getBatchedTestResults(testResultsWithMoments);
	return batchedTestResults;
};

const childTypes = [
	TEST_TYPES.CHILD_FOLLOWUP,
	TEST_TYPES.YOUTH_FOLLOWUP,
	TEST_TYPES.ADULT_FOLLOWUP,
];
const allMyClasses = 'Alle mine klasser';
let sortOrder = true;
class MyClasses extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isMainChecked: false,
			singleChecks: false,
			selectedClass: '',
			isGroupPanelActive: false,
			allMyClasses: '',
			individualCandidates: [],
			selectedClasses: [],
			customTableClass: 'lit-myClass_grid',
			allClassData: this.props.classData,
			selectedClassData: this.props.classData,
			audioData: [],
			groupClassData: [],
			timeOut: false,
			silence: true,
			renderPaneAlert: false,
			selectedGroup: null,
			groupTestResults: [],
			isAddingClassToGroup: false,
			hasRelevantClasses: false,
			selectedGroupOption: '',
			isGroupDropDownOpen: false,
			selectedChooseGroupOption: '',
			isChooseGroupDropDownOpen: false,
		};
		this.chooseGroupDropdownRef = React.createRef();
		this.groupDropdownRef = React.createRef();
		this.handleClick = this.handleClick.bind(this);
	}
	componentDidMount() {
		document.addEventListener('click', this.handleOutsideClick);

		let hasRelevantClasses = false;
		const {allClassData} = this.state;

		hasRelevantClasses = allClassData.some((config) => {
			if (!config.groupId || config.ownsClass) {
				return true;
			}
		});

		this.setState({allMyClasses: allMyClasses, hasRelevantClasses});
		this.fetchAndRefreshAdminClasses();
	}
	componentWillReceiveProps() {
		let hasRelevantClasses = false;
		const {classData} = this.props;
		if (!this.state.isGroupPanelActive) {
			hasRelevantClasses = classData.some((config) => {
				if (!config.groupId || config.ownsClass) {
					return true;
				}
			});
			this.setState({
				allMyClasses: this.state.allMyClasses,
				hasRelevantClasses,
				selectedClassData: classData,
			});
		} else {
			const newClassData = [];
			for (let i = 0; i < classData.length; i++) {
				if (
					classData[i].groupId &&
					this.state.selectedGroup[0].id === classData[i].groupId
				) {
					newClassData.push(classData[i]);
					hasRelevantClasses = true;
				}
			}
			this.setState({
				allMyClasses,
				isGroupPanelActive: true,
				hasRelevantClasses: hasRelevantClasses,
				selectedClassData: newClassData,
				groupClassData: newClassData,
				isAddingClassToGroup: false,
			});
		}
	}
	componentWillUnmount() {
		document.removeEventListener('click', this.handleOutsideClick);
	}
	handleOutsideClick = (event) => {
		if (
			this.chooseGroupDropdownRef.current &&
			!this.chooseGroupDropdownRef.current.contains(event.target)
		) {
			this.setState({isChooseGroupDropDownOpen: false});
		}
		if (
			this.groupDropdownRef.current &&
			!this.groupDropdownRef.current.contains(event.target)
		) {
			this.setState({isGroupDropDownOpen: false});
		}
	};

	deleteSelectedClasses(extractedIds, subsId) {
		deleteClasses(extractedIds, subsId).then(() => {
			getAllClasses().then((classData) => {
				const adminClasses = classData.map((usersClass) => ({
					className: usersClass.className,
					classId: usersClass.classId,
				}));
				this.setState({
					selectedClassData: classData,
					allClassData: classData,
				});
			});
		});
	}

	fetchAndRefreshAdminClasses() {
		getAllClasses().then((classData) => {
			var selectedClassData = classData;
			const uncheckedClassData = [];
			for (let i = 0; i < selectedClassData.length; i++) {
				if (selectedClassData[i].checked) {
					selectedClassData[i].checked = false;
				}
				uncheckedClassData.push(selectedClassData[i]);
			}

			this.setState({
				selectedClassData: uncheckedClassData,
				allClassData: uncheckedClassData,
				isAddingClassToGroup: false,
			});
		});
	}

	getAndSetAdminClasses() {
		getAllClasses().then((classData) => {
			var selectedClassData = classData;
			const uncheckedClassData = [];
			for (let i = 0; i < selectedClassData.length; i++) {
				if (selectedClassData[i].checked) {
					selectedClassData[i].checked = false;
				}

				uncheckedClassData.push(selectedClassData[i]);
			}

			this.setState({
				selectedClassData: uncheckedClassData,
				allClassData: uncheckedClassData,
				paneMessage: ' kandidater lagt til i gruppen',
				renderPaneMessage: true,
				isAddingClassToGroup: false,
			});
		});
	}
	addSelectedClassesToGroup(classIds, groupIds) {
		const groupedClasses = {classIds, groupIds};
		if (!classIds || !groupIds) {
			return;
		}

		addClassesToGroup(groupedClasses).then((res) => {
			this.getAndSetAdminClasses();
		});
	}

	handleClick = (id, key) => {
		this.updateViewLog(id, key);
	};

	updateViewLog(id, key) {
		updateLogEvent('view', id, key, 'Results').then(() => {});
	}

	getAndSetTestCandidatesAverageScore(testData, classData) {
		const {currentUser} = this.props;
		const id = currentUser.id;
		return getTestsAverageScore(testData).then((averageZScore) => {
			return new Promise((resolve) => {
				classData.averageZscore = roundWithDecimals(averageZScore, 4);
				this.setState(
					{
						averageZScore,
					},
					resolve
				);
			});
		});
	}

	sortTableBy(identifier) {
		const {selectedClassData} = this.state;
		sortOrder = !sortOrder;
		switch (identifier) {
			case 'name':
				selectedClassData.sort(this.compareByName);
				break;
			case 'candidates':
				selectedClassData.sort(this.compareBytests);
				break;
		}
		this.setState({selectedClassData});
	}

	compareByName(a, b) {
		if (!sortOrder) {
			if (a.className.toLowerCase() < b.className.toLowerCase()) {
				return 1;
			}
			if (a.className.toLowerCase() > b.className.toLowerCase()) {
				return -1;
			}
			return 0;
		}
		if (a.className.toLowerCase() > b.className.toLowerCase()) {
			return 1;
		}
		if (a.className.toLowerCase() < b.className.toLowerCase()) {
			return -1;
		}
		return 0;
	}
	compareBytests(a, b) {
		if (!sortOrder) {
			if (a.strength < b.strength) {
				return 1;
			}
			if (a.strength > b.strength) {
				return -1;
			}
			return 0;
		}
		if (a.strength > b.strength) {
			return 1;
		}
		if (a.strength < b.strength) {
			return -1;
		}
		return 0;
	}
	renderCustomTableHeader() {
		return (
			<div>
				<div className="lit-myClass-header_grid">
					<div className="lit-myClass-header-wrapper">
						<div className="checkbox-wrapper-13">
							<input
								type="checkbox"
								disabled
								className="lit_cb_users"
								id="main_checkbox"
								onChange={(e) => {
									if (e.target.checked) {
										const checkedClassData = this.state.selectedClassData.map(
											(data) => {
												data.checked = true;
												return data;
											}
										);

										this.setState({
											isMainChecked: true,
											selectedClassData: checkedClassData,
										});
									} else {
										const checkedClassData = this.state.selectedClassData.map(
											(data) => {
												data.checked = false;
												return data;
											}
										);

										this.setState({
											isMainChecked: false,
											selectedClassData: checkedClassData,
										});
									}
								}}
							/>
						</div>

						<div className="myClass_table-title-header">
							<span
								className="className-title"
								onClick={() => {
									this.sortTableBy('name');
								}}
							>
								{`Klassenavn`}
							</span>
						</div>
						<div className="myClass_table-title-header">
							<span
								className="total-candidates"
								onClick={() => {
									this.sortTableBy('candidates');
								}}
							>
								{`Antall Kandidater`}
							</span>
						</div>
						<div
							className="myClass_table-title-header"
							onClick={() => {
								/* this.sortTableBy('tests'); */
							}}
						>
							<span>Tester Gjennomført</span>
						</div>
						<div className="myClass_table-title-header">
							<span>Gjennomsnittig Z-skåre</span>
						</div>
						<div className="myClass_table-title-header" />
					</div>
				</div>
			</div>
		);
	}
	renderCustomTable(classData) {
		return (
			<div>
				<div
					className={
						classData.active
							? 'lit-myClass_grid-active'
							: 'lit-myClass_grid'
					}
					id={classData.className}
					onClick={() => {
						if (!classData.active) {
							classData.active = true;
							this.setState({
								selectedClass: classData.className,
							});
						} else {
							classData.active = false;
							this.setState({
								selectedClass: classData.className,
							});
						}
					}}
				>
					{!this.state.isMainChecked && !this.state.singleChecks && (
						<div
							className="checkbox-wrapper-13"
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<input
								type="checkbox"
								key={classData.classId}
								className="lit_cb_users"
								/* defaultChecked={classData.checked ? true : false} */
								checked={classData.checked}
								id={3}
								onChange={(e) => {
									if (e.target.checked) {
										classData.checked = true;
										/* if(this.state.isGroupPanelActive){}
										else{
											
										} */
										this.setState({
											selectedClassData: this.state
												.selectedClassData,
										});
									} else {
										classData.checked = false;
										this.setState({
											selectedClassData: this.state
												.selectedClassData,
										});
									}
								}}
							/>
						</div>
					)}
					{this.state.isMainChecked && (
						<div
							className="checkbox-wrapper-13"
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<input
								type="checkbox"
								className="lit_cb_users"
								defaultChecked
								id={4}
								onChange={(e) => {
									if (e.target.checked) {
										classData.checked = true;
										this.setState({
											selectedClassData: this.props
												.classData,
										});
									} else {
										classData.checked = false;
										this.setState({
											selectedClassData: this.props
												.classData,
										});
									}
								}}
							/>
						</div>
					)}
					<div className="myClass_name_table-cell">
						{classData.className}
					</div>
					<div className="myClass_name_table-cell">
						{classData.strength}
					</div>
					<div className="myClass_name_table-cell">
						{this.calculateCompletedTests(
							classData.classInfo,
							this.props.batchedFilteredTestResults
						)}
					</div>
					<div className="myClass_name_table-cell">
						<span
							className="lit-average-zs"
							onClick={(event) => {
								event.stopPropagation();
								const someData = this.getFilteredTestUsers(
									classData.classInfo,
									this.props.batchedFilteredTestResults
								);
								const testData = someData.map((data) => {
									return {
										id: data.id,
										type: data.type,
										key: data.key,
									};
								});
								this.getAndSetTestCandidatesAverageScore(
									testData,
									classData
								);
							}}
						>
							{classData.averageZscore}
						</span>
					</div>
					<div className="myClass_name_table-cell-expand-btn">
						<div className="lit-list__text">
							<button
								className={
									classData.active
										? 'lit-btn lit-btn--small bg-primary5-active'
										: 'lit-btn lit-btn--small bg-primary5'
								}
							>
								{classData.active === true ? (
									<span>{'Skjul ▲'}</span>
								) : (
									<span>{'Se Tester ▼'}</span>
								)}
							</button>
						</div>
					</div>
				</div>
				{classData.active === true &&
					this.renderSubTable(
						this.getFilteredTestUsers(
							classData.classInfo,
							this.props.batchedFilteredTestResults
						)
					)}
			</div>
		);
	}
	calculateCompletedTests(classInfo, allTestUsers) {
		const allUsers = classInfo.map((data) => {
			return data.candidateId;
		});

		if (!this.state.isGroupPanelActive) {
			const tempObj = [];
			for (let i = 0; i < allTestUsers.length; i++) {
				const testsResults = allTestUsers[i].testResults;
				for (let j = 0; j < testsResults.length; j++) {
					if (testsResults[j].testUser) {
						if (
							allUsers.includes(
								testsResults[j].testUser.testUserId
							)
						) {
							tempObj.push(testsResults[j]);
						}
					}
				}
			}
			const count = tempObj.filter(
				(singleInstance) => singleInstance.completedAt
			).length;

			return `${count}/${tempObj.length}`;
		} else if (
			this.state.isGroupPanelActive &&
			this.state.groupTestResults.length
		) {
			const {groupTestResults} = this.state;
			const tempObj = [];

			for (let i = 0; i < groupTestResults.length; i++) {
				const testsResults = groupTestResults[i].testResults;
				for (let j = 0; j < testsResults.length; j++) {
					if (testsResults[j].testUser) {
						if (
							allUsers.includes(
								testsResults[j].testUser.testUserId
							)
						) {
							tempObj.push(testsResults[j]);
						}
					}
				}
			}
			const count = tempObj.filter(
				(singleInstance) => singleInstance.completedAt
			).length;

			return `${count}/${tempObj.length}`;
		}
	}
	renderSubTable(singleCandidate) {
		if (singleCandidate) {
			return (
				<div className="lit-myClass_individual-test">
					<table className="lit-tests">
						<thead>
							<tr>
								<td className="lit-test__header">
									{strings.status}
								</td>
								<td className="lit-test__header">
									{strings.testkey}
								</td>
								<td className="lit-test__header">
									{strings.type}
								</td>
								<td className="lit-test__header">
									{strings.recipient}
								</td>
								<td className="lit-test__header">
									{strings.status}
								</td>
								<td className="lit-test__action__btn__header">
									{strings.actions}
								</td>
							</tr>
						</thead>
						<tbody>
							{singleCandidate.map((candidate) => {
								const {
									id,
									key,
									testUser,
									status,
									type,
								} = candidate;
								const firstName = testUser.firstName;
								const lastName = testUser.lastName;
								const fullName = `${firstName} ${lastName}`;
								return (
									<tr className="lit-test" key={key}>
										<td className={'lit-test__col'}>
											<div className={'lit-test-status2'}>
												<div
													className={classNames({
														'lit-test-status__indicator': true,
														[status.id]: true,
													})}
												/>
												<p className="lit-test-status__label">
													{status.label}
												</p>
											</div>
										</td>
										<td className="lit-test__col">{key}</td>
										<td className="lit-test__col">
											{TEST_TYPE_NAMES[type]}
										</td>
										<td className="lit-test__col">
											{fullName || (
												<b>{strings.notdistributed}</b>
											)}
											&nbsp;
										</td>
										{this.getTestDateInfo(
											candidate.completedAt,
											candidate.usedAt,
											candidate.distributedAt
										)}
										<td className="lit-test__action__btn">
											{this.renderButton(
												type,
												id,
												status,
												key
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			);
		}
		return (
			<div>
				<p style={{color: 'white'}}>
					Dene Kandidater har ingen Gjennomførte tester
				</p>
			</div>
		);
	}
	renderButton(type, id, status, key) {
		const completed = status === COMPLETED;
		const started = status === STARTED;
		const isChildType = childTypes.includes(type);

		const destination = isChildType
			? `/single-child-test/${id}`
			: `/single-test/${id}`;

		if (completed || started) {
			return (
				<div>
					<Link
						className="lit-btn lit-btn--small bg-secondary"
						to={destination}
						onClick={() => this.handleClick(id, key)}
						target="_blank"
						rel="noopener noreferrer"
					>
						{strings.seeresults}
					</Link>
				</div>
			);
		}
		return null;
	}
	getTestDateInfo(completedAt, usedAt, distributedAt) {
		let testDateInfo;
		if (completedAt) {
			testDateInfo = (
				<td className="lit-test__col">
					<p className="lit-test-no-margin">
						{strings.completed}
						<br />
						{moment(completedAt).format('LLL')}
						&nbsp;
					</p>
				</td>
			);
		} else if (usedAt) {
			testDateInfo = (
				<td className="lit-test__col">
					<p className="lit-test-no-margin">
						{strings.started}
						<br />
						{moment(usedAt).format('LLL')}
						&nbsp;
					</p>
				</td>
			);
		} else if (distributedAt) {
			testDateInfo = (
				<td className="lit-test__col">
					<p className="lit-test-no-margin">
						{strings.distributed}
						<br />
						{moment(distributedAt).format('LLL')}
						&nbsp;
					</p>
				</td>
			);
		}

		return testDateInfo;
	}
	getFilteredTestUsers(classData, allTestUsers) {
		const allUsers = classData.map((data) => {
			return data.candidateId;
		});
		if (!this.state.isGroupPanelActive) {
			const tempObj = [];
			for (let i = 0; i < allTestUsers.length; i++) {
				const testsResults = allTestUsers[i].testResults;
				for (let j = 0; j < testsResults.length; j++) {
					if (testsResults[j].testUser) {
						if (
							allUsers.includes(
								testsResults[j].testUser.testUserId
							)
						) {
							tempObj.push(testsResults[j]);
						}
					}
				}
			}

			if (tempObj.length) {
				return tempObj;
			}
		} else if (this.state.isGroupPanelActive) {
			const {groupTestResults} = this.state;
			const tempObj = [];
			for (let i = 0; i < groupTestResults.length; i++) {
				const testsResults = groupTestResults[i].testResults;
				for (let j = 0; j < testsResults.length; j++) {
					if (testsResults[j].testUser) {
						if (
							allUsers.includes(
								testsResults[j].testUser.testUserId
							)
						) {
							tempObj.push(testsResults[j]);
						}
					}
				}
			}

			if (tempObj.length) {
				return tempObj;
			}
		}

		return null;
	}

	async getAndSetTestAdminGroupedClassTestResults(selectedGroup) {
		const selectedgroup = selectedGroup;
		if (selectedGroup !== '') {
			const testResults = await getGroupedTestAdminClassResults(
				selectedgroup
			);
			const batchedTestResults = await processTestResults(
				testResults.testResults,
				null
			);
			return await new Promise((resolve) => {
				this.setState(
					{
						allCandidates: testResults.testUsers,
						isGroupPanelActive: false,
						groupTestResults: batchedTestResults,
						isClassPanelActive: false,
					},
					resolve
				);
			});
		}
		return null;
	}

	onHandleGroupDropDownOpen = (e) => {
		const newClassData = [];
		if (e.includes('groupTitle')) {
			const newClassData = [];

			const classIds = [];
			let hasRelevantClasses = false;

			let extractedGroup = e.split('groupTitle');
			const groupName = extractedGroup[extractedGroup.length - 1];
			const chosenGroup = this.props.userGroups.filter(
				(group) => group.name === groupName
			);
			this.getAndSetTestAdminGroupedClassTestResults(chosenGroup[0].id);
			for (let i = 0; i < this.state.allClassData.length; i++) {
				if (
					this.state.allClassData[i].groupId &&
					chosenGroup[0].id === this.state.allClassData[i].groupId
				) {
					newClassData.push(this.state.allClassData[i]);
					hasRelevantClasses = true;
				}
			}
			this.setState({
				allMyClasses: e,
				isGroupPanelActive: true,
				hasRelevantClasses: hasRelevantClasses,
				selectedClassData: newClassData,
				groupClassData: newClassData,
				selectedGroup: chosenGroup,
			});
		} else {
			let hasRelevantClasses = false;
			const checkedClassData = this.props.testUsers.map((data) => {
				data.checked = false;
				return data;
			});
			for (let i = 0; i < this.state.allClassData.length; i++) {
				if (
					!this.state.allClassData[i].groupId ||
					this.state.allClassData[i].ownsClass
				) {
					newClassData.push(this.state.allClassData[i]);
					hasRelevantClasses = true;
				}
			}

			this.setState({
				isGroupPanelActive: false,
				hasRelevantClasses: hasRelevantClasses,
				selectedClassData: newClassData,
				allMyClasses: allMyClasses,
			});
		}
	};
	toggleGroupsDropdown = () => {
		this.setState((prevState) => ({
			isGroupDropDownOpen: !prevState.isGroupDropDownOpen,
		}));
	};
	toggleChooseGroupDropdown = () => {
		this.setState((prevState) => ({
			isChooseGroupDropDownOpen: !prevState.isChooseGroupDropDownOpen,
		}));
	};
	render() {
		const {
			selectedClassData,
			isGroupDropDownOpen,
			selectedGroupOption,
			selectedChooseGroupOption,
			isChooseGroupDropDownOpen,
		} = this.state;

		return (
			<div>
				<div className="lit-tests-view__header">
					{/* <div className="audio" id="audio"></div> */}
					<div className="lit-new-test__wrapper">
						<div className="lit-input-tab-grid">
							{
								<>
									{Boolean(selectedClassData.length) && (
										<button
											className="lit-mytests-header-mytest-btn-ternary"
											onClick={() => {
												const extractSelectedClasses = selectedClassData.filter(
													(data) => data.checked
												);
												let controlDeletion = false;
												if (
													!extractSelectedClasses.length
												) {
													this.setState({
														renderPaneAlert: true,
													});
												} else {
													const extractedIds = extractSelectedClasses.map(
														(classIds) => {
															if (
																classIds.ownsClass
															)
																return classIds.classId;

															controlDeletion = true;
														}
													);
													const subsId =
														extractSelectedClasses[0]
															.subscription;
													if (controlDeletion) {
														window.alert(
															'Det er ikke tillatt å slette en klasse som ikke er opprettet av deg.'
														);
													} else if (
														window.confirm(
															strings.formatString(
																strings.confirmationdeleteclass,
																`${
																	extractedIds.length >
																	1
																		? strings.multipleclasses
																		: strings.theclass
																}`
															)
														)
													) {
														this.deleteSelectedClasses(
															extractedIds,
															subsId
														);
													}
												}
												this.setState({
													allMyClasses:
														'Alle mine klasser',
												});
											}}
										>
											<FontAwesomeIcon
												className="lit-icon-btns"
												icon={faTrash}
											/>
											<p className="lit-btns-txt-negative">
												{strings.deleteclass}
											</p>
										</button>
									)}
									{/*scrollers starting div*/}
									{this.state.renderPaneMessage && (
										<InformationPane
											paneMessage={this.state.paneMessage}
											onApproval={() => {
												this.setState({
													renderPaneMessage: false,
												});
											}}
											onCloseWindow={() => {
												this.setState({
													renderPaneMessage: false,
												});
											}}
											onDisproval={() => {
												this.setState({
													renderPaneMessage: false,
												});
											}}
											category={'information'}
										/>
									)}

									{Boolean(
										this.state.allClassData.length
									) && (
										<div className="lit-View_tests">
											<div
												className="select-container"
												ref={this.groupDropdownRef}
											>
												<div
													className="select-display"
													onClick={
														this
															.toggleGroupsDropdown
													}
												>
													{selectedGroupOption ||
														'Alle mine klasser'}
													<span className="dropdown-arrow">
														▼
													</span>
												</div>
												{isGroupDropDownOpen && (
													<div className="dropdown-list-withScroller">
														<div
															className="dropdown-item"
															onClick={() => {
																this.setState({
																	selectedGroupOption:
																		'Alle mine klasser',
																	isGroupDropDownOpen: false,
																});
																this.onHandleGroupDropDownOpen(
																	'Alle mine klasser'
																);
															}}
														>
															{
																'Alle mine klasser'
															}
														</div>
														{Boolean(
															this.props
																.userGroups
																.length
														) && (
															<div
																className="dropdown-item disabled"
																onClick={() => {
																	this.setState(
																		{
																			isGroupDropDownOpen: false,
																		}
																	);
																}}
															>
																{
																	'Klasser i grupper:'
																}
															</div>
														)}
														{Boolean(
															this.props
																.userGroups
																.length
														) &&
															this.props.userGroups.map(
																(
																	groups,
																	index
																) => (
																	<div
																		className="dropdown-item"
																		key={
																			index
																		}
																		onClick={() => {
																			this.setState(
																				{
																					selectedGroupOption:
																						groups.name,
																					isGroupDropDownOpen: false,
																				}
																			);
																			this.onHandleGroupDropDownOpen(
																				`groupTitle${groups.name}`
																			);
																		}}
																	>
																		{
																			groups.name
																		}
																	</div>
																)
															)}
													</div>
												)}
											</div>
										</div>
									)}

									{this.state.isAddingClassToGroup && (
										<div
											className="select-container"
											ref={this.chooseGroupDropdownRef}
										>
											<div
												className="select-display"
												onClick={
													this
														.toggleChooseGroupDropdown
												}
											>
												{selectedChooseGroupOption ||
													this.props.userGroups[0]
														.name}
												<span className="dropdown-arrow">
													▼
												</span>
											</div>
											{isChooseGroupDropDownOpen && (
												<div className="dropdown-list">
													{this.props.userGroups.map(
														(group, index) => (
															<div
																className="dropdown-item"
																onClick={() => {
																	const chosenGroup = this.props.userGroups.filter(
																		(
																			groupp
																		) =>
																			groupp.name ===
																			group.name
																	);
																	const chosengroup =
																		chosenGroup[0];

																	this.setState(
																		{
																			groupId:
																				chosengroup.id,
																			selectedChooseGroupOption:
																				chosengroup.name,
																			isChooseGroupDropDownOpen: false,
																		}
																	);
																}}
																key={index}
															>
																{group.name}
															</div>
														)
													)}
												</div>
											)}
										</div>
									)}

									{Boolean(selectedClassData.length) && (
										<button
											className="lit-mytests-header-mytest-btn-secondary"
											onClick={() => {
												const extractSelectedClasses = selectedClassData.filter(
													(data) => data.checked
												);
												if (
													!extractSelectedClasses.length
												) {
													this.setState({
														renderPaneAlert: true,
													});
												} else if (
													!this.state
														.isAddingClassToGroup
												) {
													const classIds = extractSelectedClasses.map(
														(classIds) => {
															return classIds.classId;
														}
													);

													const groupId = this.props
														.userGroups[0].id;
													this.setState({
														isAddingClassToGroup: true,
														selectedClasses: classIds,
														groupId,
													});
												} else {
													const {
														selectedClasses,
														groupId,
													} = this.state;
													this.addSelectedClassesToGroup(
														selectedClasses,
														groupId
													);
												}
											}}
										>
											<FontAwesomeIcon
												className="lit-icon-btns"
												icon={faPlus}
											/>
											<p className="lit-btns-txt">
												{!this.state
													.isAddingClassToGroup
													? strings.addtogroup
													: ' Legg til'}
											</p>
										</button>
									)}
								</>
							}
						</div>
					</div>
				</div>

				<div className="lit-tests-view__contant">
					<div className="lit-btn_grid">
						{(!this.state.selectedClassData.length ||
							!this.state.hasRelevantClasses) && (
							<p>{strings.message_noclasses}</p>
						)}

						{this.state.hasRelevantClasses &&
							Boolean(this.state.selectedClassData.length) && (
								<div className="lit-main-candidates-contianer">
									<div className="lit_myClass_table-wrapper">
										{this.renderCustomTableHeader()}

										{selectedClassData.map(
											(data, index) => {
												if (
													!this.state
														.isGroupPanelActive
												) {
													if (
														!data.groupId ||
														data.ownsClass
													) {
														return (
															<div key={index}>
																{this.renderCustomTable(
																	data
																)}
															</div>
														);
													}
												} else {
													if (data.groupId) {
														return (
															<div key={index}>
																{this.renderCustomTable(
																	data
																)}
															</div>
														);
													}
												}
											}
										)}
									</div>
								</div>
							)}
					</div>

					{this.state.renderPaneAlert && (
						<InformationPane
							paneMessage={strings.chooseclass}
							onApproval={() => {
								this.setState({
									renderPaneAlert: false,
								});
							}}
							onCloseWindow={() => {
								this.setState({
									renderPaneAlert: false,
								});
							}}
							onDisproval={() => {
								this.setState({
									renderPaneAlert: false,
								});
							}}
							category={'information'}
						/>
					)}
				</div>
			</div>
		);
	}
}

export default MyClasses;
