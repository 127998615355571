import React from 'react';
import Editor from './editor';

class EditMunicipalityModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="lit-modal" onClick={() => this.props.onCancel()}>
				<div
					className="lit-modal__other"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<div>
						<Editor
							onEditModelConfirm={this.props.onConfirm}
							onEditModelCancel={this.props.onCancel}
							selectedMunicipality={
								this.props.selectedMunicipality
							}
                            refreshMunicipalities={this.props.refreshMunicipalities}

						/>
					</div>
				</div>
			</div>
		);
	}
}

export default EditMunicipalityModal;
