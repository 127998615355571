import React, {Component} from 'react';
import './styles.scss';
import strings from 'utils/strings';
import AlertBox from 'components/CreateNotification/alert';
import updateMunicipality from 'api/updateMunicipality';

class Editor extends Component {
	constructor(props) {
		super(props);
		const {
			selectedMunicipality: {municipality, uuid},
		} = this.props;

		this.state = {
			municipality: municipality || '',
			uuid: uuid || 'NO',
			showAlert: false,
			isConfirmedDisabled: true,
		};
	}

	handleInputChange = (e) => {
		const {name, value} = e.target;
		this.setState({
			isConfirmedDisabled: false,
		});
		if (name === 'uuid') {
			if (!value.startsWith('NO')) {
				this.setState({uuid: 'NO'});
			} else {
				this.setState({uuid: value});
			}
		} else {
			this.setState({
				[name]: value,
			});
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const {municipality, uuid} = this.state;

		const updatedUuid = uuid.startsWith('NO') ? uuid : `NO${uuid}`;
		const municipalityObject = {
			id: this.props.selectedMunicipality.id,
			municipality,
			uuid: updatedUuid,
		};

		updateMunicipality(municipalityObject).then((res) => {
			this.setState({
				isConfirmedDisabled: true,
			});
			if (!res) {
				this.setState({
					showAlert: true,
					error: true,
					success: false,
					message: 'Ops! something went wrong.',
				});
				setTimeout(() => {
					this.setState({
						showAlert: false,
					});
				}, 3000);
			} else {
				this.setState({
					showAlert: true,
					error: false,
					success: true,
					message: strings.municipalityUpdated,
					municipality: '',
					uuid: 'NO',
				});
				setTimeout(() => {
					this.setState({
						showAlert: false,
					});
					this.props.refreshMunicipalities();
				}, 3000);
			}
		});
	};

	render() {
		const {
			municipality,
			uuid,
			showAlert,
			success,
			message,
			isConfirmedDisabled,
		} = this.state;

		return (
			<div className="form-container">
				<h2>{strings.addMunicipality}</h2>
				<form onSubmit={this.handleSubmit}>
					<input
						type="text"
						name="uuid"
						placeholder={strings.municipalityNumber}
						value={uuid}
						onChange={this.handleInputChange}
					/>
					<input
						type="text"
						name="municipality"
						placeholder={strings.municipalityName}
						value={municipality}
						onChange={this.handleInputChange}
					/>
					<button
						disabled={!municipality || !uuid || isConfirmedDisabled}
						type="submit"
					>
						{strings.confirm}
					</button>
				</form>
				{showAlert && (
					<AlertBox
						type={success ? 'success' : 'error'}
						message={message}
					/>
				)}
			</div>
		);
	}
}

export default Editor;
