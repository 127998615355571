import React from 'react';
import moment from 'moment';
import DOMPurify from 'dompurify';

class NotificatioDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {selectedNotification} = this.props;
		const cleanedDetails = DOMPurify.sanitize(selectedNotification.details);

		return (
			<div className="lit-modal" onClick={() => this.props.onCancel()}>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<div className="notification-content">
						<h2 className="notification-subject">
							{selectedNotification.subject}
						</h2>

						<p className="notification-time">
							{moment(selectedNotification.createdAt).format(
								'LLL'
							)}
						</p>
						<h2 className="notification-summary">
							{selectedNotification.summary}
						</h2>
						<div
							className="notification-details"
							dangerouslySetInnerHTML={{__html: cleanedDetails}}
						/>
						<div className="button-container">
							<button
								className="confirm-button"
								onClick={this.props.onConfirm}
							>
								OK
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default NotificatioDetails;
