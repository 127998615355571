import {SET_CURRENT_USER} from 'actions/index';

export default function currentUser(state = null, action) {
	switch (action.type) {
		case SET_CURRENT_USER:
			return action.user;
		default:
			return state;
	}
}
