import React from 'react';
import './styles.scss';
import TextEditor from './TextEditor';

class CreateNotification extends React.Component {
	constructor() {
		super();

		this.state = {};
	}

	render() {
		const {} = this.state;

		return (
			<div>
				<TextEditor />
			</div>
		);
	}
}

export default CreateNotification;
