import React from 'react';
import {connect} from 'react-redux';
import './styles.scss';
import ViewNotifications from 'components/ViewNotifications';

class Notifications extends React.Component {
	constructor() {
		super();

		this.state = {};
	}

	render() {
		return (
			<div className="notification-list">
				<ViewNotifications />
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
