import React from 'react';
import {connect} from 'react-redux';
import './styles.scss';
import Tests from './Tests';
import Notification from './notification';

class ControlPanel extends React.Component {
	constructor() {
		super();

		this.state = {
			showTests: true,
			showNotification: false,
			showTestsActive: 'tablink1-active',
			showNotificationActive: 'tablink2',
			animator: 'marker-tests',
		};
	}

	render() {
		return (
			<div className="lit-users">
				<div>
					<nav>
						<div id={this.state.animator}></div>
						<a
							href="#"
							className={this.state.showTestsActive}
							onClick={() => {
								this.setState({
									showTests: true,
									showNotification: false,
									showTestsActive: 'tablink1-active',
									showNotificationActive: 'tablink2',
									animator: 'marker-tests',
								});
							}}
						>
							Tester
						</a>
						<a
							href="#"
							className={this.state.showNotificationActive}
							onClick={() => {
								this.setState({
									showTests: false,
									showNotification: true,
									showTestsActive: 'tablink1',
									showNotificationActive: 'tablink2-active',
									animator: 'marker-notification',
								});
							}}
						>
							Varslinger
						</a>
					</nav>
				</div>
				{this.state.showTests && !this.state.showNotification && (
					<Tests />
				)}
				{!this.state.showTests && this.state.showNotification && (
					<Notification />
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ControlPanel);
