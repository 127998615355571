import React, {Component} from 'react';

import {
	TEST_STEPS,
	TEST_STEPS_BY_ID,
	YOUTH_TEST_STEPS_BY_ID,
	CHILD_SCRNG_TEST_STEPS_BY_ID,
	CHILD_TEST_STEPS_BY_ID,
	Z_SCORE_OFFSET,
	Z_SCORE_MAX,
} from '../../../constants';
import classNames from 'classnames';

import roundWithDecimals from 'utils/roundWithDecimals';

import normalDistributionImage from 'assets/kurve.png';
import strings from 'utils/strings';

class SubtestReport extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	// eslint-disable-next-line complexity
	returnTestScores = (
		id,
		totalCorrect,
		testType,
		flag,
		zScore,
		tScore,
		scaledScore,
		standardScore
	) => {
		if (flag === 'zscore') {
			switch (id) {
				case 0:
					if (totalCorrect < 5 && testType === 'youth-followup')
						return `: < ` + roundWithDecimals(zScore, 2);
					else if (
						totalCorrect > 17 &&
						testType === 'youth-followup'
					) {
						return `: > ` + roundWithDecimals(zScore, 2);
					} else if (
						totalCorrect < 17 &&
						totalCorrect > 5 &&
						testType === 'youth-followup'
					) {
						return `: ` + roundWithDecimals(zScore, 2);
					}
					return `: ` + roundWithDecimals(zScore, 2);
				case 1:
					return `: ` + roundWithDecimals(zScore, 2);
				case 2:
					return `: ` + roundWithDecimals(zScore, 2);
				case 3:
					return `: ` + roundWithDecimals(zScore, 2);
				case 4:
					if (totalCorrect < 62 && testType === 'youth-followup') {
						return `: < ` + roundWithDecimals(zScore, 2);
					} else if (
						testType === 'youth-followup' &&
						totalCorrect > 115
					) {
						return `: > ` + roundWithDecimals(zScore, 2);
					} else if (
						testType === 'youth-followup' &&
						totalCorrect < 115 &&
						totalCorrect > 62
					) {
						return `: ` + roundWithDecimals(zScore, 2);
					}
					return `: ` + roundWithDecimals(zScore, 2);
				case 5:
					return `: ` + roundWithDecimals(zScore, 2);
				case 6:
					return `: ` + roundWithDecimals(zScore, 2);
				case 7:
					return `: ` + roundWithDecimals(zScore, 2);
				case 8:
					return `: ` + roundWithDecimals(zScore, 2);
				case 9:
					return `: ` + roundWithDecimals(zScore, 2);
			}
		}
		if (flag === 'tscore') {
			switch (id) {
				case 0:
					if (totalCorrect < 5 && testType === 'youth-followup') {
						return `: < ` + roundWithDecimals(tScore, 0);
					} else if (
						totalCorrect > 17 &&
						testType === 'youth-followup'
					) {
						return `: > ` + roundWithDecimals(tScore, 0);
					} else if (
						totalCorrect < 17 &&
						totalCorrect > 5 &&
						testType === 'youth-followup'
					) {
						return `: ` + roundWithDecimals(tScore, 0);
					}
					return `: ` + roundWithDecimals(tScore, 0);
				case 1:
					return `: ` + roundWithDecimals(tScore, 0);
				case 2:
					return `: ` + roundWithDecimals(tScore, 0);
				case 3:
					return `: ` + roundWithDecimals(tScore, 0);
				case 4:
					if (totalCorrect < 62 && testType === 'youth-followup') {
						return `: < ` + roundWithDecimals(tScore, 0);
					} else if (
						testType === 'youth-followup' &&
						totalCorrect > 115
					) {
						return `: > ` + roundWithDecimals(tScore, 0);
					} else if (
						testType === 'youth-followup' &&
						totalCorrect < 115 &&
						totalCorrect > 62
					) {
						return `: ` + roundWithDecimals(tScore, 0);
					}
					return `: ` + roundWithDecimals(tScore, 0);
				case 5:
					return `: ` + roundWithDecimals(tScore, 0);
				case 6:
					return `: ` + roundWithDecimals(tScore, 0);
				case 7:
					return `: ` + roundWithDecimals(tScore, 0);
				case 8:
					return `: ` + roundWithDecimals(tScore, 0);
				case 9:
					return `: ` + roundWithDecimals(tScore, 0);
			}
		}
		if (flag === 'scaledscore') {
			switch (id) {
				case 0:
					if (totalCorrect < 5 && testType === 'youth-followup') {
						return `: < ` + roundWithDecimals(scaledScore, 0);
					} else if (
						totalCorrect > 17 &&
						testType === 'youth-followup'
					) {
						return `: > ` + roundWithDecimals(scaledScore, 0);
					} else if (
						totalCorrect < 17 &&
						totalCorrect > 5 &&
						testType === 'youth-followup'
					) {
						return `: ` + roundWithDecimals(scaledScore, 0);
					}
					return `: ` + roundWithDecimals(scaledScore, 0);
				case 1:
					return `: ` + roundWithDecimals(scaledScore, 0);
				case 2:
					return `: ` + roundWithDecimals(scaledScore, 0);
				case 3:
					return `: ` + roundWithDecimals(scaledScore, 0);
				case 4:
					if (totalCorrect < 62 && testType === 'youth-followup') {
						return `: < ` + roundWithDecimals(scaledScore, 0);
					} else if (
						testType === 'youth-followup' &&
						totalCorrect > 115
					) {
						return `: > ` + roundWithDecimals(scaledScore, 0);
					} else if (
						testType === 'youth-followup' &&
						totalCorrect < 115 &&
						totalCorrect > 62
					)
						return `: ` + roundWithDecimals(scaledScore, 0);

					return `: ` + roundWithDecimals(scaledScore, 0);

				case 5:
					return `: ` + roundWithDecimals(scaledScore, 0);
				case 6:
					return `: ` + roundWithDecimals(scaledScore, 0);
				case 7:
					return `: ` + roundWithDecimals(scaledScore, 0);
				case 8:
					return `: ` + roundWithDecimals(scaledScore, 0);
				case 9:
					return `: ` + roundWithDecimals(scaledScore, 0);
			}
		}
		if (flag === 'standardScore') {
			switch (id) {
				case 0:
					if (totalCorrect < 5 && testType === 'youth-followup') {
						return `: < ` + roundWithDecimals(standardScore, 0);
					} else if (
						totalCorrect > 17 &&
						testType === 'youth-followup'
					) {
						return `: > ` + roundWithDecimals(standardScore, 0);
					} else if (
						totalCorrect < 17 &&
						totalCorrect > 5 &&
						testType === 'youth-followup'
					) {
						return `: ` + roundWithDecimals(standardScore, 0);
					}
					return `: ` + roundWithDecimals(standardScore, 0);
				case 1:
					return `: ` + roundWithDecimals(standardScore, 0);
				case 2:
					return `: ` + roundWithDecimals(standardScore, 0);
				case 3:
					return `: ` + roundWithDecimals(standardScore, 0);
				case 4:
					if (totalCorrect < 62 && testType === 'youth-followup') {
						return `: < ` + roundWithDecimals(standardScore, 0);
					} else if (
						testType === 'youth-followup' &&
						totalCorrect > 115
					) {
						return `: > ` + roundWithDecimals(standardScore, 0);
					} else if (
						testType === 'youth-followup' &&
						totalCorrect < 115 &&
						totalCorrect > 62
					) {
						return `: ` + roundWithDecimals(standardScore, 0);
					}
					return `: ` + roundWithDecimals(standardScore, 0);

				case 5:
					return `: ` + roundWithDecimals(standardScore, 0);
				case 6:
					return `: ` + roundWithDecimals(standardScore, 0);
				case 7:
					return `: ` + roundWithDecimals(standardScore, 0);
				case 8:
					return `: ` + roundWithDecimals(standardScore, 0);
				case 9:
					return `: ` + roundWithDecimals(standardScore, 0);
			}
		}
	};

	render() {
		const {subtest, type} = this.props;
		const {
			totalCorrect,
			id,
			zScore,
			percentile,
			tScore,
			scaledScore,
			standardScore,
			vars,
		} = subtest;

		const zScoreImageLabelWidth = 20.79;
		const zScoreImageRightPaddingWidth = 2.25;
		const zScoreChartWidth =
			100 - zScoreImageRightPaddingWidth - zScoreImageLabelWidth;
		let subtestInfo = null;
		switch (type) {
			case 'child':
				subtestInfo = CHILD_TEST_STEPS_BY_ID[id];
				break;
			case 'adult-screening':
				subtestInfo = TEST_STEPS_BY_ID[id];
				break;
			case 'youth-screening':
				subtestInfo = YOUTH_TEST_STEPS_BY_ID[id];
				break;
			case 'child-screening':
				subtestInfo = CHILD_SCRNG_TEST_STEPS_BY_ID[id];
				break;
			case 'child-experimental':
				subtestInfo = CHILD_TEST_STEPS_BY_ID[id];
				break;
		}

		const subtestQuestionCount = subtestInfo.questionCount;
		const subtestLabel = subtestInfo.label;

		let totalCorrectLabel;

		if (type === 'child' || type === 'child-experimental') {
			let myVars;
			if (vars) {
				myVars = JSON.parse(vars);
			}
			switch (id) {
				case 0: {
					totalCorrectLabel = `${totalCorrect}  rette av ${subtestQuestionCount} mulige`;
					break;
				}
				case 3: {
					let totalErrors = 0;
					let duration = 0;
					if (myVars && myVars.duration) {
						duration = myVars.duration;
						totalErrors = myVars.totalErrors;
					}
					totalCorrectLabel = `\nTid brukt: ${Math.round(
						duration / 1000
					)} sekunder\nAntall feil: ${totalErrors}`;
					break;
				}
				case 4: {
					totalCorrectLabel = `${totalCorrect}  ${strings.wordreadcorrectly}`;
					break;
				}
				case 5: {
					let totalCorrect = 0;
					let duration = 0;
					if (myVars && myVars.duration) {
						duration = myVars.duration;
						totalCorrect = myVars.totalCorrect;
					}
					totalCorrectLabel = `${totalCorrect} rette av 40 mulige på ${Math.round(
						duration / 1000
					)} sekunder\n`;
					break;
				}
				case 12: {
					totalCorrectLabel = `${totalCorrect}  ${strings.wordreadcorrectly}`;
					break;
				}
				default: {
					totalCorrectLabel = `${totalCorrect} av ${subtestQuestionCount}`;
				}
			}
		} else if (type !== 'child' && type !== 'child-experimental') {
			if (subtestInfo.name === TEST_STEPS.SKRIVEHASTIGHET) {
				totalCorrectLabel = `${totalCorrect} ${strings.wordsperminute}`;
			} else {
				totalCorrectLabel = `${totalCorrect} av ${subtestQuestionCount}`;
			}
		}
		let updatedZScore;
		if (zScore < -4) {
			updatedZScore = -4;
		} else if (zScore > 4) {
			updatedZScore = 4;
		} else {
			updatedZScore = zScore;
		}
		const testZScorePercentage =
			(updatedZScore + Z_SCORE_OFFSET) / Z_SCORE_MAX;
		const testZChartBarLeft =
			zScoreImageLabelWidth + testZScorePercentage * zScoreChartWidth;

		return (
			<div className="sub-test" key={id}>
				<h3 className="sub-test__name">{subtestLabel}</h3>
				<div className="sub-test__scores">
					<div className="sub-test__col">
						<div className="lit-z-chart">
							<img
								className="lit-z-chart__figure"
								src={normalDistributionImage}
								alt="Graph with normal distribution chart"
							/>
							<div
								className="lit-z-chart-bar"
								style={{
									left: `${testZChartBarLeft}%`,
								}}
							>
								<div
									className={classNames({
										'lit-z-chart-bar__fill': true,
										positive: zScore > 1,
										negative: zScore < -1,
									})}
								/>
							</div>
						</div>
					</div>
					<div className="sub-test__col">
						<h4 className="sub-test__heading">
							{strings.overview}
						</h4>
						<div className="sub-test-horizontal-chart">
							<p className="sub-test__value">
								<strong>{strings.rawscore}</strong>
								{`: `}
								{totalCorrectLabel}
							</p>
							<p className="sub-test__value">
								<strong>{strings.zscore}</strong>
								{/* {`: `*/}

								{this.returnTestScores(
									id,
									totalCorrect,
									this.props.testType,
									'zscore',
									zScore,
									tScore,
									scaledScore,
									standardScore
								)}
							</p>
							{type !== 'child' &&
								type !== 'youth-screening' &&
								type !== 'child-screening' && (
									<p className="sub-test__value">
										<strong>
											{strings.percentilescore}
										</strong>
										{`: `}
										{roundWithDecimals(percentile, 0)}
									</p>
								)}
							<p className="sub-test__value">
								<strong>{strings.tscore}</strong>
								{this.returnTestScores(
									id,
									totalCorrect,
									this.props.testType,
									'tscore',
									zScore,
									tScore,
									scaledScore,
									standardScore
								)}
							</p>
							<p className="sub-test__value">
								<strong>{strings.scaledscore}</strong>
								{this.returnTestScores(
									id,
									totalCorrect,
									this.props.testType,
									'scaledscore',
									zScore,
									tScore,
									scaledScore,
									standardScore
								)}
							</p>
							{type != 'adult-screening' && (
								<p className="sub-test__value">
									<strong>{'Standard skåre'}</strong>
									{this.returnTestScores(
										id,
										totalCorrect,
										this.props.testType,
										'standardScore',
										zScore,
										tScore,
										scaledScore,
										standardScore
									)}
								</p>
							)}
							<p className="sub-test__value rating">
								{strings.assessment}
								{zScore <= -1 && (
									<span className="sub-test__assessmentText">
										{strings.formatString(
											strings.assessmentsubtestbelow,
											roundWithDecimals(zScore, 2),
											<span className="sub-test__nobBreakText">
												<em>{strings.under}</em>
											</span>
										)}
										<br></br>
										<span className="sub-test__nobBreakText">
											{strings.theAverage}
										</span>
									</span>
								)}
								{zScore >= 1 && (
									<span className="sub-test__assessmentText">
										{strings.formatString(
											strings.assessmentsubtestbelow,
											roundWithDecimals(zScore, 2),

											<em>{strings.over}</em>
										)}
										<br></br>
										<span className="sub-test__nobBreakText">
											{strings.theAverage}
										</span>
									</span>
								)}
								{zScore < 1 && zScore > -1 && (
									<span className="sub-test__assessmentText">
										{strings.assessmentsubtestnormal}
									</span>
								)}
							</p>
						</div>
					</div>
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default SubtestReport;
