import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import getEventLog from 'api/getEventLog';

import {} from 'constants/index';

import './styles.scss';
import strings from 'utils/strings';
import {
	STATUS,
	ALL,
	TEST_ACTIVITY,
	USER_ACTIVITY,
	SUPER_ACTIVITY,
} from 'constants/index';
const statusFilters = [
	STATUS,
	ALL,
	TEST_ACTIVITY,
	USER_ACTIVITY,
	SUPER_ACTIVITY,
];
class EventLog extends React.Component {
	constructor() {
		super();

		this.state = {
			events: [],
			pageNumber: 1,
			fetchMorePages: true,
			filteredEvents: [],
			activeFilter: ALL,
		};
		this.handleScroll = this.handleScroll.bind(this);
		this.getEvents = this.getEvents.bind(this);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
		this.getEvents();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.pageNumber !== this.state.pageNumber) {
			if (this.state.fetchMorePages) {
				this.getEvents();
			}
		}
	}

	getEvents() {
		getEventLog(this.state.pageNumber).then((events) => {
			if (events.length > 0) {
				const updatedEvents = this.state.events.concat(events);
				const {activeFilter} = this.state;
				let filteredEvents = [];
				if (activeFilter === ALL) {
					filteredEvents = updatedEvents;
				} else {
					switch (activeFilter.id) {
						case activeFilter.id:
							filteredEvents = updatedEvents.filter(
								(singleEvent) => {
									if (
										singleEvent.category === activeFilter.id
									) {
										return singleEvent;
									}
								}
							);
							break;
					}
				}
				this.setState({
					events: updatedEvents,
					filteredEvents: filteredEvents,
				});
			} else {
				this.setState({
					fetchMorePages: false,
				});
			}
		});
	}

	handleScroll = () => {
		const scrollY = window.scrollY;
		const windowHeight = window.innerHeight;
		const documentHeight = document.documentElement.scrollHeight;
		const isScrollingDown = scrollY > this.prevScrollY;

		if (isScrollingDown && scrollY + windowHeight >= documentHeight - 100) {
			this.setState((prevState) => ({
				pageNumber: prevState.pageNumber + 1,
			}));
		}

		this.prevScrollY = scrollY;
	};

	onSelectFilters = (e) => {
		const {events, filteredEvents} = this.state;

		this.setState(
			{
				activeFilter: statusFilters.filter(
					(filter) => filter.id === e.target.value
				)[0],
			},
			() => {
				const newEvents = this.getFilteredTestResults(
					this.state.events,
					this.state.activeFilter
				);
				return new Promise((resolve) => {
					this.setState(
						{
							events: events,
							filteredEvents: newEvents,
						},
						resolve
					);
				});
			}
		);
	};

	getFilteredTestResults(allEvents, activeFilter) {
		let filteredEvents = [];
		if (activeFilter === ALL) {
			return this.state.events;
		}

		switch (activeFilter.id) {
			case activeFilter.id:
				filteredEvents = allEvents.filter((singleEvent) => {
					if (singleEvent.category === activeFilter.id) {
						return singleEvent;
					}
				});
				break;
		}
		return filteredEvents;
	}

	render() {
		const {events, filteredEvents} = this.state;

		return (
			<div className="lit-event-log">
				<h1>{strings.logg}</h1>

				<div>
					<div className="lit-View_tests">
						<div className="lit-dropdown_filter">
							<select
								className="lit-input__field"
								onChange={(e) => {
									this.onSelectFilters(e);
								}}
							>
								{statusFilters.map((filters) => (
									<option
										key={filters.id}
										value={filters.id}
										disabled={
											filters.label === strings.status
										}
									>
										{filters.label}
									</option>
								))}
							</select>
						</div>
					</div>
					<br />
				</div>
				<table>
					<tbody>
						{filteredEvents.map((event, index) => (
							<tr className="lit-event-log__entry" key={index}>
								<td>{moment(event.time).format('LLL')}</td>
								<td>{event.eventName}</td>
								<td>{event.identity}</td>
								<td>{event.testKey}</td>
								<td>{event.category}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EventLog);
