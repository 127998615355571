export default function mapFormElementsToNameValues(formElements) {
	const result = {};

	Array.prototype.forEach.call(formElements, (formElement) => {
		const {name, value} = formElement;
		if (name && value) {
			result[name] = value;
		}
	});

	return result;
}
