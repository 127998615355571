/* eslint-disable react/no-multi-comp */
import React, {Component} from 'react';
import moment from 'moment';
import strings from 'utils/strings';

import {updateObject} from 'utils/reducerUtils';
const InfoRow = (props) => {
	// eslint-disable-next-line no-undefined
	if (props.condition !== undefined && !props.condition) {
		return null;
	}
	return (
		<p className="info__row">
			<span className="info__label">{props.name}</span>
			<span className="info__value">{props.children || props.value}</span>
		</p>
	);
};
class TestUserInfo extends Component {
	constructor() {
		super();
		this.state = {
			isAddingAnnomnese: false,
			testCandidate: null,
		};
		this.handleUpdateTestCandidateEvent =
			this.handleUpdateTestCandidateEvent.bind(this);
	}
	handleUpdateTestCandidateEvent = (event) => {
		const target = event.target;

		const key = target.name;
		const value = target.value;

		this.handleUpdate(key, value);
	};

	handleUpdate = (key, value) => {
		const {testCandidate} = this.state;
		this.setState({
			testCandidate: updateObject(testCandidate, {
				[key]: value,
			}),
		});
	};
	saveTestCandidate(testCandidateUpdates) {
		const testKey = this.props.testResult.key;
		const testUserId = this.props.testResult.testUserId;

		const obj = {
			info: testCandidateUpdates,
			testUser: testUserId,
			testKey: testKey,
		};

		this.props.onUpdateTestCandidateInfo(obj);
	}
	render() {
		const {testResult} = this.props;
		const {info} = testResult;
		const {testUser} = testResult;
		const isTestOwner = testResult.isTestOwner;

		let completeName = '<skjult navn>';

		if (testUser.firstName) {
			completeName = `${testUser.firstName} ${testUser.lastName}`;
		}

		return (
			<div>
				{isTestOwner && (
					<div className="lit-input-information">
						<button
							className="lit-btn bg-primary"
							onClick={(e) => {
								const candidateObj = {
									firstName: testUser.firstName,
									lastName: testUser.lastName,
									class: info.class || testUser.class || null,
									ageGroup: info.class,
									age: info.age,
									gender: testUser.gender || 'm',
									languagesInHome:
										testUser.languagesInHome || 'one',
								};

								this.setState({
									isAddingAnnomnese:
										!this.state.isAddingAnnomnese,
									testCandidate: candidateObj,
								});
							}}
						>
							{!this.state.isAddingAnnomnese
								? 'Lage opplysninger'
								: 'Avbryt'}
						</button>
					</div>
				)}
				{this.state.isAddingAnnomnese && (
					<div>
						<form
							className="register-form"
							onSubmit={(event) => {
								event.preventDefault();
								const testCandidate = updateObject(
									this.state.testCandidate
								);

								this.saveTestCandidate(testCandidate);
							}}
						>
							<br />

							<div className="lit-input inline">
								<label
									className="lit-input__label wide"
									htmlFor="test-candidate-modal-firstName"
								>
									{strings.firstname}
								</label>
								<input
									className="lit-input__field"
									id="test-candidate-modal-firstName"
									name="firstName"
									onChange={
										this.handleUpdateTestCandidateEvent
									}
									placeholder="Nordmann"
									type="text"
									value={this.state.testCandidate.firstName}
									required
								/>
							</div>
							<div className="lit-input inline">
								<label
									className="lit-input__label wide"
									htmlFor="test-candidate-modal-lastName"
								>
									{strings.lastname}
								</label>
								<input
									className="lit-input__field"
									id="test-candidate-modal-lastName"
									name="lastName"
									onChange={
										this.handleUpdateTestCandidateEvent
									}
									placeholder="Nordmann"
									type="text"
									value={this.state.testCandidate.lastName}
									required
								/>
							</div>
							<div className="lit-input inline">
								<label
									className="lit-input__label wide"
									htmlFor="test-candidate-modal-age"
								>
									{strings.age}
								</label>
								<input
									className="lit-input__field"
									id="test-candidate-modal-age"
									name="age"
									onChange={
										this.handleUpdateTestCandidateEvent
									}
									placeholder={info.age}
									type="text"
									value={this.state.testCandidate.age}
									required
								/>
							</div>
							<div className="lit-input inline">
								<label
									className="lit-input__label wide"
									htmlFor="test-candidate-modal-gender"
								>
									{strings.sex}
								</label>
								<select
									className="lit-input__field"
									id="test-candidate-modal-gender"
									name="gender"
									onChange={
										this.handleUpdateTestCandidateEvent
									}
								>
									<option key={1} value={'m'}>
										{strings.male}
									</option>
									<option key={2} value={'f'}>
										{strings.female}
									</option>
									<option key={3} value={'u'}>
										{'Uspesifisert'}
									</option>
								</select>
							</div>
							<div className="lit-input inline">
								<label
									className="lit-input__label wide"
									htmlFor="test-candidate-modal-languagesInHome"
								>
									{
										'Har vokst opp i ett- eller to-språklig hjem?'
									}
								</label>
								<select
									className="lit-input__field"
									id="test-candidate-modal-languagesInHome"
									name="languagesInHome"
									onChange={
										this.handleUpdateTestCandidateEvent
									}
								>
									<option key={1} value={'one'}>
										{'Ett språk'}
									</option>
									<option key={2} value={'two'}>
										{'To språk'}
									</option>
									<option key={3} value={'multiple'}>
										{'Flere språk'}
									</option>
								</select>
							</div>
							<div className="lit-input inline">
								<label
									className="lit-input__label wide"
									htmlFor="test-candidate-modal-class"
								>
									{'Klassetrinn'}
								</label>
								<input
									className="lit-input__field"
									id="test-candidate-modal-class"
									name="class"
									onChange={
										this.handleUpdateTestCandidateEvent
									}
									placeholder={
										info.class ? (
											info.class
										) : (
											<i>{'ikke tilgjengelig'}</i>
										)
									}
									type="text"
									value={this.state.testCandidate.class}
									required
								/>
							</div>
							{/* <div className="lit-input inline">
								<label
									className="lit-input__label wide"
									htmlFor="test-admin-modal-phone"
								>
									{strings.telephone}
								</label>
								<input
									className="lit-input__field"
									id="test-admin-modal-phone"
									name="phone"
									onChange={this.handleUpdateTestAdminEvent}
									placeholder="987 65 432"
									required
									type="tel"
									value={testAdmin.phone || ''}
								/>
							</div> */}

							<div className="lit-input">
								<button className="lit-btn bg-primary">
									{`${strings.save}`}
								</button>
							</div>
						</form>
						<br />
					</div>
				)}
				{!this.state.isAddingAnnomnese && (
					<div className="info">
						<InfoRow
							name={strings.testdate}
							value={moment(testResult.completedAt).format('LL')}
						/>
						<InfoRow
							condition={isTestOwner}
							name={`Navn: `}
							value={completeName}
						/>

						<InfoRow
							name={strings.age}
							value={`${info.age} år gammel`}
						/>
						<InfoRow name={strings.sex}>
							{testUser.gender === 'm' &&
								(testUser.age.years < 18
									? strings.boy
									: strings.male)}
							{testUser.gender === 'f' &&
								(testUser.age.years < 18
									? strings.girl
									: strings.female)}
							{testUser.gender === 'u' && 'Uspesifisert'}
							{!testUser.gender && <i>{'ikke tilgjengelig'}</i>}
						</InfoRow>

						<div className="info__group info__group--education">
							<h3>{strings.education}</h3>
						</div>
						<InfoRow name={`Klassetrinn: `}>
							{info.class === 0 && 'Siste år før skolestart'}
							{info.class === 1 && '1. klasse'}
							{info.class === 2 && '2. klasse'}
							{info.class === 3 && '3. klasse'}
							{info.class === 4 && '4. klasse'}
							{info.class === 5 && '5. klasse'}
							{info.class === 6 && '6. klasse'}
							{info.class === 7 && '7. klasse'}
							{info.class === 8 && '8. klasse'}
							{info.class === 9 && '9. klasse'}
							{info.class === 10 && '10. klasse'}

							{testUser.class === 'studiespesialisering' &&
								'Studiespesialisering'}
							{testUser.class === 'yrkesfag' && 'Yrkesfag'}
							{testUser.class === 'bachelor' && 'Bachelor'}
							{testUser.class === 'master' && 'Mastergrad'}
							{testUser.class === 'phd' && 'Doktorgrad (PhD)'}
						</InfoRow>

						<div className="info__group">
							<h3>{`Hjemmespråk`}</h3>
							<InfoRow
								name={`Har vokst opp i ett- eller to-språklig hjem? `}
							>
								{testUser.languagesInHome === 'one' &&
									'Ett språk'}
								{testUser.languagesInHome === 'two' &&
									'To språk'}
								{testUser.languagesInHome === 'multiple' &&
									'Flere språk'}
								{!testUser.languagesInHome && (
									<i>{'ikke tilgjengelig'}</i>
								)}
							</InfoRow>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default TestUserInfo;
