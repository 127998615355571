import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

export const MenuItem = (props) => {
	const {tab, pathname} = props;

	const isExternal = tab.external;
	const isActiveTab = pathname === tab.url || pathname === tab.altUrl;
	return (
		<li key={tab.name}>
			{isExternal ? (
				<a
					href={tab.url}
					className={classNames({
						'lit-menu__item': true,
						selected: isActiveTab,
					})}
					target="_blank"
					rel="noopener noreferrer"
				>
					{tab.name}
				</a>
			) : (
				<Link
					to={tab.url}
					className={classNames({
						'lit-menu__item': true,
						selected: isActiveTab,
					})}
				>
					{tab.name}
				</Link>
			)}
		</li>
	);
};

export default MenuItem;
