import React from 'react';
import './styles.scss';
import classNames from 'classnames';
import strings from 'utils/strings';
class TestAdminGroups extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			adminGroup: [],
			targetGroup: '',
			groupName: '',
			groupData: props.allGroupsUsers ? props.allGroupsUsers : [],
			isAddingUsers: false,
			inclusiveMember: [],
			isCreatingNewGroup: true,
			exclusiveMembers: [],
		};
		this.excludeMembersIfExists = this.excludeMembersIfExists.bind(this);
	}
	componentDidMount() {
		this.setState({groupData: this.props.allGroupsUsers});
	}

	setDefaultUser(selectedUsers, groupData) {
		const groups = groupData.map((group) => {
			return group.groupName;
		});
		const unique = [...new Set(groups)];
		this.excludeMembersIfExists(unique[0], groupData, selectedUsers);
	}

	excludeMembersIfExists(event, groupMembers, users) {
		const value = event;

		const selectedSubsId = users.map((userId) => {
			return {
				subscription: userId.subscription.id,
				firstName: userId.firstName,
				lastName: userId.lastName,
				adminId: userId.id,
			};
		});
		var getrelevantData = groupMembers.filter(
			(group) => group.groupName === value
		);
		var groupSubsId = getrelevantData.map((relevantData) => {
			return {
				memberId: relevantData.subscription,
				groupName: relevantData.groupName,
				groupId: relevantData.Id,
			};
		});
		let flag = null;
		let currentGroupId = null;
		const inclusive = []; // for storing group members who already exist in the selected group

		const exclusive = []; //for storing group members who do not exist in the selected group

		for (let i = 0; i < selectedSubsId.length; i++) {
			flag = 0;
			for (let j = 0; j < groupSubsId.length; j++) {
				if (
					selectedSubsId[i].subscription === groupSubsId[j].memberId
				) {
					flag = 1;
					const matched = {
						Name:
							selectedSubsId[i].firstName +
							' ' +
							selectedSubsId[i].lastName,
					};
					inclusive.push(matched);
				} else {
					currentGroupId = groupSubsId[j].groupId;
				}
			}
			if (flag !== 1) {
				const unMatched = {
					subsId: selectedSubsId[i].subscription,
					adminid: selectedSubsId[i].adminId,
				};
				exclusive.push(unMatched);
			}
		}

		this.setState({
			exclusiveMembers: exclusive,
			targetGroup: currentGroupId,
			inclusiveMember: inclusive,
		});
	}

	onAddMembers = () => {
		if (this.state.inclusiveMember.length) {
			let existingMembers = '';
			for (const member of this.state.inclusiveMember) {
				existingMembers +=
					JSON.stringify(Object.values(member)).replace(
						/[[\]"]+/g,
						''
					) + ', ';
			}
			window.alert(existingMembers + strings.alreadyexistsingroup);
		}

		if (this.state.exclusiveMembers.length) {
			this.props.onAddingMembers(
				this.state.targetGroup,
				this.state.exclusiveMembers
			);
		} /* else
			window.alert(
				strings.cannotaddexistingmembertogroup
			); */
	};

	onCreateNewGroup = () => {
		const {selectedUsers} = this.props;
		const groups = this.state.groupData.map((group) => {
			return group.groupName;
		});
		const uniqueGroups = [...new Set(groups)];

		if (this.state.groupName.length) {
			const identifyDuplicate = uniqueGroups.filter((groups) => {
				return (
					this.state.groupName.toLowerCase() === groups.toLowerCase()
				);
			});
			if (!identifyDuplicate.length) {
				const groupName = this.state.groupName;
				const ids = selectedUsers.map((theUser) => theUser.id);

				const subsIds = selectedUsers.map(
					(theUser) => theUser.subscription.id
				);

				this.props.onCreateGroup(ids, subsIds, groupName);
			} else {
				window.alert(strings.groupalreadyexists);
			}
		} else {
			window.alert(strings.writegroupname);
		}
	};

	render() {
		const theUsers = this.props.selectedUsers;

		const groups = this.state.groupData.map((group) => {
			return group.groupName;
		});
		const uniqueGroups = [...new Set(groups)];

		return (
			<div
				className="lit-modal"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<h3>{strings.selected_users}</h3>
					<table id="users_table" className="lit-list-user-list">
						<thead>
							<tr>
								<th className="lit-list-users-new-group-header">
									{strings.firstname +
										', ' +
										strings.lastname}
								</th>
								<th className="lit-list-users-new-group-header">
									{strings.organization}
								</th>
								<th className="lit-list-users-new-group-header">
									{strings.email}
								</th>
								<th className="lit-list-users-new-group-header">
									{strings.telephone}
								</th>
							</tr>
						</thead>
						<tbody>
							{theUsers.map((theUser) => {
								return (
									<tr
										className="lit-list__item"
										key={theUser.id}
									>
										<td className="lit-list-users-new-group-col">
											<div className="lit-list__text">
												{theUser.firstName &&
													`${theUser.firstName}, ${theUser.lastName}`}
											</div>
										</td>
										<td className="lit-list-users-new-group-col">
											<div className="lit-list__text">
												{theUser.organization}
											</div>
										</td>
										<td className="lit-list-users-new-group-col">
											<div className="lit-list__text">
												{theUser.email}
											</div>
										</td>
										<td className="lit-list-users-new-group-col">
											<div className="lit-list__text">
												{theUser.phone}
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>

					<div className="checkbox-wrapper-13">
						<input
							type="checkbox"
							className="user_checkbox2"
							name="velg gruppet"
							onChange={(e) => {
								if (e.target.checked) {
									this.setState({
										isCreatingNewGroup: false,
									});
									this.setDefaultUser(
										this.props.selectedUsers,
										this.props.allGroupsUsers
									);
								} else {
									this.setState({
										isCreatingNewGroup: true,
									});
								}
							}}
						/>

						<label>{strings.addtogroup}</label>
					</div>

					{this.state.isCreatingNewGroup && (
						<div className="lit-input-newgroup-name">
							<input
								className="lit-tf_search"
								type="text"
								placeholder={strings.writegroupname}
								onChange={(e) => {
									const val = e.target.value;
									this.setState({groupName: val});
								}}
							/>
						</div>
					)}

					{!this.state.isCreatingNewGroup && (
						<div className="lit-input-newgroup-name">
							<select
								className="lit-input__field"
								defaultValue={uniqueGroups[0]}
								name="group"
								onChange={(event) => {
									this.excludeMembersIfExists(
										event.target.value,
										this.state.groupData,
										theUsers
									);
								}}
							>
								{uniqueGroups.map((group) => (
									<option key={group} value={group}>
										{group}
									</option>
								))}
							</select>
						</div>
					)}
					{!this.state.isCreatingNewGroup && (
						<div className="lit-input-newgroup">
							<div className="lit_btn_add-members">
								<button
									className={classNames('print-hide', {
										'lit-btn see-more': true,
										'bg-tertiary': true,
									})}
									onClick={this.onAddMembers}
								>
									{strings.insert}
								</button>
								<button
									className="lit-btn bg-negative2"
									onClick={() => {
										this.props.closeWindow();
									}}
								>
									{strings.cancel}
								</button>
							</div>
						</div>
					)}
					{this.state.isCreatingNewGroup && (
						<div className="lit_create_new-group">
							<button
								className={classNames('print-hide', {
									'lit-btn see-more': true,
									'bg-tertiary': true,
								})}
								onClick={this.onCreateNewGroup}
							>
								{strings.createnewgroup}
							</button>
							<button
								className="lit-btn bg-negative2"
								onClick={() => {
									this.props.closeWindow();
								}}
							>
								{strings.cancel}
							</button>
						</div>
					)}
				</div>
			</div>
		);
	}
}
export default TestAdminGroups;
