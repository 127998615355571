import React from 'react';
import strings from 'utils/strings';
class SubscriptionTable extends React.Component {
	constructor(props) {
		super(props);
	}

	convertID(id) {
		if (id === 'english') {
			return 'Engelsk';
		}
		if (id === 'swedish') {
			return 'Svensk';
		}
		if (id === 'norwegian') {
			return 'Norsk';
		}
		if (id === 'german') {
			return 'Tysk';
		}
		if (id === 'dutch') {
			return 'Dutch';
		}

		return 'Engelsk'; //default
	}

	render() {
		const isSuperAdmin = this.props.isSuperAdmin;
		const languages = this.props.languages;
		return (
			<div className="detailed-subscription">
				<div className="lit-subscription-table-container">
					<h3 className="detailed-subscription__heading">
						{strings.subscription}
					</h3>
					<div className="lit-main-master-header">
						<div className="lit-main-language-header">
							<h4 className="lit-main_subscription-headings"></h4>
						</div>
						<div className="lit-main-production-header">
							<h4 className="lit-main_subscription-headings">
								Abonnement
							</h4>
						</div>
						{isSuperAdmin && (
							<div className="lit-main-norming-header">
								<h4 className="lit-main_subscription-headings">
									Normering
								</h4>
							</div>
						)}
						<div
							className={
								isSuperAdmin
									? 'lit-main-basic-header'
									: 'lit-admin-basic-header'
							}
						>
							<h4 className="lit-main_subscription-headings">
								Basis
							</h4>
						</div>
						{isSuperAdmin && (
							<div className="lit-main-final-header">
								<h4 className="lit-main_subscription-headings"></h4>
							</div>
						)}
					</div>
					{/* oterh layer of divs goes under */}
					<div className="lit-main-secondary-header">
						<div className="lit-main-subHeaders language">
							<h4 className="lit-secondary-headings" />
						</div>
						<div className="lit-main-subHeaders production_child">
							<h4 className="lit-secondary-headings">
								{strings.singleChild}
							</h4>
						</div>
						<div className="lit-main-subHeaders production_child">
							<h4 className="lit-secondary-headings">
								{strings.singleChild}
							</h4>
						</div>
						<div className="lit-main-subHeaders production_youth_screening">
							<h4 className="lit-secondary-headings">
								{strings.youth}
							</h4>
						</div>
						<div className="lit-main-subHeaders  production_youth_followup">
							<h4 className="lit-secondary-headings">
								{strings.youth}
							</h4>
						</div>

						<div className="lit-main-subHeaders production_adult_screening">
							<h4 className="lit-secondary-headings">
								{strings.adult}
							</h4>
						</div>

						<div className="lit-main-subHeaders  production_adult_followup">
							<h4 className="lit-secondary-headings">
								{strings.adult}
							</h4>
						</div>
						{isSuperAdmin && (
							<>
								<div className="lit-main-subHeaders norming_child">
									<h4 className="lit-secondary-headings">
										{strings.singleChild}
									</h4>
								</div>
								<div className="lit-main-subHeaders norming_youth">
									<h4 className="lit-secondary-headings">
										{strings.youth}
									</h4>
								</div>
								<div className="lit-main-subHeaders norming_adult">
									<h4 className="lit-secondary-headings">
										{strings.adult}
									</h4>
								</div>
							</>
						)}
						<div
							className={
								isSuperAdmin
									? 'lit-main-subHeaders basic'
									: 'lit-admin-subHeaders basic'
							}
						>
							<h4 className="lit-secondary-headings"></h4>
						</div>
						{isSuperAdmin && (
							<div className="lit-main-subHeaders fnal">
								<h4 className="lit-secondary-headings"></h4>
							</div>
						)}
					</div>
					<div className="lit-main-tertiary-header">
						<div className="lit-main-subHeaders language">
							<h4 className="lit-secondary-headings">Språk</h4>
						</div>
						<div className="lit-main-subHeaders production_child">
							<h4 className="lit-secondary-headings">
								{`Screening`}
							</h4>
						</div>
						<div className="lit-main-subHeaders production_adult_screening">
							<h4 className="lit-secondary-headings">
								Oppfølging
							</h4>
						</div>
						<div className="lit-main-subHeaders production_youth_screening">
							<h4 className="lit-secondary-headings">
								Screening
							</h4>
						</div>
						<div className="lit-main-subHeaders  production_youth_followup">
							<h4 className="lit-secondary-headings">
								Oppfølging
							</h4>
						</div>

						<div className="lit-main-subHeaders production_adult_screening">
							<h4 className="lit-secondary-headings">
								Screening
							</h4>
						</div>

						<div className="lit-main-subHeaders  production_adult_followup">
							<h4 className="lit-secondary-headings">
								Oppfølging
							</h4>
						</div>
						{isSuperAdmin && (
							<>
								<div className="lit-main-subHeaders norming_child">
									<h4 className="lit-secondary-headings">
										---
									</h4>
								</div>
								<div className="lit-main-subHeaders norming_youth">
									<h4 className="lit-secondary-headings">
										---
									</h4>
								</div>
								<div className="lit-main-subHeaders norming_adult">
									<h4 className="lit-secondary-headings">
										---
									</h4>
								</div>
							</>
						)}
						<div
							className={
								isSuperAdmin
									? 'lit-main-subHeaders basic'
									: 'lit-admin-subHeaders basic'
							}
						>
							<h4 className="lit-secondary-headings">---</h4>
						</div>
						{isSuperAdmin && (
							<div className="lit-main-subHeaders final">
								<h4 className="lit-secondary-headings"></h4>
							</div>
						)}
					</div>

					<div className="lit-main-table">
						<table cellSpacing="0" className="lit-list__header">
							<tbody>
								{languages.map((language) => {
									const {
										child,
										youth,
										adult,
										child_followup,
										youth_followup,
										adult_followup,
										norming_child,
										norming_youth,
										norming_adult,
										basic,
									} = language;
									return (
										<tr
											className="lit-list__item"
											key={language.id}
										>
											<td className="lit-list__col ">
												<div className="lit-list__text language">
													<b>
														{' '}
														{this.convertID(
															language.language
														)}
													</b>
												</div>
											</td>

											{child ? (
												<td className="lit-list__col">
													<div className="tickmark large">
														L
													</div>
												</td>
											) : (
												<td className="lit-list__col">
													<div className="crossmark large">
														X
													</div>
												</td>
											)}
											{child_followup ? (
												<td className="lit-list__col">
													<div className="tickmark large">
														L
													</div>
												</td>
											) : (
												<td className="lit-list__col">
													<div className="crossmark large">
														X
													</div>
												</td>
											)}
											{youth ? (
												<td className="lit-list__col">
													<div className="tickmark large">
														L
													</div>
												</td>
											) : (
												<td className="lit-list__col">
													<div className="crossmark large">
														X
													</div>
												</td>
											)}
											{youth_followup ? (
												<td className="lit-list__col">
													<div className="tickmark large">
														L
													</div>
												</td>
											) : (
												<td className="lit-list__col">
													<div className="crossmark large">
														X
													</div>
												</td>
											)}

											{adult ? (
												<td className="lit-list__col">
													<div className="tickmark large">
														L
													</div>
												</td>
											) : (
												<td className="lit-list__col">
													<div className="crossmark large">
														X
													</div>
												</td>
											)}

											{adult_followup ? (
												<td className="lit-list__col">
													<div className="tickmark large">
														L
													</div>
												</td>
											) : (
												<td className="lit-list__col">
													<div className="crossmark large">
														X
													</div>
												</td>
											)}
											{isSuperAdmin && (
												<>
													{norming_child ? (
														<td className="lit-list__col">
															<div className="tickmark norming large">
																L
															</div>
														</td>
													) : (
														<td className="lit-list__col">
															<div className="crossmark norming large">
																X
															</div>
														</td>
													)}
												</>
											)}
											{isSuperAdmin && (
												<>
													{norming_youth ? (
														<td className="lit-list__col">
															<div className="tickmark norming large">
																L
															</div>
														</td>
													) : (
														<td className="lit-list__col">
															<div className="crossmark norming large">
																X
															</div>
														</td>
													)}
												</>
											)}
											{isSuperAdmin && (
												<>
													{norming_adult ? (
														<td className="lit-list__col">
															<div className="tickmark norming large">
																L
															</div>
														</td>
													) : (
														<td className="lit-list__col">
															<div className="crossmark norming large">
																X
															</div>
														</td>
													)}
												</>
											)}
											{basic ? (
												<td className="lit-list__col">
													<div
														className={
															isSuperAdmin
																? 'tickmark basic large'
																: 'tickmark-admin basic large'
														}
													>
														L
													</div>
												</td>
											) : (
												<td className="lit-list__col">
													<div
														className={
															isSuperAdmin
																? 'crossmark basic large'
																: 'crossmark-admin basic large'
														}
													>
														X
													</div>
												</td>
											)}
											{isSuperAdmin && (
												<td className="lit-list__col">
													<div className="lit-list__text final">
														<button
															className="lit-btn lit-btn--small bg-secondary"
															onClick={() => {
																this.props.onShowSubEditPanel(
																	language
																);
															}}
														>
															{strings.edit}
														</button>
													</div>
												</td>
											)}
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}

export default SubscriptionTable;
