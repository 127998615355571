import React, {Component} from 'react';
import moment from 'moment';

import classNames from 'classnames';
import {TEST_USER_INFO_LABELS} from '../../../constants/index';
import strings from 'utils/strings';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPrint} from '@fortawesome/free-solid-svg-icons';

const childTypeGrades = [
	'child-screening',
	'child',
	'youth-screening',
	'youth-followup',
];
const InfoRow = (props) => {
	if (props.condition !== undefined && !props.condition) {
		return null;
	}
	return (
		<p className="info__row">
			<span className="info__label">{props.name}</span>
			<span className="info__value">{props.children || props.value}</span>
		</p>
	);
};
const InfoRowBool = (props) => {
	const value = props.children || props.value;
	const hasLongIntro = props.hasLongIntro;

	const valueText =
		value === 'yes' || value === 'ja' || value === true
			? 'Ja'
			: value === true || value === 'true'
			? 'Ja'
			: value === 'no' || value === 'nei' || value === false
			? 'Nei'
			: value === ''
			? 'ikke tilgjengelig'
			: hasLongIntro && typeof value === 'undefined'
			? 'ikke tilgjengelig'
			: hasLongIntro && value
			? 'Ja'
			: hasLongIntro && !value
			? 'Nei'
			: !hasLongIntro && !value
			? 'Nei'
			: '';

	return (
		<InfoRow condition={props.condition} name={props.name}>
			{valueText}
		</InfoRow>
	);
};

class TestUserInfo extends Component {
	render() {
		const {testResult} = this.props;
		const {testUser} = testResult;
		const testCompletionDate = moment(testResult.completedAt, 'YYYY-MM-DD');
		const requiredDate = moment('2022-04-23').format('YYYY-MM-DD');
		const timeDifference = moment(testCompletionDate).diff(
			requiredDate,
			'days'
		);
		if (timeDifference > 1) {
		}

		const hasLongIntro = testResult.anamnese ? true : false;
		const isTestOwner = testResult.isTestOwner;

		const completeName = testUser.firstName
			? `${testUser.firstName} ${testUser.lastName}`
			: strings.hiddenname;
		const completeEmail = testUser.firstName
			? `${testUser.email}`
			: '<Skjult Epost>';

		return (
			<div>
				<div className="lit-input-information">
					<button
						className={classNames('print-hide', {
							'lit-btn2 see-more': true,
							'bg-tertiary': true,
						})}
						onClick={() => {
							window.print();
							/* this.updatePrintLog('Results'); */
						}}
					>
						<FontAwesomeIcon
							size="lg"
							className="lit-icon-btns"
							icon={faPrint}
						/>

						<p className="lit-btns-txt">{'Print opplysninger'}</p>
					</button>
				</div>
				<div className="info">
					<InfoRow
						condition={isTestOwner}
						name={`Navn:`}
						hasLongIntro={hasLongIntro}
					>{`${completeName}`}</InfoRow>
					{this.props.testType === 'child-screening' && (
						<InfoRow
							condition={testUser.education.isInHighSchool}
							hasLongIntro={hasLongIntro}
							name={`Trinn: `}
						>
							{testUser.education.youthGrade}
						</InfoRow>
					)}
					<InfoRow
						name="Dato for gjennomføring: "
						value={moment(testResult.completedAt).format('LL')}
					/>

					<InfoRow
						condition={isTestOwner}
						name="E-post:"
						value={completeEmail}
						hasLongIntro={hasLongIntro}
					/>
					<InfoRow
						condition={isTestOwner}
						name="Telefon:"
						value={testUser.phone}
						hasLongIntro={hasLongIntro}
					/>

					<InfoRow
						name="Alder ved gjennomføring:"
						value={`${testUser.age.years} år og ${testUser.age.months} måneder`}
						hasLongIntro={hasLongIntro}
					/>
					<InfoRow
						name="Kjønn:"
						value={
							testUser.gender === 'm' &&
							childTypeGrades.includes(this.props.testType)
								? 'Gutt'
								: testUser.gender === 'f' &&
								  childTypeGrades.includes(this.props.testType)
								? 'Jente'
								: testUser.gender === 'm' &&
								  !childTypeGrades.includes(this.props.testType)
								? 'Mann'
								: testUser.gender === 'f' &&
								  !childTypeGrades.includes(this.props.testType)
								? 'Kvinne'
								: 'Ikke tilgjengelig'
						}
						hasLongIntro={hasLongIntro}
					/>
					{this.props.testType !== 'child-screening' && (
						<div className="info__group info__group--education">
							<h3>{`Utdanning`}</h3>
							{testUser.education && (
								<>
									<InfoRow
										name={strings.highestEducation}
										hasLongIntro={hasLongIntro}
									>
										{testUser.education
											.highestCompletedEducation ===
											'juniorHigh' && 'Ungdomsskole'}
										{testUser.education
											.highestCompletedEducation ===
											'highSchool' && 'Videregående'}
										{testUser.education
											.highestCompletedEducation ===
											'bachelor' && 'Bachelor'}
										{testUser.education
											.highestCompletedEducation ===
											'master' && 'Master'}
										{testUser.education
											.highestCompletedEducation ===
											'phd' && 'PhD'}
										{testUser.education
											.highestCompletedEducation ===
											'other' && 'Annet'}
									</InfoRow>
									{testUser.education
										.highestCompletedEducation ===
										'other' && (
										<InfoRow
											name={strings.otherCompetence}
											hasLongIntro={hasLongIntro}
										>
											{testUser.education.otherEducation}
										</InfoRow>
									)}

									<InfoRowBool
										name={strings.goesToHighSchool}
										value={
											testUser.education.isInHighSchool
										}
										hasLongIntro={hasLongIntro}
									/>
									{this.props.testType !==
										'child-screening' && (
										<InfoRow
											condition={
												testUser.education
													.isInHighSchool
											}
											name={`Utdanningsprogram: `}
											hasLongIntro={hasLongIntro}
										>
											{testUser.education
												.vocationalEducation
												? 'Yrkesfag'
												: 'Studiespesialiserende'}
										</InfoRow>
									)}

									<InfoRow
										condition={
											testUser.education.isInHighSchool
										}
										hasLongIntro={hasLongIntro}
										name={`Trinn: `}
									>
										{this.props.testType ===
										'adult-screening'
											? testUser.education.grade
											: testUser.education.youthGrade ||
											  testUser.class}
									</InfoRow>
									{!testUser.education.isInHighSchool && (
										<>
											<InfoRowBool
												name={`Er du på arbeidsavklaringstiltak? `}
												value={
													testUser.education
														.onWorkAssessmentAllowance
												}
												hasLongIntro={hasLongIntro}
											/>

											<InfoRow
												condition={
													testUser.education
														.onWorkAssessmentAllowance
												}
												name={`Tiltak: `}
												hasLongIntro={hasLongIntro}
											>
												{testUser.education.measure ===
													'youthProgram' &&
													'Ungdomsprogram'}
												{testUser.education.measure ===
													'accompanyingMeasures' &&
													'Oppfølgingstiltak'}
												{testUser.education.measure ===
													'workPreparatoryTraining' &&
													'Arbeidsforberedende trening'}
											</InfoRow>

											{!testUser.education
												.onWorkAssessmentAllowance && (
												<>
													<InfoRowBool
														name={`Tar du voksenopplæring (videregående for voksne over 25 år)? `}
														value={
															testUser.education
																.isDoingAdultEducation
														}
														hasLongIntro={
															hasLongIntro
														}
													/>
													<InfoRowBool
														condition={
															!testUser.education
																.isDoingAdultEducation
														}
														name={`Tar du høyere utdanning, for eksempel på høyskole eller universitet? `}
														value={
															testUser.education
																.isDoingHigherEducation
														}
														hasLongIntro={
															hasLongIntro
														}
													/>
												</>
											)}
										</>
									)}
								</>
							)}
						</div>
					)}
					<div className="info__group">
						{this.props.testType !== 'child-screening' ? (
							<h3>{`Hjemmespråk`}</h3>
						) : (
							''
						)}

						{this.props.testType !== 'child-screening' && (
							<InfoRowBool
								name={`Har du vokst opp i et to-språklig hjem? `}
								value={
									testUser.homeLanguage.grewUpInBilingualHome
								}
								hasLongIntro={hasLongIntro}
							/>
						)}

						{this.props.testType !== 'child-screening' &&
							testUser.homeLanguage.grewUpInBilingualHome && (
								<>
									<InfoRow
										name={`Hva er ditt 1.språk? `}
										hasLongIntro={hasLongIntro}
									>
										{
											TEST_USER_INFO_LABELS[
												testUser.homeLanguage
													.firstLanguage
											]
										}
									</InfoRow>
									<InfoRow
										condition={
											testUser.homeLanguage
												.firstLanguage === 'other'
										}
										hasLongIntro={hasLongIntro}
										name={`Annet 1. språk `}
										value={
											testUser.homeLanguage
												.otherFirstLanguage
										}
									/>
									<InfoRow
										name={`Hva er ditt 2.språk? `}
										hasLongIntro={hasLongIntro}
										value={
											TEST_USER_INFO_LABELS[
												testUser.homeLanguage
													.secondLanguage
											]
										}
									/>
									<InfoRow
										condition={
											testUser.homeLanguage
												.secondLanguage === 'other'
										}
										hasLongIntro={hasLongIntro}
										name={`Annet 2. språk `}
										value={
											testUser.homeLanguage
												.otherSecondLanguage
										}
									/>
								</>
							)}
					</div>

					{this.props.testType !== 'child-screening' && (
						<div>
							<div className="info__group info__group--language">
								<h3>{`Språk`}</h3>
								<InfoRowBool
									name={`Har du vansker med huske hvilket ord du skal si, når du snakker? `}
									hasLongIntro={hasLongIntro}
									value={
										testUser.language
											.troubleRememberingWhichWordToSay
									}
								></InfoRowBool>
								<InfoRowBool
									name={`Stokker du ofte om på ordene når du prater? `}
									hasLongIntro={hasLongIntro}
									value={
										testUser.language
											.shufflesWordsWhenSpeaking
									}
								></InfoRowBool>
								<InfoRowBool
									name={`Bruker du lang tid på å formulere deg riktig, når du snakker? `}
									hasLongIntro={hasLongIntro}
									value={
										testUser.language
											.spendsLongTimeFormulatingCorrectly
									}
								></InfoRowBool>
								<InfoRowBool
									name={`Har det noen ganger vært mistanke om at du har språkvansker? `}
									hasLongIntro={hasLongIntro}
									value={
										testUser.language
											.hasBeenSuspicionsOfDifficulties
									}
								></InfoRowBool>
								<InfoRowBool
									name={`Har du vært utredet for språkvansker av PPT eller andre? `}
									value={
										testUser.language
											.hasBeenAssessedForDifficulties
									}
									hasLongIntro={hasLongIntro}
								/>
								<InfoRowBool
									condition={
										testUser.language
											.hasBeenAssessedForDifficulties
									}
									hasLongIntro={hasLongIntro}
									name={`Har du en diagnose, f.eks. spesifikke språkvansker? `}
									value={testUser.language.hasDiagnosis}
								/>

								<InfoRowBool
									condition={
										!testUser.language
											.hasBeenAssessedForDifficulties
									}
									hasLongIntro={hasLongIntro}
									name={`Mistenker du selv at du har språkvansker? `}
									value={
										testUser.language
											.suspectsToHaveDifficulties
									}
								/>
								<InfoRow
									name={`Har noen i din nærmeste familie (slektninger) språkvansker? `}
									hasLongIntro={hasLongIntro}
								>
									{testUser.language
										.closeRelativeHasDifficulties ===
										'yes' && 'Ja'}
									{testUser.language
										.closeRelativeHasDifficulties ===
										'no' && 'Nei'}
									{testUser.language
										.closeRelativeHasDifficulties ===
										'doesNotKnow' && 'Vet ikke'}
								</InfoRow>
								<InfoRow
									name={`Hvordan vil du rangere dine egne muntlige språkferdigheter på en skala fra 1-6, hvor
                        1 er svært dårlig og 6 er svært bra? `}
									value={testUser.language.selfRating}
									hasLongIntro={hasLongIntro}
								/>
							</div>
						</div>
					)}
					{testUser.readingAndWriting &&
						this.props.testType === 'child-screening' && (
							<div className="info__group info__group--reading-and-writing">
								<h3>{`Lesing og skriving`}</h3>
								<p className="indent"></p>
								<InfoRowBool
									name={`Har barnet fått påvist lese- og skrivevansker? `}
									value={
										testUser.readingAndWriting
											.hasBeenAssessedForDifficulties
									}
									hasLongIntro={hasLongIntro}
								/>
								<InfoRowBool
									name={`Har barnet vært mistenkt for lese- og skrivevansker av PPT eller andre? `}
									value={
										testUser.readingAndWriting
											.suspectsToHaveDyslexia
									}
									hasLongIntro={hasLongIntro}
								/>
							</div>
						)}

					{testUser.readingAndWriting &&
						this.props.testType !== 'child-screening' && (
							<div className="info__group info__group--reading-and-writing">
								<h3>{`Lesing og skriving`}</h3>
								<p className="indent">
									<strong>{`Har du vansker med å:`}</strong>
								</p>
								<InfoRowBool
									name={`lese ordene riktig? `}
									value={
										testUser.readingAndWriting
											.difficultiesWith
											.readingWordsCorrectly
									}
									hasLongIntro={hasLongIntro}
								/>
								<InfoRowBool
									name={`lese lange tekster? `}
									value={
										testUser.readingAndWriting
											.difficultiesWith.readLongTexts
									}
									hasLongIntro={hasLongIntro}
								/>
								<InfoRowBool
									name={`huske hva du har lest? `}
									value={
										testUser.readingAndWriting
											.difficultiesWith
											.rememberingWhatYouHaveRead
									}
									hasLongIntro={hasLongIntro}
								/>
								<InfoRowBool
									name={`skrive ordene riktig? `}
									value={
										testUser.readingAndWriting
											.difficultiesWith.writeWordsProperly
									}
									hasLongIntro={hasLongIntro}
								/>
								<InfoRowBool
									name={`få «tankene ned på papiret»? `}
									value={
										testUser.readingAndWriting
											.difficultiesWith
											.gettingThoughtsOntoPaper
									}
									hasLongIntro={hasLongIntro}
								/>
								<InfoRowBool
									name={`lese og skrive på engelsk? `}
									value={
										testUser.readingAndWriting
											.difficultiesWith
											.readingAndWritingEnglish
									}
									hasLongIntro={hasLongIntro}
								/>
								<InfoRowBool
									name={`Har du vært utredet for lese- og skrivevansker av PPT eller andre? `}
									value={
										testUser.readingAndWriting
											.hasBeenAssessedForDifficulties
									}
									hasLongIntro={hasLongIntro}
								/>

								<InfoRowBool
									condition={
										testUser.readingAndWriting
											.hasBeenAssessedForDifficulties
									}
									name={`Har du en diagnose, f.eks. dysleksi eller spesifikke lese- og
                                    skrivevansker? `}
									value={
										testUser.readingAndWriting.hasDiagnosis
									}
									hasLongIntro={hasLongIntro}
								/>

								<InfoRowBool
									condition={
										!testUser.readingAndWriting
											.hasBeenAssessedForDifficulties
									}
									name={`Mistenker du selv at du har dysleksi? `}
									value={
										testUser.readingAndWriting
											.suspectsToHaveDyslexia
									}
									hasLongIntro={hasLongIntro}
								/>

								<InfoRow
									name={`Har noen i din nærmeste familie (slektninger) lese- og skrivevansker eller
							dysleksi? `}
									hasLongIntro={hasLongIntro}
								>
									{testUser.readingAndWriting
										.closeRelativeHasDifficulties ===
										'yes' && 'Ja'}
									{testUser.readingAndWriting
										.closeRelativeHasDifficulties ===
										'no' && 'Nei'}
									{testUser.readingAndWriting
										.closeRelativeHasDifficulties ===
										'doesNotKnow' && 'Vet ikke'}
								</InfoRow>
								<InfoRow
									name={`Hvordan vil du rangere dine egne lese- og skriveferdigheter på en skala fra 1-6,
                            hvor 1 er svært dårlig og 6 er svært bra? `}
									value={
										testUser.readingAndWriting.selfRating
									}
									hasLongIntro={hasLongIntro}
								/>
							</div>
						)}

					{this.props.testType !== 'child-screening' && (
						<div>
							<div className="info__group info__group--math">
								<h3>{`Matematikk`}</h3>
								<InfoRowBool
									name={`Har du vansker med regning eller matematikk? `}
									value={
										testUser.math
											.hasDifficultiesWithMathOrCalculation
									}
									hasLongIntro={hasLongIntro}
								/>
								<InfoRowBool
									name={`Har du vært utredet for matematikkvansker av PPT eller andre? `}
									value={
										testUser.math
											.hasBeenAssessedForDifficulties
									}
									hasLongIntro={hasLongIntro}
								/>
								<InfoRowBool
									condition={
										testUser.math
											.hasBeenAssessedForDifficulties
									}
									name={`Har du en diagnose (f.eks. dyskalkuli eller spesifikke matematikkvansker)? `}
									value={testUser.math.hasDiagnosis}
								/>

								<InfoRowBool
									condition={
										!testUser.math
											.hasBeenAssessedForDifficultie
									}
									name={`Mistenker du selv at du har dyskalkuli? `}
									value={
										testUser.math.suspectsToHaveDyscalculia
									}
									hasLongIntro={hasLongIntro}
								/>

								<InfoRow
									name={`Har noen i din nærmeste familie (slektninger) mattevansker eller dyskalkuli? `}
									hasLongIntro={hasLongIntro}
								>
									{testUser.math
										.closeRelativeHasDifficulties ===
										'yes' && 'Ja'}
									{testUser.math
										.closeRelativeHasDifficulties ===
										'no' && 'Nei'}
									{testUser.math
										.closeRelativeHasDifficulties ===
										'doesNotKnow' && 'Vet ikke'}
								</InfoRow>
								<InfoRow
									name={`Hvordan vil du rangere dine egne matematikkferdigheter på en skala fra 1-6, hvor 1
                        er svært dårlig og 6 er svært bra? `}
									value={testUser.math.selfRating}
									hasLongIntro={hasLongIntro}
								/>
							</div>
							<div className="info__group info__group--adhd">
								<h3>{`Konsentrasjon`}</h3>
								<InfoRowBool
									name={`Har du problemer med konsentrasjon og utholdenhet? `}
									value={
										testUser.adhd
											.hasDifficultiesWithConcentrationAndStamina
									}
									hasLongIntro={hasLongIntro}
								/>
								<InfoRowBool
									name={`Kjeder du deg lett og blir rastløs? `}
									value={testUser.adhd.easilyBoredAndRestless}
									hasLongIntro={hasLongIntro}
								/>
								<InfoRowBool
									name={`Har du vært utredet for ADD eller ADHD? `}
									value={
										testUser.adhd
											.hasBeenAssessedForDifficulties
									}
									hasLongIntro={hasLongIntro}
								/>
								{testUser.adhd
									.hasBeenAssessedForDifficulties && (
									<InfoRowBool
										name={`Har du en diagnose (f.eks. ADD eller ADHD)? `}
										value={testUser.adhd.hasDiagnosis}
										hasLongIntro={hasLongIntro}
									/>
								)}
								{!testUser.adhd
									.hasBeenAssessedForDifficulties && (
									<InfoRowBool
										name={`Mistenker du selv at du har ADD eller ADHD? `}
										value={
											testUser.adhd
												.suspectsToHaveAddOrAdhd
										}
										hasLongIntro={hasLongIntro}
									/>
								)}
								<InfoRow
									name={`Har noen i din nærmeste familie (slektninger) diagnosen ADD eller ADHD? `}
									hasLongIntro={hasLongIntro}
								>
									{testUser.adhd
										.closeRelativeHasDifficulties ===
										'yes' && 'Ja'}
									{testUser.adhd
										.closeRelativeHasDifficulties ===
										'no' && 'Nei'}
									{testUser.adhd
										.closeRelativeHasDifficulties ===
										'doesNotKnow' && 'Vet ikke'}
								</InfoRow>
								<InfoRow
									name={`Hvordan vil du rangere din generelle konsentrasjonsevne på en skala fra 1-6, hvor 1
                        er svært dårlig og 6 er svært bra? `}
									value={testUser.adhd.selfRating}
									hasLongIntro={hasLongIntro}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default TestUserInfo;
