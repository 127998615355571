import React from 'react';
import 'quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import strings from 'utils/strings';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {setCurrentUser} from 'actions/index';
import {formats, modules} from 'components/CreateNotification/config';
import ToggleSwitch from 'components/Switch';
import DateTimeInput from 'components/Data&TimeComponent';
import AlertBox from 'components/CreateNotification/alert';
import updateNotification from 'api/updateNotification';

class Editor extends React.Component {
	constructor(props) {
		super(props);
		const {
			selectedNotification: {subject, details, summary},
		} = this.props;

		this.state = {
			notificationContent: details || '',
			subject: subject || '',
			showAlert: false,
			error: null,
			success: null,
			message: '',
			summary: summary || '',
			isSwitchOn: false,
			dateTime: '',
			isConfirmedDisabled: true,
		};
		this.handleProcedureContentChange =
			this.handleProcedureContentChange.bind(this);
		this.onConfirm = this.onConfirm.bind(this);
		this.toggleSwitch = this.toggleSwitch.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
	}

	toggleSwitch = () => {
		this.setState((prevState) => ({
			isSwitchOn: !prevState.isSwitchOn,
		}));
	};

	handleDateChange = (event) => {
		this.setState({
			dateTime: event.target.value,
			isConfirmedDisabled: false,
		});
	};

	handleProcedureContentChange = (content) => {
		if (content === '<p><br></p>') {
			this.setState({
				notificationContent: null,
				isConfirmedDisabled: true,
			});
		} else {
			this.setState({
				notificationContent: content,
				isConfirmedDisabled: false,
			});
		}
	};

	onConfirm = () => {
		const notificationObject = {
			id: this.props.selectedNotification.id,
			subject: this.state.subject,
			summary: this.state.summary,
			details: this.state.notificationContent,
			createdAt:
				this.state.isSwitchOn && this.state.dateTime
					? this.state.dateTime
					: this.props.selectedNotification.createdAt,
		};
		this.setState({
			isConfirmedDisabled: true,
		});
		updateNotification(notificationObject).then((res) => {
			this.setState({
				isConfirmedDisabled: false,
			});
			if (!res) {
				this.setState({
					showAlert: true,
					error: true,
					success: false,
					message: 'Ops! something went wrong.',
				});
				setTimeout(() => {
					this.setState({
						showAlert: false,
					});
					this.props.onEditModelConfirm();
				}, 3000);
			} else {
				this.props.fetchNotifications();
				this.setState({
					showAlert: true,
					error: false,
					success: true,
					message: 'Notification updated successfully',
				});
				setTimeout(() => {
					this.setState({
						showAlert: false,
					});
					this.props.onEditModelConfirm();
				}, 3000);
			}
		});
	};

	onCancel = (a) => {
		this.props.onEditModelCancel();
		this.setState({
			error: null,
			success: null,
			message: '',
		});
	};

	render() {
		const {
			notificationContent,
			showAlert,
			message,
			success,
			subject,
			summary,
			isSwitchOn,
			dateTime,
			isConfirmedDisabled,
		} = this.state;
		return (
			<div>
				<div>
					<h3>{strings.editNotification}</h3>
					<div className="lit-text-editor-div">
						<label>Subject:</label>
						<div>
							<input
								className="lit-input__field"
								value={subject}
								onChange={(e) => {
									this.setState({
										subject: e.target.value,
										isConfirmedDisabled: false,
									});
								}}
								required
								type="text"
							/>
						</div>
					</div>
					<div className="lit-text-editor-div">
						<label>Summary:</label>
						<div>
							<textarea
								className="lit-input__field"
								value={summary}
								onChange={(e) => {
									this.setState({
										summary: e.target.value,
										isConfirmedDisabled: false,
									});
								}}
								required
								type="text"
							/>
						</div>
					</div>
					<div className="lit-text-editor-input-switch">
						Schedule a notification: &nbsp;{' '}
						<ToggleSwitch
							isSwitchOn={isSwitchOn}
							toggleSwitch={this.toggleSwitch}
						/>
					</div>
					{isSwitchOn && (
						<div className="lit-text-editor-div">
							<DateTimeInput
								dateTime={dateTime}
								handleDateChange={this.handleDateChange}
							/>
						</div>
					)}
					<div className="lit-text-editor-content">
						<ReactQuill
							value={notificationContent}
							theme="snow"
							modules={modules}
							formats={formats}
							placeholder="write your content ...."
							onChange={this.handleProcedureContentChange}
						></ReactQuill>
					</div>
				</div>
				<div className="lit-post-notification-btn-div">
					<button
						className="lit-btn bg-primary"
						onClick={this.onConfirm}
						disabled={
							!subject ||
							!notificationContent ||
							!summary ||
							isConfirmedDisabled
						}
					>
						{strings.confirm}
					</button>

					<button
						className="lit-btn bg-negative"
						onClick={this.onCancel}
					>
						{strings.cancel}
					</button>
				</div>
				{showAlert && (
					<AlertBox
						type={success ? 'success' : 'error'}
						message={message}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {currentUser} = state;

	return {
		currentUser,
	};
};

const mapDispatchToProps = {
	handleSetCurrentUser: setCurrentUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Editor));
