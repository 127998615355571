import React, {Component} from 'react';
import './styles.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faXmark} from '@fortawesome/free-solid-svg-icons';
class AlertBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
		};
	}

	componentDidMount() {
		this.showAlert();
	}

	showAlert = () => {
		setTimeout(() => {
			this.setState({visible: true});
		}, 500);

		this.hideTimeout = setTimeout(() => {
			this.setState({visible: false});
		}, 2000);
	};

	componentWillUnmount() {
		clearTimeout(this.hideTimeout);
	}

	render() {
		const {message, type} = this.props;
		return (
			<div
				className={`alert-box ${type} ${
					this.state.visible ? 'visible' : ''
				}`}
			>
				<p>{message}</p>
				{type === 'success' ? (
					<span className="icon">
						<FontAwesomeIcon icon={faCheck} />
					</span>
				) : (
					<span className="icon">
						{' '}
						<FontAwesomeIcon icon={faXmark} />
					</span>
				)}
			</div>
		);
	}
}

export default AlertBox;
