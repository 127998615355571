import React from 'react';
import strings from 'utils/strings';
class NewCandidateModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isCreatingNewClass: true,
			userClasses: [],
		};
	}
	componentDidMount() {}
	render() {
		return (
			<div
				className="lit-modal"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<h3>NewCandidateModal</h3>
					<form
						className="register-form"
						onSubmit={() => {
							/* event.preventDefault();
							const testAdminResult = updateObject(testAdmin);
                            onSave(testAdminResult); */
						}}
					>
						<br />
						{/* <h3>{strings.personalinformation}</h3> */}
						<div className="lit-input inline">
							<label
								className="lit-input__label wide"
								htmlFor="test-admin-modal-firstName"
							>
								{strings.firstname}
							</label>
							<input
								className="lit-input__field"
								id="test-admin-modal-firstName"
								name="firstName"
								/* onChange={this.handleUpdateTestAdminEvent} */
								placeholder="Kari"
								required
								type="text"
								/* value={testAdmin.firstName || ''} */
							/>
						</div>
						<div className="lit-input inline">
							<label
								className="lit-input__label wide"
								htmlFor="test-admin-modal-lastName"
							>
								{strings.lastname}
							</label>
							<input
								className="lit-input__field"
								id="test-admin-modal-lastName"
								name="lastName"
								/* onChange={this.handleUpdateTestAdminEvent} */
								placeholder="Nordmann"
								required
								type="text"
								/* value={testAdmin.lastName || ''} */
							/>
						</div>
						<div className="lit-input inline">
							<label
								className="lit-input__label wide"
								htmlFor="test-admin-modal-organization"
							>
								{strings.shcool_or_company}
							</label>
							<input
								className="lit-input__field"
								id="test-admin-modal-organization"
								name="organization"
								/* onChange={this.handleUpdateTestAdminEvent} */
								placeholder="Kongsbakken vgs"
								type="text"
								/* value={testAdmin.organization || ''} */
							/>
						</div>
						<div className="lit-input inline">
							<label
								className="lit-input__label wide"
								htmlFor="test-admin-modal-email"
							>
								{strings.email}
							</label>
							<input
								className="lit-input__field"
								id="test-admin-modal-email"
								name="email"
								onChange={this.handleUpdateTestAdminEvent}
								placeholder="kari@nordmann.no"
								required
								type="email"
								/* value={testAdmin.email || ''} */
							/>
						</div>
						<div className="lit-input inline">
							<label
								className="lit-input__label wide"
								htmlFor="test-admin-modal-phone"
							>
								{strings.telephone}
							</label>
							<input
								className="lit-input__field"
								id="test-admin-modal-phone"
								name="phone"
								/* onChange={this.handleUpdateTestAdminEvent} */
								placeholder="987 65 432"
								required
								type="tel"
								/* value={testAdmin.phone || ''} */
							/>
						</div>

						<div className="lit-input">
							<button className="lit-btn bg-primary">
								{
									/* `${
									isCreate
										? strings.addtestadministrator
										: strings.saveuserinformation
								}` */
									'Ny Kandidat'
								}
							</button>
							<button
								className="lit-btn bg-negative2"
								onClick={() => this.props.closeWindow()}
							>
								{strings.cancel}
							</button>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
export default NewCandidateModal;
