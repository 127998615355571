import {ENDPOINT_URL} from 'constants/index';
import {apiErrorHandling} from 'utils/apiUtils';
import strings from 'utils/strings';

import getFetchOptions from 'utils/getFetchOptions';

export default function updateLogEvent(flag, id, key, action) {
	return fetch(
		`${ENDPOINT_URL}/updatelogevent`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify({
				flag,
				id,
				key,
				action,
			}),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
