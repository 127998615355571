import {ENDPOINT_URL} from 'constants/index';
import {apiErrorHandling} from 'utils/apiUtils';

import getFetchOptions from 'utils/getFetchOptions';

//removing sub_id from api func.
export default function createBulkTests(
	testkey_Types,
	nKeys,
	groupId,
	language,
	type,
	childSelfStarting
) {
	return fetch(
		`${ENDPOINT_URL}/bulktests/create`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify({
				testkey_Types,
				nKeys,
				groupId,
				language,
				type,
				childSelfStarting,
			}),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
