import React, {Component} from 'react';
import strings from 'utils/strings';

class SubtestAnswers extends Component {
	constructor(props) {
		super(props);

		this.state = {
			viewMode: 'SHOW_ANSWERED',
		};
	}

	setViewMode(value) {
		this.setState({
			viewMode: value,
		});
	}

	toggleShowUnanswered() {
		if (this.state.viewMode === 'SHOW_ALL') {
			this.setViewMode('SHOW_ANSWERED');
		} else {
			this.setViewMode('SHOW_ALL');
		}
	}
	renderRepeatAnswer(repeatAnswer, key) {
		return (
			<span className="sub-test__answer--wrong" key={key}>
				<strike>{repeatAnswer + ' '}</strike>
			</span>
		);
	}

	checkWordWithArray(word, array, key) {
		const answer = word
			.replace(/\,/g, '') //eslint-disable-line
			.replace(/\./g, '') //eslint-disable-line
			.trim()
			.toLowerCase();
		for (let i = 0; i < array.length; i++) {
			const correct = array[i].trim().toLowerCase();
			if (answer === correct) {
				return (
					<span className="sub-test__answer--correct" key={key}>
						{answer + ' '}
					</span>
				);
			}
		}
		return (
			<span className="sub-test__answer--wrong" key={key}>
				{answer + ' '}
			</span>
		);
	}

	render() {
		const {subtest} = this.props;
		const {viewMode} = this.state;
		const {formattedAnswers, answersCorrect} = subtest;

		let notAnswered = 0;
		let answersClass = '';
		let isVisible = true;
		switch (viewMode) {
			case 'SHOW_ALL':
				answersClass = 'sub-test__answers--show-not-answered';
				break;
			case 'SHOW_ANSWERED':
				answersClass = '';
				break;
			case 'HIDE_ALL':
			default: {
				isVisible = false;
			}
		}

		switch (subtest.id) {
			case 1:
			case 3:
			case 6: {
				answersClass += ' col-4';
				break;
			}
			case 4: {
				answersClass += ' col-1';
				break;
			}
			case 5: {
				answersClass += ' col-2';
				break;
			}
			default:
				break;
		}

		const getCorrectClass = (isCorrect) => {
			return `sub-test__answer--${isCorrect ? 'correct' : 'wrong'}`;
		};
		const renderSubTest1Answers = (answer, index) => {
			const correctClass = getCorrectClass(answer.isCorrect);

			return (
				<div className={`sub-test__answer`} key={index}>
					<p>
						{answer.id + '. '}
						<span className={correctClass}>{answer.value}</span>
					</p>
					{answer.correctValues.map((correctValue) => (
						<p key={correctValue}>
							{strings.formatString(
								strings.rightanswer,
								`${correctValue}`
							)}
						</p>
					))}
				</div>
			);
		};
		const renderSubTest2Answers = (answer, index) => {
			const correctClass = getCorrectClass(answer.isCorrect);
			let wrapperClass = 'sub-test__answer';
			let answerText = answer.value;
			if (answer.value.indexOf(' ') === -1) {
				notAnswered++;
				answerText = strings.notanswered;
				wrapperClass += ' sub-test__answer--not-answered';
			}
			return (
				<div className={wrapperClass} key={index}>
					<p>
						{answer.id + '. '}
						<span className={correctClass}>{answerText}</span>
					</p>
					{answer.correctValues.map((correctValue) => (
						<p key={correctValue}>
							{strings.formatString(
								strings.rightanswer,
								`${correctValue}`
							)}
						</p>
					))}
				</div>
			);
		};
		const renderSubTest3Answers = (answer, index) => {
			let wrapperClass = 'sub-test__answer';
			const correctClass = getCorrectClass(answer.isCorrect);
			let answerText = answer.value;
			if (!answer.value) {
				notAnswered++;
				answerText = strings.notanswered;
				wrapperClass += ' sub-test__answer--not-answered';
			}
			return (
				<div className={wrapperClass} key={index}>
					<p>
						{answer.id + '. '}
						<span className={correctClass}>{answerText}</span>
					</p>
					{answer.correctValues.map((correctValue) => (
						<p key={correctValue}>
							{strings.formatString(
								strings.rightanswer,
								`${correctValue}`
							)}
						</p>
					))}
				</div>
			);
		};
		const renderSubTest4Answers = (answers, index) => {
			let isEvaluated = [];
			let spanArray = [];
			let medFlag = 2;

			{
				answers.map((subanswer, index) => {
					if (subanswer.value.toLowerCase() === 'med') {
						medFlag = medFlag - 1;
					}
					if (
						!isEvaluated.includes(
							subanswer.value
								.replace(/\,/g, '')
								.replace(/\./g, '')
								.trim()
								.toLowerCase()
						)
					) {
						if (subanswer.value.toLowerCase() !== 'med')
							isEvaluated.push(subanswer.value.toLowerCase());
						if (
							subanswer.value.toLowerCase() === 'med' &&
							medFlag <= 0
						)
							isEvaluated.push(subanswer.value.toLowerCase());

						spanArray.push(
							this.checkWordWithArray(
								subanswer.value,
								answersCorrect,
								index
							)
						);
					} else {
						spanArray.push(
							this.renderRepeatAnswer(subanswer.value, index)
						);
					}
				});
			}
			return (
				<li className="sub-test__answer__li" key={`${index}`}>
					{spanArray.map((spans) => {
						return spans;
					})}
				</li>
			);
		};
		const renderSubTest5Answers = (answer, index) => {
			let wrapperClass = 'sub-test__answer';
			const correctClass = getCorrectClass(answer.isCorrect);
			let answerText = '';
			if (answer.choices && answer.value) {
				answerText = answer.choices[answer.value].choice;
			}

			if (!answer.value) {
				notAnswered++;
				answerText = strings.notanswered;
				wrapperClass += ' sub-test__answer--not-answered';
			}

			return (
				<div className={wrapperClass} key={index}>
					<p>
						{`${answer.id}. `}
						{answer.question ? answer.question : ''}
					</p>
					<p className={correctClass}>{answerText}</p>
				</div>
			);
		};
		const renderSubTest6Answers = (answer, index) => {
			let wrapperClass = 'sub-test__answer';
			const correctClass = getCorrectClass(answer.isCorrect);

			let answerText = '';

			if (!answer.value) {
				notAnswered++;
				answerText = strings.notanswered;
				wrapperClass += ' sub-test__answer--not-answered';
			}

			if (answer.choices && answer.value) {
				answerText = answer.choices[answer.value].choice;
			}
			wrapperClass += ' sub-test__answer--compact ';

			return (
				<div className={wrapperClass} key={index}>
					<p>
						{`${answer.id}. `}
						{answer.question ? answer.question : ''}
					</p>
					<p>
						<span className={correctClass}>{answerText}</span>
					</p>
				</div>
			);
		};

		const answers = formattedAnswers.map((answer, index) => {
			switch (subtest.id) {
				case 1: {
					return renderSubTest1Answers(answer, index);
				}
				case 2: {
					return renderSubTest2Answers(answer, index);
				}
				case 3: {
					return renderSubTest3Answers(answer, index);
				}
				case 4: {
					return renderSubTest4Answers(answer, index);
				}
				case 5: {
					return renderSubTest5Answers(answer, index);
				}
				case 6: {
					return renderSubTest6Answers(answer, index);
				}
				default:
					return '';
			}
		});

		return (
			isVisible &&
			formattedAnswers.length && (
				<div>
					<h4 className="sub-test__answers__heading">
						{strings.candidate_answers}
					</h4>
					<div className={`sub-test__answers ${answersClass}`}>
						{subtest.id === 4 && (
							<ol className="sub-test__answer__ol">{answers}</ol>
						)}
						{subtest.id !== 4 && answers}
					</div>
					{notAnswered > 0 && (
						<p className="sub-test__not-answered">
							<b>
								{strings.formatString(
									strings.testnotanswered,
									`${notAnswered}`
								)}
							</b>
							{/* <b>{`${notAnswered} oppgaver ikke besvart`}</b>{' '} */}
							<button
								className="sub-test__link lit-link-button print-hide"
								onClick={(e) => {
									e.preventDefault();
									this.toggleShowUnanswered(subtest.id);
								}}
							>
								{viewMode === 'SHOW_ALL'
									? strings.hideunanswered
									: strings.showunanswered}
							</button>
						</p>
					)}
				</div>
			)
		);
	}
}

export default SubtestAnswers;
