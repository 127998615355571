import {ENDPOINT_URL} from 'constants/index';
import {apiErrorHandling} from 'utils/apiUtils';

import getFetchOptions from 'utils/getFetchOptions';

export default function updateAdminInfoSelf(testAdmin) {
	const testAdminInfo = {
		id: testAdmin.id,
		firstName: testAdmin.firstName,
		email: testAdmin.email,
		lastName: testAdmin.lastName,
		organization: testAdmin.organization,
		phone: testAdmin.phone,
		municipality: testAdmin.municipality,
	};
	return fetch(
		`${ENDPOINT_URL}/adminuserself/${testAdminInfo.id}`,
		getFetchOptions({
			method: 'PUT',
			body: JSON.stringify(testAdminInfo),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
/* 
/adminuserself/:id
*/
