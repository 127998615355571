import React, {Component} from 'react';
import './styles.scss';

class SelectDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			selectedOption: this.props.defaultValue || '',
		};
	}

	componentDidMount() {
		document.addEventListener('click', this.handleOutsideClick);
	}

	componentWillUnmount() {    
		document.removeEventListener('click', this.handleOutsideClick);
	}              
	toggleDropdown = () => {
		this.setState((prevState) => ({
			isOpen: !prevState.isOpen,
		}));
	};

	handleOutsideClick = (event) => {
		if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
			this.setState({isOpen: false});
		}
	};

	handleOptionClick = (option) => {
		this.setState({
			selectedOption: option.label,
			isOpen: false,
		});
		if (option.id) {
			this.props.onChange(option.id);
		}
	};

	render() {
		const {options, disabledLabel} = this.props;
		const {isOpen, selectedOption} = this.state;
		return (
			<div
				className="select-container"
				ref={(node) => (this.dropdownRef = node)}
			>
				<div className="select-display" onClick={this.toggleDropdown}>
					{selectedOption || disabledLabel}
				</div>
				{isOpen && (
					<div className="dropdown-list">
						{options.map((option) => (
							<div
								key={option.id}
								className={`dropdown-item ${
									option.label === disabledLabel
										? 'disabled'
										: ''
								}`}
								onClick={() => this.handleOptionClick(option)}
							>
								{option.label}
							</div>
						))}
					</div>
				)}
			</div>
		);
	}
}

export default SelectDropdown;
