import {ENDPOINT_URL} from 'constants/index';
import getFetchOptions from 'utils/getFetchOptions';
import {apiErrorHandling} from 'utils/apiUtils';
import {updateObject} from 'utils/reducerUtils';
import getSubscriptionSummary from 'utils/getSubscriptionSummary';

export default function getTestAdminSummary(initialDate, finalDate, country) {
	return fetch(
		`${ENDPOINT_URL}/getsummary/${initialDate}/${finalDate}/${country}`,
		getFetchOptions({
			method: 'GET',
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.then((summaryData) => {
			return summaryData.map((data) =>
				updateObject(data, {userStage: getSubscriptionSummary(data)})
			);
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
