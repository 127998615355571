import React from 'react';
import {connect} from 'react-redux';
import {ADDMUNICIPALITY, VIEWMUNICIPALITY} from 'constants/index';
import './styles.scss';
import strings from 'utils/strings';
import moment from 'moment';
import getMunicipalities from '../../api/getMunicipalities';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPenToSquare, faTrash} from '@fortawesome/free-solid-svg-icons';
import AddMunicipality from 'components/AddMunicipality';
import InformationPane from 'components/InformationPane';
import deleteMunicipality from 'api/deleteMunicipality';
import EditMunicipalityModal from './editMunicipalityModel';

const feideFilters = [VIEWMUNICIPALITY, ADDMUNICIPALITY];

class FeideTwo extends React.Component {
	constructor() {
		super();

		this.state = {
			activeFilter: VIEWMUNICIPALITY,
			municipalities: [],
			renderPaneAlert: false,
			paneMessage: 'Er du sikker på at du vil slette denne kommunen?',
			isEditModelOpen: false,
		};
		this.fetchMunicipalities = this.fetchMunicipalities.bind(this);
		this.onEditItemClick = this.onEditItemClick.bind(this);
		this.onListItemDelete = this.onListItemDelete.bind(this);
	}
	componentDidMount() {
		this.fetchMunicipalities();
	}

	fetchMunicipalities() {
		this.setState({
			isEditModelOpen: false,
		});
		return getMunicipalities().then((municipalities) => {
			this.setState({
				municipalities,
			});
		});
	}
	onEditModelConfirm = () => {
		this.setState({
			isEditModelOpen: false,
		});
	};

	onEditModelCancel = (a) => {
		this.setState({
			isEditModelOpen: false,
		});
	};
	onEditItemClick = (id, municipalities) => {
		const municipality = municipalities.filter((item) => item.id === id);
		this.setState({
			isEditModelOpen: true,
			selectedMunicipality: municipality[0],
		});
	};
	onListItemDelete = (id) => {
		deleteMunicipality(id).then((res) => {
			if (!res) {
				this.setState({
					showAlert: true,
					error: true,
					success: false,
					message: 'Ops! something went wrong.',
				});
				setTimeout(() => {
					this.setState({
						showAlert: false,
					});
				}, 3000);
			} else {
				this.setState({
					showAlert: true,
					error: false,
					success: true,
					message: 'Kommunen ble slettet!',
				});
				this.fetchMunicipalities();

				setTimeout(() => {
					this.setState({
						showAlert: false,
					});
				}, 3000);
			}
		});
	};

	render() {
		const {
			municipalities,
			activeFilter,
			selectedMunicipality,
			renderPaneAlert,
			isEditModelOpen,
		} = this.state;

		return (
			<div className="lit-all-tests">
				<form
					onSubmit={(event) => {
						event.preventDefault();
						this.handleSubmitTestKey();
					}}
				>
					<div className="lit-filters-row">
						<div className="lit-dropdown_filter">
							<select
								className="lit-input__field"
								defaultValue={VIEWMUNICIPALITY}
								onChange={(e) => {
									this.setState({
										activeFilter: feideFilters.filter(
											(filter) =>
												filter.id === e.target.value
										)[0],
									});
								}}
							>
								{feideFilters.map((userStageFilter) => (
									<option
										key={userStageFilter.id}
										value={userStageFilter.id}
									>
										{userStageFilter.label ||
											userStageFilter.text}
									</option>
								))}
							</select>
						</div>
					</div>
				</form>
				{activeFilter === VIEWMUNICIPALITY && (
					<div>
						{Boolean(municipalities.length < 1) && (
							<p>{strings.noMunicipalityFound}</p>
						)}
						{Boolean(municipalities.length) && (
							<div className="main">
								<table className="list">
									<thead>
										<tr>
											<th>{strings.createdAt}</th>
											<th>{strings.municipalityName}</th>
											<th>
												{strings.municipalityNumber}
											</th>

											<th>{strings.actions}</th>
										</tr>
									</thead>
									<tbody>
										{municipalities.map((item) => {
											const {
												id,
												municipality,
												uuid,
												createdAt,
											} = item;

											return (
												<tr
													className="lit-list__item"
													key={id}
												>
													<td>
														{moment(
															createdAt
														).format('LLL')}
													</td>

													<td>{municipality}</td>
													<td>{uuid}</td>
													<td>
														<div className="action-buttons">
															<button
																className="edit-button"
																title="Edit Municipality"
																onClick={() => {
																	this.onEditItemClick(
																		id,
																		municipalities
																	);
																}}
															>
																<FontAwesomeIcon
																	size="lg"
																	className="lit-mytests-header-mytest-icon-btn-icon"
																	icon={
																		faPenToSquare
																	}
																/>
															</button>
															<button
																className="delete-button"
																title="Delete Municipality"
																onClick={() => {
																	this.setState(
																		{
																			renderPaneAlert: true,
																			selectedMunicipality: id,
																		}
																	);
																}}
															>
																<FontAwesomeIcon
																	size="lg"
																	className="lit-mytests-header-mytest-icon-btn-icon"
																	icon={
																		faTrash
																	}
																/>
															</button>
														</div>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}
					</div>
				)}
				{activeFilter === ADDMUNICIPALITY && (
					<AddMunicipality
						refreshMunicipalities={this.fetchMunicipalities}
					/>
				)}
				{renderPaneAlert && (
					<InformationPane
						paneMessage={this.state.paneMessage}
						onApproval={() => {
							this.setState({
								renderPaneAlert: false,
							});
							this.onListItemDelete(selectedMunicipality);
						}}
						onCloseWindow={() => {
							this.setState({
								renderPaneAlert: false,
							});
						}}
						onDisproval={() => {
							this.setState({
								renderPaneAlert: false,
							});
						}}
						category={'confirmation'}
					/>
				)}
				{isEditModelOpen && (
					<EditMunicipalityModal
						onConfirm={this.onEditModelConfirm}
						onCancel={this.onEditModelCancel}
						selectedMunicipality={selectedMunicipality}
						refreshMunicipalities={this.fetchMunicipalities}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FeideTwo);
