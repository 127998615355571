import React, {Component} from 'react';
import './styles.scss';
import strings from 'utils/strings';
import AlertBox from 'components/CreateNotification/alert';
import addMunicipality from 'api/addMunicipality';

class AddMunicipality extends Component {
	constructor(props) {
		super(props);
		this.state = {
			municipality: '',
			uuid: 'NO',
			showAlert: false,
		};
	}

	handleInputChange = (e) => {
		const {name, value} = e.target;

		if (name === 'uuid') {
			if (!value.startsWith('NO')) {
				this.setState({uuid: 'NO'});
			} else {
				this.setState({uuid: value});
			}
		} else {
			this.setState({
				[name]: value,
			});
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const {municipality, uuid} = this.state;

		const updatedUuid = uuid.startsWith('NO') ? uuid : `NO${uuid}`;
		addMunicipality(municipality, updatedUuid).then((res) => {
			if (!res) {
				this.setState({
					showAlert: true,
					error: true,
					success: false,
					message: 'Ops! something went wrong.',
				});
				setTimeout(() => {
					this.setState({
						showAlert: false,
					});
				}, 3000);
			} else {
				this.setState({
					showAlert: true,
					error: false,
					success: true,
					message: strings.municipalityAdded,
					municipality: '',
					uuid: 'NO',
				});
				this.props.refreshMunicipalities();
				setTimeout(() => {
					this.setState({
						showAlert: false,
					});
				}, 3000);
			}
		});
	};

	render() {
		const {municipality, uuid, showAlert, success, message} = this.state;

		return (
			<div className="form-container">
				<h2>{strings.addMunicipality}</h2>
				<form onSubmit={this.handleSubmit}>
					<input
						type="text"
						name="municipality"
						placeholder={strings.name}
						value={municipality}
						onChange={this.handleInputChange}
					/>
					<input
						type="text"
						name="uuid"
						placeholder={strings.municipalityNumber}
						value={uuid}
						onChange={this.handleInputChange}
					/>
					<button disabled={!municipality || !uuid} type="submit">
						{strings.confirm}
					</button>
				</form>
				{showAlert && (
					<AlertBox
						type={success ? 'success' : 'error'}
						message={message}
					/>
				)}
			</div>
		);
	}
}

export default AddMunicipality;
