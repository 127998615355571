import React from 'react';
import Editor from './editor';

class EditNotificationModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="lit-modal" onClick={() => this.props.onCancel()}>
				<div
					className="lit-modal__other"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<div>
						<Editor
							onEditModelConfirm={this.props.onConfirm}
							onEditModelCancel={this.props.onCancel}
							selectedNotification={
								this.props.selectedNotification
							}
							fetchNotifications={this.props.fetchNotifications}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default EditNotificationModal;
