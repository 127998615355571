import React from 'react';
import './styles.scss';
import strings from 'utils/strings';
import classNames from 'classnames';
import {updateObject} from 'utils/reducerUtils';
import updateTestAdminMunicipality from 'api/updateTestAdminMunicipality';
import AlertBox from 'components/CreateNotification/alert';
class AddTestAdminsToMunicipality extends React.Component {
	constructor(props) {
		super(props);
		this.state = {uuid: 'NO', showAlert: false};
	}
	handleInputChange = (e) => {
		const {name, value} = e.target;

		if (name === 'uuid') {
			if (!value.startsWith('NO')) {
				this.setState({uuid: 'NO'});
			} else {
				this.setState({uuid: value});
			}
		} else {
			this.setState({
				[name]: value,
			});
		}
	};
	saveTestAdmin(testAdminUpdates) {
		const testAdminIds = this.props.selectedUsers.map((item) => item.id);
		if (!testAdminUpdates || !testAdminIds) {
			return;
		}
		const updatedObject = {...testAdminUpdates, testAdminIds};
		updateTestAdminMunicipality(updatedObject).then((res) => {
			if (!res) {
				this.setState({
					showAlert: true,
					error: true,
					success: false,
					message: 'Ops! something went wrong.',
				});
				setTimeout(() => {
					this.setState({
						showAlert: false,
					});
				}, 3000);
			} else {
				this.setState({
					showAlert: true,
					error: false,
					success: true,
					message: strings.municipalityAdded,
					municipality: '',
				});

				setTimeout(() => {
					this.setState({
						showAlert: false,
						uuid: 'NO',
					});
					this.props.closeWindow();
				}, 3000);
			}
		});
	}
	render() {
		const theUsers = this.props.selectedUsers;
		const {uuid, showAlert, success, message} = this.state;

		return (
			<div
				className="lit-modal"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<h3>{strings.selected_users}</h3>
					<table id="users_table" className="lit-list-user-list">
						<thead>
							<tr>
								<th className="lit-list-users-new-group-header">
									{strings.firstname +
										', ' +
										strings.lastname}
								</th>
								<th className="lit-list-users-new-group-header">
									{strings.organization}
								</th>
								<th className="lit-list-users-new-group-header">
									{strings.email}
								</th>
								<th className="lit-list-users-new-group-header">
									{strings.telephone}
								</th>
							</tr>
						</thead>
						<tbody>
							{theUsers.map((theUser) => {
								return (
									<tr
										className="lit-list__item"
										key={theUser.id}
									>
										<td className="lit-list-users-new-group-col">
											<div className="lit-list__text">
												{theUser.firstName &&
													`${theUser.firstName}, ${theUser.lastName}`}
											</div>
										</td>
										<td className="lit-list-users-new-group-col">
											<div className="lit-list__text">
												{theUser.organization}
											</div>
										</td>
										<td className="lit-list-users-new-group-col">
											<div className="lit-list__text">
												{theUser.email}
											</div>
										</td>
										<td className="lit-list-users-new-group-col">
											<div className="lit-list__text">
												{theUser.phone}
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className="input-add-municipality-container">
						<input
							type="text"
							name="uuid"
							placeholder={strings.municipalityNumber}
							value={uuid}
							onChange={this.handleInputChange}
						/>
					</div>
					<div className="lit_create_new-group">
						<button
							disabled={!uuid || uuid === 'NO'}
							className={classNames('print-hide', {
								'lit-btn see-more': true,
								'bg-tertiary': true,
							})}
							onClick={() => {
								const testAdminResult = updateObject({
									municipality: uuid,
								});
								this.saveTestAdmin(testAdminResult);
							}}
						>
							{strings.addMunicipality}
						</button>
						<button
							className="lit-btn bg-negative2"
							onClick={() => {
								this.props.closeWindow();
							}}
						>
							{strings.cancel}
						</button>
					</div>
				</div>
				{showAlert && (
					<AlertBox
						type={success ? 'success' : 'error'}
						message={message}
					/>
				)}
			</div>
		);
	}
}
export default AddTestAdminsToMunicipality;
