import {ENDPOINT_URL} from 'constants/index';
import getFetchOptions from 'utils/getFetchOptions';
import {apiErrorHandling} from 'utils/apiUtils';

export default function getAllGroups() {
	return fetch(
		`${ENDPOINT_URL}/allgroups`,
		getFetchOptions({
			method: 'GET',
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
