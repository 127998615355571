import React, {Component} from 'react';
import './styles.scss';

class ToggleSwitch extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="toggle-switch" onClick={this.props.toggleSwitch}>
				<div
					className={`switch ${
						this.props.isSwitchOn ? 'switch-on' : 'switch-off'
					}`}
				>
					<div
						className={`toggle ${
							this.props.isSwitchOn ? 'toggle-on' : 'toggle-off'
						}`}
					/>
				</div>
			</div>
		);
	}
}

export default ToggleSwitch;
