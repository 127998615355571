import React, {Component} from 'react';
import './styles.scss';

class DateTimeInput extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<input
				type="datetime-local"
				id="datetime-input"
				value={this.props.dateTime}
				onChange={this.props.handleDateChange}
				className="datetime-input"
			/>
		);
	}
}

export default DateTimeInput;
