import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import createAuthToken from 'api/createAuthToken';

import './styles.scss';

import {BANK_ID, BANK_ID_MOBILE, FEIDE} from 'constants/index';

import {initAuthAction} from 'actions/index';
import strings from 'utils/strings';

const testKey = null;
let feidSelection = false;
class VerifyCurrentLogin extends React.Component {
	constructor() {
		super();
		this.state = {
			authToken: null,
			authError: null,
		};

		this.createdAndFetchRegistrationToken = this.createdAndFetchRegistrationToken.bind(
			this
		);
	}

	componentWillMount() {
		this.createdAndFetchRegistrationToken();
	}
	async createdAndFetchRegistrationToken() {
		const adminId = this.props.testAdmin.id;
		const email = this.props.testAdmin.email;

		createAuthToken(adminId, email).then((res) => {
			this.setState({
				authToken: res[0].UUID,
				authError: this.props.error,
			});
		});
	}
	returnNewMethod(canHaveFeide2) {
		if (canHaveFeide2) {
			return 'Feide 2.0';
		}
		return 'Feide';
	}
	returnInstructions(loginMethods, canHaveFeide2) {
		if (loginMethods.includes('feide') && canHaveFeide2) {
			return `Du har nå valgt å bytte til Feide 2.0. Vennligst
			start med å identifisere deg selv.
			Husk å bruke samme metode som du
			bruker for å logge på.`;
		}

		return `
			Du har nå valgt å bytte til en annet
			identifiseringsmetode. Vennligst
			start med å identifisere deg selv.
			Husk å bruke samme metode som du
			bruker for å logge på.`;
	}
	render() {
		const {
			error,
			iframeUrl,
			isFeide,
			initAuth,
			isInit,
			loginMethods,
			canHaveFeide2,
			hasFeide2,
		} = this.props;
		const showIframe = isInit && iframeUrl;
		if (showIframe && isFeide) window.location.href = `${iframeUrl}`;
		const {authToken} = this.state;
		const loginClass = 'lit-verifyLogin';

		return (
			<div
				className="lit-modal"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__island-newLogin"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<div className="lit-newlogin">
						<div className="lit-newLogin__content">
							<div className={'lit-verifyLogin'}>
								{!isInit && (
									<div
										className={
											loginClass + '__wrapper bg-white'
										}
									>
										<h2 className={loginClass + '__header'}>
											{'Bytt til ' +
												`${this.returnNewMethod(
													canHaveFeide2
												)}`}
										</h2>
										<p
											className={
												loginClass + '__description'
											}
										>
											{`${this.returnInstructions(
												loginMethods,
												canHaveFeide2
											)}`}
										</p>

										{!loginMethods.includes('feide') &&
											!canHaveFeide2 && (
												<div
													className={
														loginClass +
														'__action-wrapper'
													}
												>
													<button
														className={
															'lit-btn bg-primary'
														}
														onClick={() => {
															initAuth(
																BANK_ID,
																testKey,
																authToken,
																this.props
																	.chosenMethod,
																'verify'
															);
														}}
													>
														{strings.bankid}
													</button>
													<button
														className="lit-btn bg-primary bg-border"
														onClick={() => {
															initAuth(
																BANK_ID_MOBILE,
																testKey,
																authToken,
																this.props
																	.chosenMethod,
																'verify'
															);
														}}
													>
														{strings.bankidonmobile}
													</button>
												</div>
											)}
										{!loginMethods.includes('feide') &&
											canHaveFeide2 &&
											!hasFeide2 && (
												<div
													className={
														loginClass +
														'__action-wrapper'
													}
												>
													<button
														className={
															'lit-btn bg-primary'
														}
														onClick={() => {
															initAuth(
																FEIDE, //change it to FEIDE
																testKey,
																authToken,
																this.props
																	.chosenMethod,
																'feide2pointZero',
																this.props
																	.testAdmin
																	.id
															);
														}}
													>
														{'Logg inn med Feide'}
													</button>
												</div>
											)}
										{loginMethods.includes('feide') &&
											canHaveFeide2 &&
											!hasFeide2 && (
												<div
													className={
														loginClass +
														'__action-wrapper'
													}
												>
													<button
														className={
															'lit-btn bg-primary'
														}
														onClick={() => {
															initAuth(
																FEIDE, //change it to FEIDE
																testKey,
																authToken,
																this.props
																	.chosenMethod,
																'feide2pointZero',
																this.props
																	.testAdmin
																	.id
															);
														}}
													>
														{'Logg inn med Feide'}
													</button>
												</div>
											)}
									</div>
								)}
								{error && (
									<div className="lit-change-login-error">
										<p className="find-me">
											Unable to change login Method:
											Error::
											<strong>{error}.</strong>
											Please try again later.
										</p>
									</div>
								)}

								{/* {showIframe && feidSelection && (
									<div className="bank-id-iframe-wrapper">
										<iframe
											title="login-frame"
											frameBorder="0"
											id="authFrame"
											scrolling="no"
											src={iframeUrl}
											style={{
												width: '120%',
												minHeight: '900px',
											}}
										/>
									</div>
								)} */}
								{showIframe && !isFeide && (
									<div className="bank-id-iframe-wrapper">
										<iframe
											title="login-frame"
											frameBorder="0"
											id="authFrame"
											scrolling="no"
											src={iframeUrl}
											style={{
												width: '100%',
												minHeight: '200px',
											}}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

VerifyCurrentLogin.propTypes = {
	error: PropTypes.string.isRequired,
	iframeUrl: PropTypes.string.isRequired,
	initAuth: PropTypes.func.isRequired,
	isInit: PropTypes.bool.isRequired,
	isFeide: PropTypes.bool,
};

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit, isFeide} = state.auth;

	return {
		error,
		iframeUrl,
		isInit,
		isFeide,
	};
};

const mapDispatchToProps = {
	initAuth: initAuthAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCurrentLogin);
