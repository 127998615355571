import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import contract from 'assets/Literate_KundeAvtale.pdf';
import AdminInformation from './AdminInformation';
import SubscriptionTable from './SubscriptionTable';
import EditSubscription from './EditSubscription';
/* ToDO:: grab all inclusive muncipalites and allow users to update to feide 2.0 */
import {Link} from 'react-router-dom';

import './styles.scss';

import {
	CURRENCY_SYMBOL,
	TEST_PRICE,
	SUBSCRIPTION_PRICE,
	TRIAL,
	BASIC,
} from 'constants/index';

import getSingleTestAdmin from 'api/getSingleTestAdmin';
import updateAdminInfoSelf from 'api/updateAdminInfoSelf';
import retrieveDeletedTests from 'api/retrieveDeletedTests';
import getLoginMethod from 'api/getLoginMethod';
import editLanguageSubscription from 'api/editLanguageSubscription';
import getMyGroups from 'api/getMyGroups';
import getFeideConfig from 'api/getFeideConfig';

import getSortedSubscriptionTestPeriods from 'utils/getSortedSubscriptionTestPeriods';
import getUserStage from 'utils/getUserStage';
import isTrialActive from 'utils/isTrialActive';

import {updateObject} from 'utils/reducerUtils';

import UserStageIndicator from 'components/UserStageIndicator';
import strings from 'utils/strings';

const addTitleToTestPeriods = (testPeriod) => {
	const testPeriodMoment = moment().set({
		year: testPeriod.year,
		month: testPeriod.month,
	});

	return updateObject(testPeriod, {
		date: testPeriodMoment,
		title: testPeriodMoment.format('MMM YYYY'),
	});
};

class MySubscription extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			testAdmin: null,
			testCountCurrentPeriod: 0,
			testCountTotal: 0,
			showDeleted: false,
			showPrevious: false,
			showPricingInfo: false,
			showTable: false,
			groupData: null,
			editSubscription: false,
			languageSubscription: null,
			showSubscriptionTab: true,
			showInformationTab: false,
			showGroupTab: false,
			showSubscriptionActive: 'tablink1-active',
			showInformationActive: 'tablink2',
			showGroupActive: 'tablink3',
			animator: 'marker-default',
			loginMethods: [],
			canHaveFeide2: false,
			hasFeide2: false,
		};
		this.retreiveTest = this.retreiveTest.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleUpdateTestAdminEvent = this.handleUpdateTestAdminEvent.bind(
			this
		);
	}

	componentDidMount() {
		const {currentUser} = this.props;
		let id = this.props.match.params.id;
		if (!id) {
			id = currentUser.id;
		}
		this.getAndSetSingleTestAdmin(id);
		this.getAllMyGroups(id);
		this.getAndSetLoginMethods(id);
	}
	getAllMyGroups(id) {
		getMyGroups(id).then((results) => {
			let hasFeide2 = false;
			hasFeide2 = results.some((config) => {
				if (config.isFeide === true) {
					return true;
				}
			});
			if (results) {
				this.setState({
					groupData: results,
					hasFeide2,
				});
			}
		});
	}
	handleUpdateTestAdminEvent(event) {
		const target = event.target;
		let updatedmunicipality = null;
		if (target.name === 'municipality') {
			updatedmunicipality = target.value.startsWith('NO')
				? target.value
				: `NO${target.value}`;
		}

		const key = target.name;
		const value = updatedmunicipality || target.value;

		this.handleUpdate(key, value);
	}

	handleUpdate(key, value) {
		const {testAdmin} = this.state;
		this.setState({
			testAdmin: updateObject(testAdmin, {
				[key]: value,
			}),
		});
	}
	saveTestAdmin(testAdminUpdates) {
		const {currentUser} = this.props;
		let id = this.props.match.params.id;
		if (!id) {
			id = currentUser.id;
		}

		if (!testAdminUpdates) {
			this.setState({
				isEditingTestAdmin: null,
			});
			return;
		}

		this.setState({
			isEditingTestAdmin: null,
		});
		updateAdminInfoSelf(testAdminUpdates).then(() => {
			this.getAndSetSingleTestAdmin(id);
			window.location.reload(false);
		});
	}

	getAndSetSingleTestAdmin(id) {
		return getSingleTestAdmin(id)
			.then((testAdmin) => {
				const currentMoment = moment();
				const currentYear = currentMoment.year();
				const currentMonth = currentMoment.month();

				let testPeriods = getSortedSubscriptionTestPeriods(
					testAdmin[0].testPeriods
				).map(addTitleToTestPeriods);

				const testCountTotal = testPeriods.reduce((sum, testPeriod) => {
					return sum + testPeriod.count;
				}, 0);
				const firstTestPeriod = testPeriods[0];
				const isLastPeriodCurrent =
					firstTestPeriod &&
					firstTestPeriod.year === currentYear &&
					firstTestPeriod.month === currentMonth;

				let currentTestPeriod;
				if (isLastPeriodCurrent) {
					currentTestPeriod = firstTestPeriod;
					testPeriods = testPeriods.slice(1);
				}
				testAdmin[0].userStage = getUserStage(testAdmin[0]);
				this.setState({
					currentTestPeriod,
					testAdmin: testAdmin[0],
					testCountTotal,
					testPeriods,
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}
	getAndSetLoginMethods(id) {
		return getLoginMethod(id).then((methods) => {
			this.setState({loginMethods: methods});
		});
	}
	retreiveTest(testId, key) {
		const {currentUser} = this.props;
		let id = this.props.match.params.id;
		if (!id) {
			id = currentUser.id;
		}
		return retrieveDeletedTests(testId, key).then(() => {
			this.getAndSetSingleTestAdmin(id);
		});
	}

	SaveLanguageSubscription(testAdminUpdates, apiCall) {
		const {currentUser} = this.props;
		let id = this.props.match.params.id;
		if (!id) {
			id = currentUser.id;
		}
		if (!testAdminUpdates) {
			this.setState({
				isEditingTestAdmin: null,
			});
			return;
		}
		apiCall(testAdminUpdates).then(() => {
			this.setState({editSubscription: false});
			this.getAndSetSingleTestAdmin(id);
		});
	}

	toggleShowPricingInfo() {
		this.setState({showPricingInfo: !this.state.showPricingInfo});
	}

	async setAndGetFeideConfig() {
		const allowedMunicipalities = await getFeideConfig();
		let canHaveFeide2 = false;

		if (allowedMunicipalities.length) {
			const {currentUser} = this.props;
			const adminMunicipality = currentUser.municipality;
			if (adminMunicipality) {
				canHaveFeide2 = allowedMunicipalities.some((config) => {
					if (config.uuid === adminMunicipality) {
						return true;
					}
				});
			}
		}

		this.setState({
			showSubscriptionTab: false,
			showInformationTab: true,
			showGroupTab: false,
			showSubscriptionActive: 'tablink1',
			showInformationActive: 'tablink2-active',
			showGroupActive: 'tablink3',
			animator: 'marker-information',
			canHaveFeide2,
		});
	}

	render() {
		const {
			currentTestPeriod,
			showPrevious,
			showDeleted,
			testPeriods,
			editSubscription,
			languageSubscription,
		} = this.state;
		const indexTestAdmin = this.state.testAdmin;
		const {
			currentUser,
			currentUser: {isSuperAdmin},
		} = this.props;
		const testAdmin = indexTestAdmin;
		if (!testAdmin) {
			return null;
		}

		const {userStage} = currentUser;
		const languages = testAdmin.subscription.languages;
		const deletedTests = testAdmin.deletedTests;
		const trialIsActive = isTrialActive(testAdmin);
		const isBasicUser = userStage === BASIC;
		const isTrialUser = userStage === TRIAL;
		return (
			<div className="lit-subscriptions">
				<nav>
					<div id={this.state.animator}></div>
					<a
						href="#"
						className={this.state.showSubscriptionActive}
						id="defaultOpen"
						onClick={() => {
							this.setState({
								showSubscriptionTab: true,
								showInformationTab: false,
								showGroupTab: false,
								showSubscriptionActive: 'tablink1-active',
								showInformationActive: 'tablink2',
								showGroupActive: 'tablink3',
								animator: 'marker-default',
							});
						}}
					>
						{strings.subscription}
					</a>
					<a
						href="#"
						className={this.state.showInformationActive}
						onClick={() => {
							this.setAndGetFeideConfig();
						}}
					>
						{strings.adminpersonalinformation}
					</a>
					<a
						href="#"
						className={this.state.showGroupActive}
						onClick={() => {
							this.setState({
								showSubscriptionTab: false,
								showInformationTab: false,
								showGroupTab: true,
								showSubscriptionActive: 'tablink1',
								showInformationActive: 'tablink2',
								showGroupActive: 'tablink3-active',
								animator: 'marker-groups',
							});
						}}
					>
						{strings.groups}
					</a>
				</nav>
				{this.state.showSubscriptionTab &&
					!this.state.showInformationTab &&
					!this.state.showGroupTab && (
						<div className="lit-subscription-main">
							<div className="tab-heading">
								<h3 className="lit-page-title">
									{isSuperAdmin
										? `${`${testAdmin.firstName} ${testAdmin.lastName}`} ${
												strings.supersubscription
										  }`
										: strings.mysubscription}
								</h3>
							</div>
							<div className="info">
								<UserStageIndicator big user={testAdmin} />
								{isSuperAdmin && currentTestPeriod && (
									<div
										className={'info__col lit-test-status'}
									>
										<h4 className="info__heading">
											{strings.teststhismonths}
										</h4>
										<p className="info__text large">
											{currentTestPeriod.count}
										</p>
										<div className="info__extra">
											<Link
												className="lit-btn lit-btn--small bg-secondary"
												to="/my-tests"
											>
												{strings.seetests}
											</Link>
										</div>
									</div>
								)}
								{isSuperAdmin && currentTestPeriod && (
									<div
										className={'info__col lit-test-status'}
									>
										<h4 className="info__heading">
											{strings.coststhismonth}
										</h4>
										<p className="info__text large">{`${
											SUBSCRIPTION_PRICE +
											currentTestPeriod.count * TEST_PRICE
										} ${CURRENCY_SYMBOL}`}</p>
									</div>
								)}
							</div>
							{(isTrialUser || isBasicUser) && (
								<div className="lit-new-subscriptions lit-new-subscriptions--subscription">
									<div className="info">
										{currentTestPeriod && (
											<div
												className={
													'info__col lit-test-status'
												}
											>
												<h4 className="info__heading">
													{strings.teststhismonths}
												</h4>
												<p className="info__text large">
													{currentTestPeriod.count}
												</p>
												<div className="info__extra">
													<Link
														className="lit-btn lit-btn--small bg-secondary"
														to="/my-tests"
													>
														{strings.seetests}
													</Link>
												</div>
											</div>
										)}
										{currentTestPeriod && (
											<div
												className={
													'info__col lit-test-status'
												}
											>
												<h4 className="info__heading">
													{strings.coststhismonth}
												</h4>
												<p className="info__text large">{`${
													SUBSCRIPTION_PRICE +
													currentTestPeriod.count *
														TEST_PRICE
												} ${CURRENCY_SYMBOL}`}</p>
											</div>
										)}
									</div>
									{isTrialUser && !trialIsActive && (
										<h3 className="lit-new-subscription__header">
											{strings.yourtrialperiodhasexpired}
										</h3>
									)}
									{(isBasicUser ||
										(isTrialUser && trialIsActive)) && (
										<h3 className="lit-new-subscription__header">
											{strings.upgradeyoursubscription}
										</h3>
									)}
									<p className="lit-new-subscription__description">
										{strings.downloadandsendcontract}
										<a
											href="mailto:admin@literate.no"
											target="_blank"
											rel="noopener noreferrer"
										>
											{`admin@literate.no`}
										</a>
										{strings.priceandconditioninfo}
										<a
											href="https://literate.no/pris-og-kjopsbetingelser/"
											target="_blank"
											rel="noopener noreferrer"
										>
											https://literate.no/pris-og-kjopsbetingelser/
										</a>
									</p>
									<a
										className="lit-btn bg-primary"
										download="Literate_KundeAvtale.pdf"
										href={contract}
									>
										{strings.downloadcontract}
									</a>
								</div>
							)}

							<div className="lit-button-grid">
								<div className="lit-button-header">
									{Boolean(deletedTests.length) && (
										<div className="lit-deletedTest-info">
											<button
												className={classNames({
													'lit-btn see-more': true,
													'bg-secondary': true,
													'bg-border': showDeleted,
												})}
												onClick={() => {
													this.setState({
														showDeleted: !showDeleted,
														showTable: false,
														showPrevious: false,
													});
												}}
											>
												{showDeleted ? (
													<span>
														{strings.hideWithArrow}
													</span>
												) : (
													<span>
														{strings.viewdeleted}
													</span>
												)}
											</button>
										</div>
									)}
									{Boolean(testPeriods.length) && (
										<div className="movetoleft">
											<button
												className={classNames({
													'lit-btn see-more': true,
													'bg-secondary': true,
													'bg-border': showPrevious,
												})}
												onClick={() => {
													this.setState({
														showPrevious: !showPrevious,
														showTable: false,
														showDeleted: false,
													});
												}}
											>
												{showPrevious ? (
													<span>
														{strings.hideWithArrow}
													</span>
												) : (
													<span>
														{
															strings.showearliermonths
														}
													</span>
												)}
											</button>
										</div>
									)}
									{languages.length && (
										<div className="lit_show_Subscripton">
											<button
												className={classNames({
													'lit-btn see-more': true,
													'bg-secondary': true,
													'bg-border': this.state
														.showTable,
												})}
												onClick={() => {
													this.setState({
														showTable: !this.state
															.showTable,
														showPrevious: false,
														showDeleted: false,
													});
												}}
											>
												{this.state.showTable ? (
													<span>
														{strings.hideWithArrow}
													</span>
												) : (
													<span>
														{
															strings.viewsubscription
														}
													</span>
												)}
											</button>
										</div>
									)}
								</div>
								<div className="lit_tables_container">
									{showPrevious && (
										<div className="previous-tests">
											<h3 className="previous-tests__heading">
												{strings.previousperiods}
											</h3>
											<table className="lit-list">
												<thead>
													<tr>
														<th>
															{strings.period}
														</th>
														<th>{strings.tests}</th>
														<th>{strings.costs}</th>
														{isSuperAdmin && (
															<th>&nbsp;</th>
														)}
													</tr>
												</thead>
												<tbody>
													{testPeriods.map(
														(testPeriod) => {
															const {
																title,
																count,
															} = testPeriod;

															return (
																<tr
																	className="lit-list__item"
																	key={title}
																>
																	<td className="lit-list__col">
																		<div className="lit-list__text">
																			{
																				title
																			}
																		</div>
																	</td>
																	<td className="lit-list__col">
																		<div className="lit-list__text large">
																			{
																				count
																			}
																		</div>
																	</td>

																	<td className="lit-list__col">
																		<div className="lit-list__text">{`${
																			SUBSCRIPTION_PRICE +
																			count *
																				TEST_PRICE
																		} ${CURRENCY_SYMBOL}`}</div>
																	</td>
																	<td className="lit-list__col">
																		<div className="lit-list__text">
																			<Link
																				className="lit-btn lit-btn--small bg-secondary"
																				to="/my-tests"
																			>
																				{
																					strings.seetests
																				}
																			</Link>
																		</div>
																	</td>
																</tr>
															);
														}
													)}
												</tbody>
											</table>
										</div>
									)}
									{showDeleted &&
										Boolean(deletedTests.length) && (
											<div className="lit_deleted-tests">
												<h3 className="deleted-tests__heading">
													{strings.deletedtests}
												</h3>
												<table className="lit-list">
													<thead>
														<tr>
															<th>
																{
																	strings.testkey
																}
															</th>
															<th>
																{strings.type}
															</th>
															<th>
																{
																	strings.deletedon
																}
															</th>
															{isSuperAdmin && (
																<th>&nbsp;</th>
															)}
														</tr>
													</thead>
													<tbody>
														{deletedTests.map(
															(deletedTest) => {
																const {
																	id,
																	testKey,
																	type,
																	deletedAt,
																} = deletedTest;
																const timeDeleted = moment(
																	deletedAt
																);

																return (
																	<tr
																		className="lit-list__item"
																		key={id}
																	>
																		<td className="lit-list__col">
																			<div className="lit-list__text">
																				{
																					testKey
																				}
																			</div>
																		</td>
																		<td className="lit-list__col">
																			<div className="lit-list__text">
																				{
																					type
																				}
																			</div>
																		</td>
																		<td className="lit-list__col">
																			<div className="lit-list__text">
																				{moment(
																					timeDeleted
																				).format(
																					'LL'
																				)}
																			</div>
																		</td>

																		{isSuperAdmin && (
																			<td className="lit-list__col">
																				<div className="lit-list__text">
																					<button
																						className="lit-btn lit-btn--small bg-secondary"
																						onClick={() => {
																							const key = {
																								testKey,
																								subsId:
																									testAdmin
																										.subscription
																										.id,
																								adminId:
																									testAdmin.id,
																							};
																							this.retreiveTest(
																								id,
																								key
																							);
																						}}
																					>
																						{
																							strings.fetch
																						}
																					</button>
																				</div>
																			</td>
																		)}
																	</tr>
																);
															}
														)}
													</tbody>
												</table>
											</div>
										)}
									{this.state.showTable && (
										/*  */ <SubscriptionTable
											isSuperAdmin={isSuperAdmin}
											languages={languages}
											onShowSubEditPanel={(language) => {
												this.setState({
													editSubscription: true,
													languageSubscription: language,
												});
											}}
										/>
									)}
								</div>
								{isSuperAdmin && editSubscription && (
									<EditSubscription
										subscription={languageSubscription}
										testAdmin={testAdmin.id}
										onUpdateLanguageSubscription={(
											testAdminUpdates
										) => {
											this.SaveLanguageSubscription(
												testAdminUpdates,
												editLanguageSubscription
											);
										}}
										closeWindow={() => {
											this.setState({
												editSubscription: false,
											});
										}}
									/>
								)}
							</div>
						</div>
					)}
				{!this.state.showSubscriptionTab &&
					this.state.showInformationTab &&
					!this.state.showGroupTab && (
						<div>
							<AdminInformation
								testAdmin={testAdmin}
								hasFeide2={this.state.hasFeide2}
								canHaveFeide2={this.state.canHaveFeide2}
								isSuperAdmin={isSuperAdmin}
								loginMethods={this.state.loginMethods}
								onSaveTestAdmin={(testAdminResult) => {
									this.saveTestAdmin(testAdminResult);
								}}
								onHandleUpdateTestAdminEvent={
									this.handleUpdateTestAdminEvent
								}
							/>
						</div>
					)}
				{!this.state.showSubscriptionTab &&
					!this.state.showInformationTab &&
					this.state.showGroupTab && (
						<div className="lit-subscription-main">
							<div className="tab-heading">
								<h3 className="lit-page-title">
									{isSuperAdmin
										? `${`${testAdmin.firstName} ${testAdmin.lastName}`} ${
												strings.referalgroups
										  }`
										: strings.mygroups}
								</h3>
							</div>
							<div className="lit-groups-main">
								{this.state.groupData.length === 0 && (
									<p>{strings.nogroupsfound}</p>
								)}
								{this.state.groupData.map((obj) => {
									if (!obj.groupObj.length) {
										return null;
									}
									const feideLabel =
										obj.isFeide === true ? (
											<span
												style={{
													marginLeft: '7px',
													color: '#0f4256',
													fontWeight: 'bold',
													fontSize: '12px',
													marginRight: '16px',
												}}
											>
												( Feide )
											</span>
										) : (
											''
										);
									return (
										<div className="Groups" key={obj.name}>
											<div className="lit_group-heading">
												<span
													style={{
														fontSize: '18px',
														marginBottom: '20px',
													}}
												>
													{obj.name}
												</span>
												{feideLabel}
											</div>
											<br></br>
											<table className="lit-mine-group-main-table">
												<thead>
													<tr className="lit-mine-group-table-header-row">
														<th className="lit-mine-group-table-header">
															{strings.firstname}
														</th>
														<th className="lit-mine-group-table-header">
															{strings.lastname}
														</th>
														<th className="lit-mine-group-table-header">
															{strings.email}
														</th>
														<th className="lit-mine-group-table-header">
															{
																strings.organization
															}
														</th>
														<th className="lit-mine-group-table-header">
															{strings.telephone}
														</th>
													</tr>
												</thead>
												<tbody>
													{obj.groupObj.map(
														(memberdata, index) => {
															return (
																<tr
																	key={index}
																	className="lit-list__item"
																>
																	<td>
																		{
																			memberdata.firstName
																		}
																	</td>
																	<td>
																		{
																			memberdata.lastName
																		}
																	</td>

																	<td>
																		{
																			memberdata.email
																		}
																	</td>
																	<td>
																		{
																			memberdata.organization
																		}
																	</td>
																	<td>
																		{
																			memberdata.phone
																		}
																	</td>
																</tr>
															);
														}
													)}
												</tbody>
											</table>
										</div>
									);
								})}
							</div>
						</div>
					)}

				<div></div>
			</div>
		);
	}
}

MySubscription.propTypes = {
	currentUser: PropTypes.shape({}),
};

const mapStateToProps = (state) => {
	const {currentUser} = state;

	return {
		currentUser,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MySubscription);
