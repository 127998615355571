import React from 'react';
import './styles.scss';
import strings from 'utils/strings';
import {updateObject} from 'utils/reducerUtils';
class EditSubscription extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			Status: null,
			isBasicChecked: false,
			languageSubscription: {...this.props.subscription},
		};
	}
	editSubscription(target, flag, languageStatus) {
		if (target.checked) {
			languageStatus[flag] = 1;
			languageStatus.basic = 0;

			this.setState({
				isBasicChecked: false,
				languageSubscription: languageStatus,
			});
		}

		if (!target.checked) {
			languageStatus[flag] = 0;
		}
	}
	assignBasicSubscription(target, flag, languageStatus) {
		if (target.checked) {
			const iterableField = [
				'adult',
				'adult_followup',
				'child',
				'norming_adult',
				'norming_child',
				'norming_youth',
				'youth',
				'child_followup',
				'youth_followup',
			];
			for (const field of iterableField) {
				languageStatus[field] = 0;
			}
			languageStatus[flag] = 1;
			this.setState({
				isBasicChecked: true,
			});
		} else {
			languageStatus[flag] = 0;
			this.setState({
				isBasicChecked: false,
			});
		}
	}

	render() {
		const usersId = this.props.testAdmin;

		return (
			<div
				className="lit-modal"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<h3>{this.state.languageSubscription.language}</h3>
					<div className="lit-input__field">
						<div className="checkout">
							<div className="checkout__expanded">
								<div className="lit_subscriptions-wrapper">
									<div className="main_production">
										<label className="lang_label">
											Production
										</label>

										<div className="one_main-options">
											<input
												className="user_checkbox"
												type="checkbox"
												disabled={
													this.state
														.languageSubscription
														.basic
												}
												defaultChecked={
													this.state
														.languageSubscription
														.child
												}
												id={
													this.state
														.languageSubscription.id
												}
												onChange={(event) => {
													this.editSubscription(
														event.target,
														'child',
														this.state
															.languageSubscription
													);
												}}
											/>
											<label className="lit-tests-subs_label">
												Child Tests
											</label>
										</div>

										<div className="one_main-options">
											<input
												className="user_checkbox"
												type="checkbox"
												defaultChecked={
													this.state
														.languageSubscription
														.youth
												}
												disabled={
													this.state
														.languageSubscription
														.basic
												}
												id={
													this.state
														.languageSubscription.id
												}
												onChange={(event) => {
													this.editSubscription(
														event.target,

														'youth',
														this.state
															.languageSubscription
													);
												}}
											/>
											<label className="lit-tests-subs_label">
												Youth Tests
											</label>
										</div>

										<div className="one_main-options">
											<input
												className="user_checkbox"
												type="checkbox"
												defaultChecked={
													this.state
														.languageSubscription
														.adult
												}
												disabled={
													this.state
														.languageSubscription
														.basic
												}
												id={
													this.state
														.languageSubscription.id
												}
												onChange={(event) => {
													this.editSubscription(
														event.target,

														'adult',
														this.state
															.languageSubscription
													);
												}}
											/>
											<label className="lit-tests-subs_label">
												Adult Tests
											</label>
										</div>

										<div className="one_main-options">
											<input
												className="user_checkbox"
												type="checkbox"
												defaultChecked={
													this.state
														.languageSubscription
														.child_followup
												}
												disabled={
													this.state
														.languageSubscription
														.basic
												}
												id={
													this.state
														.languageSubscription.id
												}
												onChange={(event) => {
													this.editSubscription(
														event.target,

														'child_followup',
														this.state
															.languageSubscription
													);
												}}
											/>
											<label className="lit-tests-subs_label">
												Child Followup
											</label>
										</div>

										<div className="one_main-options">
											<input
												className="user_checkbox"
												type="checkbox"
												defaultChecked={
													this.state
														.languageSubscription
														.youth_followup
												}
												disabled={
													this.state
														.languageSubscription
														.basic
												}
												id={
													this.state
														.languageSubscription.id
												}
												onChange={(event) => {
													this.editSubscription(
														event.target,

														'youth_followup',
														this.state
															.languageSubscription
													);
												}}
											/>
											<label className="lit-tests-subs_label">
												Youth Followup
											</label>
										</div>

										<div className="one_main-options">
											<input
												className="user_checkbox"
												type="checkbox"
												defaultChecked={
													this.state
														.languageSubscription
														.adult_followup
												}
												disabled={
													this.state
														.languageSubscription
														.basic
												}
												id={
													this.state
														.languageSubscription.id
												}
												onChange={(event) => {
													this.editSubscription(
														event.target,

														'adult_followup',
														this.state
															.languageSubscription
													);
												}}
											/>
											<label className="lit-tests-subs_label">
												Adult Followup
											</label>
										</div>
									</div>
									<div className="main_norming">
										<label className="lang_label">
											Norming
										</label>

										<div className="two-main_options">
											<input
												className="user_checkbox"
												type="checkbox"
												defaultChecked={
													this.state
														.languageSubscription
														.norming_child
												}
												disabled={
													this.state
														.languageSubscription
														.basic
												}
												id={
													this.state
														.languageSubscription.id
												}
												onChange={(event) => {
													this.editSubscription(
														event.target,

														'norming_child',
														this.state
															.languageSubscription
													);
												}}
											/>
											<label className="lit-tests-subs_label">
												Child Tests
											</label>
										</div>

										<div className="two-main_options">
											<input
												className="user_checkbox"
												type="checkbox"
												defaultChecked={
													this.state
														.languageSubscription
														.norming_youth
												}
												disabled={
													this.state
														.languageSubscription
														.basic
												}
												id={
													this.state
														.languageSubscription.id
												}
												onChange={(event) => {
													this.editSubscription(
														event.target,

														'norming_youth',
														this.state
															.languageSubscription
													);
												}}
											/>
											<label className="lit-tests-subs_label">
												Youth Tests
											</label>
										</div>

										<div className="two-main_options">
											<input
												className="user_checkbox"
												type="checkbox"
												defaultChecked={
													this.state
														.languageSubscription
														.norming_adult
												}
												disabled={
													this.state
														.languageSubscription
														.basic
												}
												id={
													this.state
														.languageSubscription.id
												}
												onChange={(event) => {
													this.editSubscription(
														event.target,

														'norming_adult',
														this.state
															.languageSubscription
													);
												}}
											/>
											<label className="lit-tests-subs_label">
												Adult Tests
											</label>
										</div>
									</div>
									<div className="basic_subscription">
										<div>
											<label className="lang_label">
												Other
											</label>
										</div>

										<div className="three-main_options">
											<input
												className="user_checkbox"
												type="checkbox"
												id={
													this.state
														.languageSubscription.id
												}
												defaultChecked={
													this.state
														.languageSubscription
														.basic
												}
												onChange={(event) => {
													this.assignBasicSubscription(
														event.target,

														'basic',
														this.state
															.languageSubscription
													);
												}}
											/>
											<label
												className="lit-tests-subs_label"
												htmlFor="lang_basic"
											>
												Basic
											</label>
										</div>
									</div>
								</div>
								{/* wrapper div */}
								<div className="lit_eidt-btnGrid" />
								<div className="lit_assign-permission">
									<button
										className="lit-btn assign-permission_bg-secondary"
										type="button"
										/* id={id} */
										onClick={() => {
											delete this.state
												.languageSubscription.id;
											const subscriptionUpdateObject =
												updateObject(
													{
														id: usersId,
													},
													this.state
														.languageSubscription
												);

											this.props.onUpdateLanguageSubscription(
												subscriptionUpdateObject
											);
										}}
									>
										{strings.save}
									</button>
								</div>
								<div className="lit_delete-permission">
									<button
										className="lit-btn delete-permission_bg-secondary"
										type="button"
										/* id={id} */
										onClick={() => {
											this.setState({
												languageSubscription:
													this.props.subscription,
											});
											this.props.closeWindow();
										}}
									>
										{strings.cancel}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default EditSubscription;
