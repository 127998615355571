/* eslint-disable react/jsx-key */
/* eslint-disable complexity */
/* eslint-disable no-case-declarations */
/* eslint-disable default-case */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import '../../TestView/styles.scss';

import getSingleSpeechTestResult from 'api/getSingleSpeechTestResult';
import updateLogEvent from 'api/updateLogEvent';

import updateTestCandidate from 'api/updateTestCandidate';
import getAudioFile from 'api/getAudioFile';
import updateTestScores from 'api/updateTestScores';
import strings from 'utils/strings';
import create from 'assets/create-24px.svg';

import Subtest from './Subtest';
import SubtestReport from '../SingleScreeningTest/SubtestReport';

import HorizontalBarChart from 'components/HorizontalBarChart';

import {withRouter} from 'react-router-dom';
import TestUserInfo from './TestUserInfo';
import EvaluateAnswers from './EvaluateAnswers';
import {no} from './assets';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPrint} from '@fortawesome/free-solid-svg-icons';

const TESTS_REQUIRED_TO_PASS = 1;

class SingleSpeechTest extends React.Component {
	constructor() {
		super();

		this.state = {
			showTestUserInfo: false,
			viewModes: {},
			testResult: null,
			testId: null,
			testType: null,
			showResultsTab: true,
			showReportTab: false,
			showInformationTab: false,
			showResultsActive: 'tablink1-active',
			showReportActive: 'tablink2',
			showInformationActive: 'tablink3',
			audioPlayOptions: [],
			enableAudioPlay: false,
			blobData: null,
			showEvaluteAnswerPanel: false,
			currentAnswer: null,
			currentIndex: null,
			buttonTabs: [
				{
					id: 'defaultOpen',
					value: 'result',
					buttonClass: 'tablink1-active',
					text: 'Resultater',
				},
				{
					id: 2,
					value: 'report',
					buttonClass: 'tablink2',
					text: 'Rapport',
				},
				{
					id: 3,
					value: 'info',
					buttonClass: 'tablink3',
					text: strings.userinformation,
				},
			],
		};
		this.returnAudioList = this.returnAudioList.bind(this);
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		this.setState({
			testId: id,
		});
		window.scrollTo(0, 0);
		getSingleSpeechTestResult(id).then((testResult) => {
			testResult.subtests.map((subtest) => {
				this.setViewMode(subtest.id, 'SHOW_ANSWERED');

				return subtest;
			});

			this.setState({
				testResult,
				testType: testResult.type,
			});
		});
	}

	getAndSetSpeechAudio(audioFlag) {
		let audioKey;
		let index;
		switch (audioFlag) {
			case 'Ordene 1-10':
				audioKey =
					this.state.testResult.key +
					'_' +
					this.state.testResult.testUserId +
					'_spmt_0';
				index = 'index0';
				break;
			case 'Ordene 11-20':
				audioKey =
					this.state.testResult.key +
					'_' +
					this.state.testResult.testUserId +
					'_spmt_1';
				index = 'index1';
				break;
			case 'Ordene 21-30':
				audioKey =
					this.state.testResult.key +
					'_' +
					this.state.testResult.testUserId +
					'_spmt_2';
				index = 'index2';
				break;
			case 'Ordene 31-40':
				audioKey =
					this.state.testResult.key +
					'_' +
					this.state.testResult.testUserId +
					'_spmt_3';
				index = 'index3';
				break;
			case 'Ordene 41-50':
				audioKey =
					this.state.testResult.key +
					'_' +
					this.state.testResult.testUserId +
					'_spmt_4';
				index = 'index4';
				break;
			case 'Ordene 51-60':
				audioKey =
					this.state.testResult.key +
					'_' +
					this.state.testResult.testUserId +
					'_spmt_5';
				index = 'index5';
				break;
			case 'Ordene 61-70':
				audioKey =
					this.state.testResult.key +
					'_' +
					this.state.testResult.testUserId +
					'_spmt_6';
				index = 'index6';
				break;
			case 'Ordene 71-80':
				audioKey =
					this.state.testResult.key +
					'_' +
					this.state.testResult.testUserId +
					'_spmt_7';
				index = 'index7';
				break;
			case 'Ordene 81-90':
				audioKey =
					this.state.testResult.key +
					'_' +
					this.state.testResult.testUserId +
					'_spmt_8';
				index = 'index8';
				break;
			case 'Ordene 91-100':
				audioKey =
					this.state.testResult.key +
					'_' +
					this.state.testResult.testUserId +
					'_spmt_9';
				index = 'index9';
				break;
			case 'Ordene 101-110':
				audioKey =
					this.state.testResult.key +
					'_' +
					this.state.testResult.testUserId +
					'_spmt_10';
				index = 'index10';
				break;
			case 'Ordene 111-120':
				audioKey =
					this.state.testResult.key +
					'_' +
					this.state.testResult.testUserId +
					'_spmt_11';
				index = 'index11';
				break;
		}

		getAudioFile(audioKey).then((res) => {
			if (res === 'operation Failed') {
				alert(strings.cantretrieveaudio);
			} else {
				const blob = new Blob([new Uint8Array(res.Body.data)], {
					type: 'audio/webm',
				});

				this.setState({
					enableAudioPlay: index,
					blobData: blob,
				});
			}
		});
	}

	getQuestionChoices(question) {
		const output = {};
		for (const choice of question.choices) {
			output[choice.letter] = choice;
		}
		return output;
	}
	returnAudioList() {
		const totalAnswers = this.state.testResult.subtests[0].answers;
		var wordRange;
		const audioArray = [];
		for (let i = 0; i < 121; i++) {
			switch (i) {
				case 0:
					if (totalAnswers['0'] && totalAnswers['0']['isAnswered']) {
						wordRange = 'Ordene 1-10';
						audioArray.push({wordRange});
					}

					break;
				case 10:
					if (
						totalAnswers['10'] &&
						totalAnswers['10']['isAnswered']
					) {
						wordRange = 'Ordene 11-20';
						audioArray.push({wordRange});
					}

					break;
				case 20:
					if (
						totalAnswers['20'] &&
						totalAnswers['20']['isAnswered']
					) {
						wordRange = 'Ordene 21-30';
						audioArray.push({wordRange});
					}

					break;
				case 30:
					if (
						totalAnswers['30'] &&
						totalAnswers['30']['isAnswered']
					) {
						wordRange = 'Ordene 31-40';
						audioArray.push({wordRange});
					}

					break;
				case 40:
					if (
						totalAnswers['40'] &&
						totalAnswers['40']['isAnswered']
					) {
						wordRange = 'Ordene 41-50';
						audioArray.push({wordRange});
					}

					break;
				case 50:
					if (
						totalAnswers['50'] &&
						totalAnswers['50']['isAnswered']
					) {
						wordRange = 'Ordene 51-60';
						audioArray.push({wordRange});
					}

					break;
				case 60:
					if (
						totalAnswers['60'] &&
						totalAnswers['60']['isAnswered']
					) {
						wordRange = 'Ordene 61-70';
						audioArray.push({wordRange});
					}

					break;
				case 70:
					if (
						totalAnswers['70'] &&
						totalAnswers['70']['isAnswered']
					) {
						wordRange = 'Ordene 71-80';
						audioArray.push({wordRange});
					}

					break;
				case 80:
					if (
						totalAnswers['80'] &&
						totalAnswers['80']['isAnswered']
					) {
						wordRange = 'Ordene 81-90';
						audioArray.push({wordRange});
					}

					break;
				case 90:
					if (
						totalAnswers['90'] &&
						totalAnswers['90']['isAnswered']
					) {
						wordRange = 'Ordene 91-100';
						audioArray.push({wordRange});
					}

					break;
				case 100:
					if (
						totalAnswers['100'] &&
						totalAnswers['100']['isAnswered']
					) {
						wordRange = 'Ordene 101-110';
						audioArray.push({wordRange});
					}

					break;
				case 110:
					if (
						totalAnswers['110'] &&
						totalAnswers['110']['isAnswered']
					) {
						wordRange = 'Ordene 111-120';
						audioArray.push({wordRange});
					}

					break;
			}
		}

		this.setState({
			audioPlayOptions: audioArray,
			showAudioContainer: true,
		});
	}
	enableAnswerPanel(answer, index) {
		var key;
		var range;
		if (index % 10 !== 0) {
			key = index > 10 ? ~~(index / 10) : 0;
			range = key;
		} else {
			key = ~~(index / 10);

			range = key;
		}

		const audioKey =
			this.state.testResult.key +
			'_' +
			this.state.testResult.testUserId +
			'_spmt_' +
			range;

		getAudioFile(audioKey).then((res) => {
			if (res === 'operation Failed') {
				alert(strings.cantretrieveaudio);
			} else {
				const blob = new Blob([new Uint8Array(res.Body.data)], {
					type: 'audio/webm',
				});

				this.setState({
					blobData: blob,
					showEvaluteAnswerPanel: true,
					currentAnswer: answer,
					currentIndex: index,
				});
			}
		});
	}
	reCalculateResult(allAnswers) {
		const grade = this.state.testResult.testUser.class;
		const testKey = this.state.testResult.key;
		const answers = allAnswers;
		updateTestScores(testKey, grade, answers).then((res) => {
			if (res === 'ok') {
				const id = this.props.match.params.id;
				this.setState({
					testId: id,
				});
				window.scrollTo(0, 0);
				getSingleSpeechTestResult(id).then((testResult) => {
					testResult.subtests.map((subtest) => {
						this.setViewMode(subtest.id, 'SHOW_ANSWERED');

						return subtest;
					});

					this.setState({
						testResult,
						testType: testResult.type,
						showEvaluteAnswerPanel: false,
					});
				});
			}
		});
	}
	updateTestCandidateInfo(obj) {
		updateTestCandidate(obj).then((res) => {
			if (res === 'ok') {
				alert('Kandidatinfo oppdatert');
				getSingleSpeechTestResult(this.state.testId).then(
					(testResult) => {
						testResult.subtests.map((subtest) => {
							this.setViewMode(subtest.id, 'SHOW_ANSWERED');

							return subtest;
						});

						this.setState({
							testResult,
							testType: testResult.type,
							showResultsTab: false,
							showReportTab: true,
							showInformationTab: false,
							showTestUserInfo: true,
							showResultsActive: 'tablink1',
							showReportActive: 'tablink2',
							showInformationActive: 'tablink3-active',
							showAudioContainer: false,
						});
					}
				);
			}
		});
	}
	updatePrintLog(action) {
		updateLogEvent('print', this.state.testId, '', action);
	}
	//more robust log
	getAnswersWithCorrectAnswer(answers, answersCorrect, questions = false) {
		const output = [];
		for (let i = 0; i < answers.length; i++) {
			let jInit = 0;
			//Saves us some looping if arrays are arranged as expected
			if (
				answers[i] &&
				answersCorrect[i] &&
				answers[i].id === answersCorrect[i].id
			) {
				jInit = i;
			}
			if (typeof answers[i].id === 'undefined') {
				break;
			}
			const currentAnswer = answers[i];
			const currentAnswerValue = currentAnswer.value.toLowerCase().trim();
			for (let j = jInit; j < answersCorrect.length; j++) {
				const currentCorrectAnswer = answersCorrect[j];
				if (currentAnswer.id === currentCorrectAnswer.id) {
					const correctAnswerValue = currentCorrectAnswer.value
						.toLowerCase()
						.trim();
					const isCorrectAnswer =
						currentAnswerValue === currentCorrectAnswer.value;

					const hasAltValues = Array.isArray(
						currentCorrectAnswer.altValues
					);
					const isCorrectAltValue =
						hasAltValues &&
						currentCorrectAnswer.altValues.indexOf(
							currentAnswerValue
						) !== -1;

					const answer = {
						id: currentAnswer.id,
						value: currentAnswerValue,
						isCorrect: isCorrectAnswer || isCorrectAltValue,
						correctValues: [correctAnswerValue],
					};

					if (hasAltValues) {
						answer.correctValues = [correctAnswerValue].concat(
							currentCorrectAnswer.altValues
						);
					}

					if (questions) {
						answer.question =
							questions[currentAnswer.id - 1].question;
						answer.choices = this.getQuestionChoices(
							questions[currentAnswer.id - 1]
						);
					}

					output.push(answer);
					break;
				}
			}
		}
		return output;
	}

	renderSubtestType0(key, answer, index, answers) {
		const getCorrectClass = (isCorrect) => {
			return `sub-test__answer--${isCorrect ? 'correct' : 'wrong'}`;
		};

		const correctClass = getCorrectClass(answer.isCorrect);
		return (
			<div className={`sub-test__answer`} key={index}>
				<p>
					{key + '. '}
					<span className={correctClass}>{answers[index]}</span>
				</p>
			</div>
		);
	}
	renderSubtestType1(key, answer, index, answers) {
		const getCorrectClass = (isCorrect) => {
			return `sub-test__answer--${isCorrect ? 'correct' : 'wrong'}`;
		};

		const correctClass = getCorrectClass(answer.isCorrect);
		return (
			<div className={`sub-test__answer`} key={index}>
				<p>
					{key + '. '}
					<span className={correctClass}>{answers[index]}</span>
					{correctClass === 'sub-test__answer--wrong' && (
						<span
							className="subtest-answer-edit"
							onClick={() => {
								this.enableAnswerPanel(answers[index], index);
							}}
						>
							<img className="edit-score" src={create}></img>
						</span>
					)}
				</p>
			</div>
		);
	}
	renderSubtestType2(key, answer, index, answers) {
		const getCorrectClass = (isCorrect) => {
			return `sub-test__answer--${isCorrect ? 'correct' : 'wrong'}`;
		};
		const correctClass = getCorrectClass(
			answer.option === answers[index].correct
		);
		return (
			<div className={`sub-test__answer`} key={index}>
				<p>
					{key + '. '}
					<span className={correctClass}>
						{answers[index].options[answer.option]}
					</span>
				</p>
			</div>
		);
	}

	renderSubtestType3(key, answer, index, answers) {
		//orddiktat
		const getCorrectClass = (isCorrect) => {
			return `sub-test__answer--${isCorrect ? 'correct' : 'wrong'}`;
		};

		const correctClass = getCorrectClass(
			answer.toLowerCase().trim() === answers[index].toLowerCase().trim()
		);
		return (
			<div className={`sub-test__answer`} key={index}>
				<p>
					{key - 1 + '. '}
					<span className={correctClass}>{answer}</span>
				</p>
			</div>
		);
	}

	renderSubtest(subtest) {
		return (
			<Subtest
				subtest={subtest}
				type="child"
				testType={this.state.testType} //is going to be used for better rendering of gjennomsnittig og stadaravvik skåre
				testResult={this.state.testResult} //is going to be used for rendering norms based on grade--agegroup
			>
				{this.renderAnswers(subtest)}
			</Subtest>
		);
	}
	renderSubtestReport(subtest) {
		return (
			<SubtestReport
				subtest={subtest}
				type="child"
				testResult={this.state.testResult}
			></SubtestReport>
		);
	}

	toggleShowUnanswered(id) {
		if (this.state.viewModes[id] === 'SHOW_ALL') {
			this.setViewMode(id, 'SHOW_ANSWERED');
		} else {
			this.setViewMode(id, 'SHOW_ALL');
		}
	}

	setViewMode(id, value) {
		const obj = {};
		obj[id] = value;
		this.setState({
			viewModes: Object.assign({}, this.state.viewModes, obj),
		});
	}
	returnTestNameForId(id) {
		switch (id) {
			case 0:
				return strings.workingmemorytest;
			case 3:
				return strings.rapidautonamingtest;
			case 4:
				return strings.oneminutetest;
			case 5:
				return strings.spoonerismtest;
		}
	}
	renderAnswers(subtest) {
		const {answers} = subtest;
		const notAnswered = 0;
		let answersClass = '';
		const {viewModes} = this.state;
		let isVisible = true;
		switch (viewModes[subtest.id]) {
			case 'SHOW_ALL':
				answersClass = 'sub-test__answers--show-not-answered';
				break;
			case 'SHOW_ANSWERED':
				answersClass = '';
				break;
			case 'HIDE_ALL':
			default: {
				isVisible = false;
			}
		}

		switch (subtest.id) {
			case 0:
			case 1:
			case 2: {
				answersClass += ' col-2';
				break;
			}
			case 3:
			case 4:
			case 5:
			case 6:
			case 7:
			case 8: {
				answersClass += ' col-4';
				break;
			}
			case 9: {
				answersClass += ' col-3';
				break;
			}
			default:
				break;
		}

		const languageAssets = no;

		const newAnswers = Object.entries(answers).map(
			([num, answer], index) => {
				const key = Number(num) + 1;
				switch (subtest.id) {
					case 0: {
						const correctAnswers = no(subtest.language).test0;
						return Object.entries(answer).map(
							([num2, answer2], index2) => {
								const key2 = Number(num2) + 1;
								return this.renderSubtestType0(
									key2,
									answer2,
									index2,
									num === 'numbers'
										? correctAnswers.numbers
										: correctAnswers.words
								);
							}
						);
					}
					case 3: {
						return this.renderSubtestType1(
							key,
							answer,
							index,
							languageAssets.test3
						);
					}
					case 12: {
						return this.renderSubtestType1(
							key,
							answer,
							index,
							languageAssets.test4
						);
					}
					case 5: {
						return this.renderSubtestType1(
							key,
							answer,
							index,
							languageAssets.test5
						);
					}
					case 6: {
						return this.renderSubtestType1(
							key,
							answer,
							index,
							languageAssets.test6
						);
					}
					case 7: {
						return this.renderSubtestType2(
							key,
							answer,
							index,
							languageAssets.test7
						);
					}
					case 8: {
						return this.renderSubtestType1(
							key,
							answer,
							index,
							languageAssets.test8
						);
					}
					case 9: {
						return this.renderSubtestType3(
							key,
							answer,
							index,
							languageAssets.test9
						);
					}
					default:
						return '';
				}
			}
		);

		return (
			isVisible &&
			newAnswers.length && (
				<div>
					<h4 className="sub-test__answers__heading">
						{strings.candidate_answers}
					</h4>
					<div className={`sub-test__answers ${answersClass}`}>
						{newAnswers}
					</div>
					{notAnswered > 0 && (
						<p className="sub-test__not-answered">
							<b>{`${notAnswered} oppgaver ikke besvart`}</b>{' '}
							<button
								className="sub-test__link print-hide"
								onClick={(e) => {
									e.preventDefault();
									this.toggleShowUnanswered(subtest.id);
								}}
							>
								{viewModes[subtest.id] === 'SHOW_ALL'
									? 'Skjul ubesvarte'
									: 'Vis ubesvarte'}
							</button>
						</p>
					)}
					<div>
						<p className="sub-test__not-answered">
							<button
								className={classNames('print-hide', {
									'lit-btn2 see-more': true,
									'bg-tertiary': true,
								})}
								onClick={(e) => {
									this.returnAudioList();
								}}
							>
								{viewModes[subtest.id] === 'SHOW_ALL'
									? 'Skjul Audio'
									: 'Lytt til ordene'}
							</button>
						</p>
						<div className="lit-audio-container">
							{this.state.showAudioContainer && (
								<div className="lit-audio-items">
									{this.state.audioPlayOptions.map(
										(option, index) => {
											return (
												<div className="lit-audio-files">
													<div
														className={
															this.state
																.enableAudioPlay ===
															'index' + index
																? 'lit-audio-single-grid-active'
																: 'lit-audio-single-grid'
														}
													>
														<button
															className={
																'lit-audio-list'
															}
															key={
																'index' + index
															}
															value={
																option.wordRange
															}
															onClick={(e) => {
																this.getAndSetSpeechAudio(
																	e.target
																		.value
																);
															}}
														>
															{option.wordRange}{' '}
														</button>
														{this.state
															.enableAudioPlay ===
															'index' + index && (
															<div
																className="lit-audio-label"
																key={
																	'index' +
																	index
																}
															>
																<audio
																	key={
																		'index' +
																		index
																	}
																	controls
																>
																	<source
																		src={URL.createObjectURL(
																			this
																				.state
																				.blobData
																		)}
																		type="audio/webm"
																	/>
																</audio>
															</div>
														)}
													</div>

													{/* <div
														className="audio"
														id="audio"
													></div> */}
												</div>
											);
										}
									)}
								</div>
							)}
							{this.state.showEvaluteAnswerPanel && (
								<EvaluateAnswers
									wrongAnswer={this.state.currentAnswer}
									blobData={this.state.blobData}
									currentIndex={this.state.currentIndex}
									allAnswers={
										this.state.testResult.subtests[0]
											.answers
									}
									closeWindow={() => {
										this.setState({
											showEvaluteAnswerPanel: false,
										});
									}}
									reEvaluateSpeechTest={(allAnswers) => {
										this.reCalculateResult(allAnswers);
									}}
								/>
							)}
						</div>
					</div>
				</div>
			)
		);
	}
	returnRelevantZscore(subTestsArray, testId) {
		for (let i = 0; i < subTestsArray.length; i++) {
			if (subTestsArray[i].id === testId) {
				return subTestsArray[i].zScore;
			}
		}
	}
	setRelevantTab(key, index) {
		const {buttonTabs} = this.state;
		for (let i = 0; i < buttonTabs.length; i++) {
			if (
				i === index &&
				!buttonTabs[index].buttonClass.includes('active')
			) {
				const counter = index + 1;
				buttonTabs[index].buttonClass = `tablink${counter}-active`;
			} else {
				const counter = i + 1;
				buttonTabs[i].buttonClass = `tablink${counter}`;
			}
		}
		switch (key) {
			case 'result':
				this.setState({
					showResultsTab: true,
					showReportTab: false,
					showInformationTab: false,
					showTestUserInfo: false,

					buttonTabs,
				});
				break;
			case 'report':
				this.setState({
					showResultsTab: false,
					showReportTab: true,
					showInformationTab: true,
					showTestUserInfo: false,
					buttonTabs,
				});
				break;
			case 'info':
				this.setState({
					showResultsTab: false,
					showReportTab: true,
					showInformationTab: false,
					showTestUserInfo: true,

					buttonTabs,
				});
				break;
		}
	}
	render() {
		const {showTestUserInfo, testResult} = this.state;
		if (!testResult) {
			return null;
		}

		const {testUser} = testResult;
		const subtestsToBeRendered = testResult.subtests.filter((subtest) => {
			const hasZero =
				testResult.subtests.filter((subtest2) => subtest2.id === 0)
					.length > 0;
			if ([1, 2].includes(subtest.id)) {
				return !hasZero;
			}
			return true;
		});
		const subtestsLength = subtestsToBeRendered.length;
		let zScoreLowCount = 0;
		let lowScoredSubTests = [];
		subtestsToBeRendered.forEach((subtest) => {
			if (subtest.zScore <= -1) {
				lowScoredSubTests.push(this.returnTestNameForId(subtest.id));
				zScoreLowCount++;
			}
		});
		let completeName = '<skjult navn>';
		if (testUser.firstName) {
			completeName = `${testUser.firstName} ${testUser.lastName}`;
		}

		let writeUpText = [];
		if (
			zScoreLowCount >= TESTS_REQUIRED_TO_PASS &&
			this.state.testType === 'child-experimental'
		) {
			writeUpText.push(
				strings.formatString(
					strings.assessmentspeechnotok,
					`${completeName}`
				)
			);
		} else if (
			zScoreLowCount < TESTS_REQUIRED_TO_PASS &&
			this.state.testType === 'child-experimental'
		) {
			writeUpText.push(
				strings.formatString(
					strings.assessmentspeechok,
					`${completeName}`
				)
			);
		}

		return (
			<div className="lit-single-test">
				<div>
					<div
						className={
							this.state.testType === 'child'
								? 'lit-single-youthtest-title-desc'
								: 'lit-single-adulttest-title-desc'
						}
					>
						<h1 className="lit-page-title">
							{this.state.showResultsTab
								? strings.formatString(
										strings.dynamicheaderfortestresult_child,
										this.state.testType ===
											'child-experimental' ? (
											<>
												<br />
												<br />
												<b>
													{
														strings.experimentalchildtest
													}
												</b>
											</>
										) : (
											<>
												<br />
												<br />
												<b>
													{strings.youthfollowuptest}
												</b>
											</>
										)
								  )
								: this.state.showTestUserInfo
								? strings.formatString(
										strings.dynamicheaderfortestcandidate_child,
										this.state.testType ===
											'child-experimental' ? (
											<>
												<br />
												<br />
												<b>
													{
														strings.experimentalchildtest
													}
												</b>
											</>
										) : (
											<b>{strings.youthfollowuptest}</b>
										)
								  )
								: this.state.showReportTab
								? strings.formatString(
										strings.dynamicheaderfortestreport_child,
										this.state.testType ===
											'child-experimental' ? (
											<>
												<br />
												<br />
												<b>
													{
														strings.experimentalchildtest
													}
												</b>
											</>
										) : (
											<b>{strings.youthfollowuptest}</b>
										)
								  )
								: ''}
						</h1>

						<p className="lit-page-lead">
							{strings.formatString(
								this.state.showResultsTab
									? strings.info_results_belowpresentsinformation
									: this.state.showTestUserInfo
									? strings.info_user_belowpresentsinformation
									: this.state.showReportTab
									? strings.info_report_belowpresentsinformation
									: '',

								<>
									<br />
									<br />
									<b>{completeName}</b>
									<br />{' '}
								</>,
								<>
									<br />
									<br />
									<b>
										{testResult.info
											? testResult.info.age
											: 'null'}
									</b>
								</>,

								<b>{0}</b>
							)}
							{testResult.completedAt ? (
								strings.formatString(
									strings.info_testwascompletedon,
									<>
										<b>
											{moment(
												testResult.completedAt
											).format('DD.MM.YYYY')}
										</b>
										<br />
									</>
								)
							) : (
								<>
									{strings.info_testwasNotcompletedon} <br />
								</>
							)}
						</p>
					</div>

					<div className="lit-tab-wrapper">
						<div className="lit-results-line" />
						{this.state.buttonTabs.map((tab, index) => {
							const id = tab.id;
							const value = tab.value;
							const buttonClass = tab.buttonClass;
							const btnText = tab.text;

							// eslint-disable-next-line react/jsx-key
							return (
								<button
									className={buttonClass}
									id={id}
									key={index}
									value={value}
									disabled={buttonClass.includes('active')}
									onClick={(e) => {
										this.setRelevantTab(
											e.target.value,
											index
										);
									}}
								>
									{btnText}
								</button>
							);
						})}
					</div>

					{this.state.showResultsTab &&
						!this.state.showReportTab &&
						!this.state.showInformationTab && (
							<div className="lit-histogram-Test_Details">
								<div className="lit-printBtn">
									<button
										className={classNames('print-hide', {
											'lit-btn2 see-more': true,
											'bg-tertiary': true,
										})}
										onClick={() => {
											window.print();
											this.updatePrintLog('Results');
										}}
									>
										<FontAwesomeIcon
											size="lg"
											className="lit-icon-btns"
											icon={faPrint}
										/>

										<p className="lit-btns-txt">
											{strings.printtestresults}
										</p>
									</button>
								</div>
								<div className="lit-results-background">
									<div className="sub-test total print-summary">
										{!testResult.completedAt && (
											<div className="incomplete-notice">
												{strings.incompleteMessage}
											</div>
										)}
										<h3 className="sub-test__name big">
											{strings.summary}
										</h3>
										<div className="summary-chart">
											<HorizontalBarChart
												isChild
												subtests={subtestsToBeRendered}
											/>
										</div>
										<div className="explanations">
											<div className="explanation">
												<div className="explanation__figure negative" />
												<p className="explanation__text">
													{strings.formatString(
														strings.zscorered,
														<em>{strings.under}</em>
													)}
												</p>
											</div>
											<div className="explanation">
												<div className="explanation__figure neutral" />
												<p className="explanation__text">
													{strings.zscoreblue}
												</p>
											</div>
											<div className="explanation">
												<div className="explanation__figure positive" />
												<p className="explanation__text">
													{strings.formatString(
														strings.zscoregreen,
														<em>{strings.over}</em>
													)}
												</p>
											</div>
										</div>

										<div className="sub-test__scores">
											<div className="width--half center">
												<h4 className="sub-test__heading big">
													{strings.assessment}
												</h4>

												{writeUpText.map((text) => {
													return <p>{text}</p>;
												})}
											</div>
										</div>
									</div>
									<div className="print-sub-tests">
										{subtestsToBeRendered.map((subtest) => {
											return this.renderSubtest(subtest);
										})}
									</div>
								</div>
							</div>
						)}
					{showTestUserInfo && (
						<TestUserInfo
							testResult={testResult}
							testId={this.state.testId}
							testResult={this.state.testResult}
							onUpdateTestCandidateInfo={(newCandidateInfo) => {
								this.updateTestCandidateInfo(newCandidateInfo);
							}}
						/>
					)}
					{this.state.showReportTab && !this.state.showTestUserInfo && (
						<div className="lit-histogram-Test_Report">
							<div className="lit-printBtn">
								<button
									className={classNames('print-hide', {
										'lit-btn2 see-more': true,
										'bg-tertiary': true,
									})}
									onClick={() => {
										window.print();
										this.updatePrintLog('Report');
									}}
								>
									<FontAwesomeIcon
										size="lg"
										className="lit-icon-btns"
										icon={faPrint}
									/>

									<p className="lit-btns-txt">
										{strings.printreport}
									</p>
								</button>
							</div>
							<div className="lit-results-background">
								<div className="sub-test total print-summary">
									{!testResult.completedAt && (
										<div className="incomplete-notice">
											{strings.incompleteMessage}
										</div>
									)}
									<h3 className="sub-test__name big">
										{strings.summary}
									</h3>
									<div className="summary-chart">
										<HorizontalBarChart
											isChild
											subtests={subtestsToBeRendered}
										/>
									</div>
									<div className="explanations">
										<div className="explanation">
											<div className="explanation__figure negative" />
											<p className="explanation__text">
												{strings.formatString(
													strings.zscorered,
													<em>{strings.under}</em>
												)}
											</p>
										</div>
										<div className="explanation">
											<div className="explanation__figure neutral" />
											<p className="explanation__text">
												{strings.zscoreblue}
											</p>
										</div>
										<div className="explanation">
											<div className="explanation__figure positive" />
											<p className="explanation__text">
												{strings.formatString(
													strings.zscoregreen,
													<em>{strings.over}</em>
												)}
											</p>
										</div>
									</div>
									<div>
										{this.state.testType ===
											'youth-followup' && (
											<div className="sub-test__scores">
												<div className="width--half center">
													<h4 className="sub-test__heading big">
														{strings.assessment}
													</h4>

													{writeUpText.map((text) => {
														return <p>{text}</p>;
													})}
												</div>
											</div>
										)}
									</div>
									<br />
									<div className="print-sub-tests-report">
										{subtestsToBeRendered.map((subtest) => {
											return this.renderSubtestReport(
												subtest
											);
										})}
									</div>
									<div className="sub-test__comment">
										<h4 className="sub-test__heading big">
											<span className="comment">
												Kommentarer:
											</span>
											<div className="line line-1" />
											<div className="line line-2" />
											<div className="line line-3" />
											<div className="line line-4" />
										</h4>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

SingleSpeechTest.propTypes = {
	currentUser: PropTypes.shape({}),
};

export default withRouter(SingleSpeechTest);
