import React from 'react';
import './styles.scss';

export const EnvironmentBanner = (props) => {
	return (
		<div className={`environment-banner ${props.environment}`}>
			NOTE: THIS IS A {props.environment.toUpperCase()} ENVIRONMENT
		</div>
	);
};
export default EnvironmentBanner;
