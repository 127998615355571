import {ENDPOINT_URL} from 'constants/index';

import {updateObject} from 'utils/reducerUtils';
import getFetchOptions from 'utils/getFetchOptions';
import {apiErrorHandling} from 'utils/apiUtils';

export default function editTestAdminSubscription(subscriptionUpdates) {
	const updatesObject = updateObject(
		{adminUserId: subscriptionUpdates.id},
		subscriptionUpdates
	);

	delete updatesObject.id;

	return fetch(
		`${ENDPOINT_URL}/subscription`,
		getFetchOptions({
			method: 'PATCH',
			body: JSON.stringify(updatesObject),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
