import {ENDPOINT_URL} from 'constants/index';
import {apiErrorHandling} from 'utils/apiUtils';

import getFetchOptions from 'utils/getFetchOptions';

export default function deleteClassMembers(testUsers, targetClass, subsId) {
	return fetch(
		`${ENDPOINT_URL}/adminclass/deletemember`,
		getFetchOptions({
			method: 'DELETE',
			body: JSON.stringify({
				testUsers,
				targetClass,
				subsId,
			}),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
