import {ENDPOINT_URL} from 'constants/index';
import {apiErrorHandling} from 'utils/apiUtils';

import getFetchOptions from 'utils/getFetchOptions';

//removing sub_id from api func.
export default function createAndDistributeTests(
	type,
	nKeys,
	groupId,
	language,
	anamnese,
	childSelfStarting,
	candidateEmails
) {
	return fetch(
		`${ENDPOINT_URL}/test/createandditribute`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify({
				type,
				nKeys,
				groupId,
				language,
				anamnese,
				childSelfStarting,
				candidateEmails,
			}),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
