/* eslint-disable prefer-const */
import React from 'react';
import strings from 'utils/strings';
import moment from 'moment';
import NewClassModal from './NewClassModal';
import {updateObject} from '../../../utils/reducerUtils';
import addMomentsToTestResults from '../../../utils/addMomentsToTestResults';
import getBatchedTestResults from '../../../utils/getBatchedTestResults';
import './styles.scss';

import NewCandidateModal from './NewCandidateModal';
import InformationPane from '../../../components/InformationPane';
import TestDispatchModal from './TestDispatchModal';
import getTestAdminGroupResults from '../../../api/getTestAdminGroupResults';
import getTestAdminClassResults from '../../../api/getTestAdminClassResults';

import classNames from 'classnames';
import {Link} from 'react-router-dom';
import create from 'assets/create-24px.svg';
import updateLogEvent from 'api/updateLogEvent';

import {
	COMPLETED,
	DISTRIBUTED,
	NOT_STARTED,
	STARTED,
	TEST_TYPES,
	TEST_TYPE_NAMES,
} from 'constants/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faK,
	faUserGroup,
	faCheck,
	faPeopleGroup,
	faPaperPlane,
	faPenToSquare,
	faPlus,
	faTrash,
	faXmark,
} from '@fortawesome/free-solid-svg-icons';

const childTypes = [
	TEST_TYPES.CHILD_FOLLOWUP,
	TEST_TYPES.YOUTH_FOLLOWUP,
	TEST_TYPES.ADULT_FOLLOWUP,
];
const defaultSelectedTestCandidates = [];
const allMyCandidates = strings.allmycandidates;
let sortOrder = true;

const processTestResults = (testResults, recentKey) => {
	const testResultsWithStatus = testResults.map((testResult) => {
		let status;
		if (testResult.completedAt) {
			status = COMPLETED;
		} else if (testResult.usedAt) {
			status = STARTED;
		} else if (testResult.distributedAt) {
			status = DISTRIBUTED;
		} else {
			status = NOT_STARTED;
		}

		return updateObject(testResult, {
			status,
		});
	});

	const testResultsWithMoments = addMomentsToTestResults(
		testResultsWithStatus
	);

	const batchedTestResults = getBatchedTestResults(testResultsWithMoments);
	return batchedTestResults;
};
let globalGroupedClasses = [];
class MyCandidates extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isMainChecked: false,
			singleChecks: false,
			selectedTestCandidates: defaultSelectedTestCandidates,
			searchFilter: '',
			isCreatingClass: false,
			individualCandidates: [],
			showIndividualTests: false,
			isCreatingCandidate: false,
			isSendingTest: false,
			selectedIndividual: null,
			isClassPanelActive: false,
			isGroupPanelActive: false,
			newClassName: '',
			renderPaneAlert: false,
			paneMessage: '',
			selectedClass: '',
			canEditClass: false,
			selectedClassId: null,
			isEditingClass: false,
			allCandidates: this.props.testUsers,
			groupCandidates: null,
			isRenamingClass: false,
			groupTestResults: [],
			candidatesInGroups: this.props.userGroups,
			groupedOption: this.props.groupedOption,
			newGroupedClasses: null,
			selectedGroupOption: '',
			isGroupDropDownOpen: false,
		};
		this.handleClick = this.handleClick.bind(this);
		this.returnOptionsHere = this.returnOptionsHere.bind(this);
	}
	componentDidMount() {
		this.setState({selectedClass: strings.allmycandidates});
		document.addEventListener('click', this.handleOutsideClick);
	}
	componentWillUnmount() {
		document.removeEventListener('click', this.handleOutsideClick);
	}
	handleOutsideClick = (event) => {
		if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
			this.setState({isGroupDropDownOpen: false});
		}
	};

	async excludeMembersIfExists(
		value,
		classMembers,
		selectedTestUsers,
		currentUser
	) {
		const {subscription} = currentUser;

		const selectedTestUserIds = selectedTestUsers.map((TestUser) => {
			return {
				testUser: TestUser.id,
				firstName: TestUser.firstName,
				lastName: TestUser.lastName,
			};
		});
		var getrelevantData = classMembers.filter(
			(userClass) => userClass.className === value
		);

		const candidates = getrelevantData[0].classInfo;
		let flag = null;
		let currentClassId = null;
		const temObj = [];
		const permObj = [];
		for (let i = 0; i < selectedTestUserIds.length; i++) {
			flag = 0;
			for (let j = 0; j < candidates.length; j++) {
				if (
					selectedTestUserIds[i].testUser ===
					candidates[j].candidateId
				) {
					flag = 1;
					const matched = {
						Name:
							selectedTestUserIds[i].firstName +
							' ' +
							selectedTestUserIds[i].lastName,
					};

					temObj.push(matched);
				} else {
					currentClassId = candidates[j].classId;
				}
			}
			if (flag !== 1) {
				const unMatched = {
					testUser: selectedTestUserIds[i].testUser,
					class: currentClassId,
					subscription: subscription.id,
					adminid: currentUser.id,
				};
				permObj.push(unMatched);
			}
		}
		this.setState({
			exclusiveMembers: permObj,
			targetClass: currentClassId,
			inclusiveMember: temObj,
		});
	}

	findWithProp(array, attr, value) {
		for (let i = 0; i < array.length; i += 1) {
			if (array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	}
	handleSingleCheck(target, testUser, filteredTestUsers) {
		if (target.checked) {
			testUser.checked = true;
			this.setState({
				selectedTestCandidates: filteredTestUsers,
				allCandidates: filteredTestUsers,
			});
		} else {
			testUser.checked = false;
			this.setState({
				selectedTestCandidates: filteredTestUsers,
				allCandidates: filteredTestUsers,
			});
		}
	}
	determineGender(currentTestUser, allTestUsers) {
		for (let i = 0; i < allTestUsers.length; i++) {
			const testsResults = allTestUsers[i].testResults;
			for (let j = 0; j < testsResults.length; j++) {
				if (testsResults[j].testUser) {
					if (
						testsResults[j].testUser.testUserId ===
							currentTestUser &&
						testsResults[j].info
					) {
						const information = JSON.parse(testsResults[j].info);
						if (testsResults[j].type !== 'child') {
							if (information.gender === 'm') {
								return (
									<div className="custom_table-cell">
										{strings.male}
									</div>
								);
							} else if (information.gender === 'f') {
								return (
									<div className="custom_table-cell">
										{strings.female}
									</div>
								);
							}
						} else if (information.gender === 'm') {
							return (
								<div className="custom_table-cell">
									{strings.boy}
								</div>
							);
						} else if (information.gender === 'f') {
							return (
								<div className="custom_table-cell">
									{strings.girl}
								</div>
							);
						}
					}
				}
			}
		}

		return <div className="custom_table-cell">{'---'}</div>;
	}
	getFilteredTestUsers(currentTestUser, allTestUsers) {
		const {groupTestResults} = this.state;
		const tempObj = [];
		if (this.state.isGroupPanelActive || this.state.isClassPanelActive) {
			for (let i = 0; i < groupTestResults.length; i++) {
				const testsResults = groupTestResults[i].testResults;
				for (let j = 0; j < testsResults.length; j++) {
					if (testsResults[j].testUser) {
						if (
							testsResults[j].testUser.testUserId ===
							currentTestUser
						) {
							tempObj.push(testsResults[j]);
						}
					}
				}
			}
		} else {
			for (let i = 0; i < allTestUsers.length; i++) {
				const testsResults = allTestUsers[i].testResults;
				for (let j = 0; j < testsResults.length; j++) {
					if (testsResults[j].testUser) {
						if (
							testsResults[j].testUser.testUserId ===
							currentTestUser
						) {
							tempObj.push(testsResults[j]);
						}
					}
				}
			}
		}
		if (tempObj.length) {
			return tempObj;
		}

		return null;
	}
	sortTableBy(identifier) {
		const {allCandidates} = this.state;
		const allUsers = allCandidates.length
			? allCandidates
			: this.props.testUsers;
		sortOrder = !sortOrder;

		switch (identifier) {
			case 'firstname':
				allUsers.sort(this.compareByfirstName);
				break;

			case 'date':
				allUsers.sort(this.compareByDate);
				break;
		}
		this.setState({allCandidates: allUsers});
	}
	compareByfirstName(a, b) {
		if (!sortOrder) {
			if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
				return 1;
			}
			if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
				return -1;
			}
			return 0;
		}
		if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
			return 1;
		}
		if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
			return -1;
		}
		return 0;
	}
	compareByLastName(a, b) {
		if (!sortOrder) {
			if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
				return 1;
			}
			if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
				return -1;
			}
			return 0;
		} else {
			if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
				return 1;
			}
			if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
				return -1;
			}
			return 0;
		}
	}
	compareByDate(a, b) {
		if (!sortOrder) {
			if (a.createdAt < b.createdAt) {
				return 1;
			}
			if (a.createdAt > b.createdAt) {
				return -1;
			}
			return 0;
		} else {
			if (a.createdAt > b.createdAt) {
				return 1;
			}
			if (a.createdAt < b.createdAt) {
				return -1;
			}
			return 0;
		}
	}

	async getAndSetTestAdminGroupTestResults(selectedGroup) {
		const selectedgroup = selectedGroup;
		if (selectedGroup !== '') {
			const testResults = await getTestAdminGroupResults(selectedgroup);
			let batchedFilteredTestResults;
			const batchedTestResults = await processTestResults(
				testResults.testResults,
				null
			);
			return await new Promise((resolve) => {
				this.setState(
					{
						allCandidates: testResults.testUsers,
						isGroupPanelActive: true,
						groupTestResults: batchedTestResults,
						isClassPanelActive: false,
					},
					resolve
				);
			});
		}
		return null;
	}

	async getAndSetTestAdminClassTestResults(
		selectedClass,
		classValue,
		ownsClass
	) {
		const selectedclass = selectedClass;
		if (selectedClass !== '') {
			const testResults = await getTestAdminClassResults(selectedclass);
			const batchedTestResults = await processTestResults(
				testResults.testResults,
				null
			);
			return await new Promise((resolve) => {
				this.setState(
					{
						allCandidates: testResults.testUsers,
						isGroupPanelActive: true,
						groupTestResults: batchedTestResults,
						isClassPanelActive: true,
						selectedClassId: selectedClass,
						selectedClass: classValue,
						canEditClass: ownsClass,
					},
					resolve
				);
			});
		}
		return null;
	}

	renderCustomTableHeader(filteredTestUsers) {
		if (!filteredTestUsers.length) {
			return (
				<p>
					Det ser ut til at du ikke har ingen kandidater for det
					valgte valget
				</p>
			);
		}
		return (
			<div className="lit-custom-header_grid">
				<div className="lit-custom-header-wrapper">
					<div className="custom_checkBox-cell">
						<div className="checkbox-wrapper-13">
							<input
								type="checkbox"
								className="user_checkbox2"
								defaultChecked={this.state.isMainChecked}
								id="main_checkbox"
								onChange={(e) => {
									if (e.target.checked) {
										const checkedClassData = filteredTestUsers.map(
											(data) => {
												data.checked = true;
												return data;
											}
										);

										this.setState({
											isMainChecked: true,
											selectedTestCandidates: checkedClassData,
										});
									} else {
										const checkedClassData = filteredTestUsers.map(
											(data) => {
												data.checked = false;
												return data;
											}
										);

										this.setState({
											isMainChecked: false,
											selectedTestCandidates: checkedClassData,
										});
									}
								}}
							/>
						</div>
					</div>

					<div className="custom_table-header-cell">
						{' '}
						<span
							className="className-title"
							onClick={() => {
								this.sortTableBy('firstname');
							}}
						>
							{' '}
							{strings.fullname}
						</span>
					</div>
					<div className="custom_table-header-cell">
						{strings.email}
					</div>
					<div className="custom_table-header-cell">
						{strings.telephone}
					</div>
					<div className="custom_table-header-cell">
						{strings.gender}
					</div>
					<div className="custom_table-header-cell">
						<span
							className="className-title"
							onClick={() => {
								this.sortTableBy('date');
							}}
						>
							{' '}
							{strings.date}
						</span>
					</div>
					<div className="custom_table-header-cell" />
				</div>
			</div>
		);
	}
	renderCustomTable(testUser, filteredTestUsers) {
		return (
			<div>
				<div
					className={
						testUser.active
							? 'lit-custom_grid-active'
							: 'lit-custom_grid'
					}
					onClick={() => {
						if (!testUser.active) {
							testUser.active = true;
							this.setState({
								showIndividualTests: true,
								selectedIndividual: testUser.id,
							});
						} else {
							testUser.active = false;
							this.setState({
								showIndividualTests: false,
								selectedIndividual: testUser.id,
							});
						}
					}}
				>
					{!this.state.isMainChecked && !this.state.singleChecks && (
						<div
							className="custom_checkBox-cell"
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<div className="checkbox-wrapper-13">
								<input
									type="checkbox"
									className="user_checkbox2"
									defaultChecked={testUser.checked}
									id={testUser.id}
									key={testUser.id}
									onChange={(e) => {
										e.stopPropagation();
										this.handleSingleCheck(
											e.target,
											testUser,
											filteredTestUsers
										);
									}}
								/>
							</div>
						</div>
					)}
					{this.state.isMainChecked && (
						<div className="custom_checkBox-cell">
							<div className="checkbox-wrapper-13">
								<input
									type="checkbox"
									className="user_checkbox2"
									defaultChecked={Boolean(
										this.state.isMainChecked
									)}
									id={testUser.id}
									key={testUser.id}
									onChange={(e) => {
										e.stopPropagation();
										this.handleSingleCheck(
											e.target,
											testUser,
											this.state.selectedTestCandidates
										);
									}}
								/>
							</div>
						</div>
					)}
					<div className="custom_table-cell">
						{testUser.firstName &&
							`${testUser.firstName} ${testUser.lastName}`}
					</div>

					<div className="custom_table-cell">{testUser.email}</div>

					<div className="custom_table-cell">{testUser.phone}</div>
					{this.determineGender(
						testUser.id,
						this.props.batchedFilteredTestResults
					)}

					<div className="custom_table-cell">
						{testUser.usedAt
							? moment(testUser.usedAt).format('LL')
							: moment(testUser.createdAt).format('LL')}
					</div>

					<div className="custom_btn-cell">
						<div className="lit-list__text">
							<button
								className={
									testUser.active
										? 'lit-btn lit-btn--small bg-primary5-active'
										: 'lit-btn lit-btn--small bg-primary5'
								}
								onClick={(e) => {
									e.stopPropagation();
									if (!testUser.active) {
										testUser.active = true;
										this.setState({
											showIndividualTests: true,
											selectedIndividual: testUser.id,
										});
									} else {
										testUser.active = false;
										this.setState({
											showIndividualTests: false,
											selectedIndividual: testUser.id,
										});
									}
								}}
							>
								{testUser.active ? (
									<span className="lit-btns-txt-negative">
										{strings.hideWithArrow}
									</span>
								) : (
									<span>{strings.showtests}</span>
								)}
							</button>
						</div>
					</div>
				</div>
				{testUser.active === true &&
					this.renderSubTable(
						this.getFilteredTestUsers(
							testUser.id,
							this.props.batchedFilteredTestResults
						)
					)}
			</div>
		);
	}
	handleClick = (id, key) => {
		this.updateViewLog(id, key);
	};

	updateViewLog(id, key) {
		updateLogEvent('view', id, key, 'Results');
	}
	renderButton(type, id, status, key) {
		const completed = status === COMPLETED;
		const started = status === STARTED;

		const isChildType = childTypes.includes(type);

		const destination = isChildType
			? `/single-child-test/${id}`
			: type === 'child-experimental'
			? `/single-speech-test/${id}`
			: `/single-test/${id}`;

		if (completed || started) {
			return (
				<Link
					className="lit-btn lit-btn--small bg-secondary"
					to={destination}
					onClick={() => this.handleClick(id, key)}
					target="_blank"
					rel="noopener noreferrer"
				>
					{strings.seeresults}
				</Link>
			);
		}

		return <div className="lit-test__field"> </div>;
	}

	renderSubTable(singleCandidate) {
		if (singleCandidate) {
			return (
				<div className="lit-individual-test">
					<table className="lit-tests">
						<thead>
							<tr>
								<td className="lit-test__header">
									{strings.status}
								</td>
								<td className="lit-test__header">
									{strings.testkey}
								</td>
								<td className="lit-test__header">
									{strings.type}
								</td>
								<td className="lit-test__header">
									{strings.recipient}
								</td>
								<td className="lit-test__col-render-header">
									{strings.actions}
								</td>
							</tr>
						</thead>
						<tbody>
							{singleCandidate.map((candidate) => {
								const {
									id,
									key,
									testUser,
									status,
									type,
								} = candidate;
								const firstName = testUser.firstName;
								const lastName = testUser.lastName;
								const fullName = `${firstName} ${lastName}`;
								return (
									<tr className="lit-test" key={key}>
										{this.getLabelWithStatus(
											candidate.completedAt,
											candidate.usedAt,
											candidate.distributedAt,
											status
										)}

										<td className="lit-test__col">{key}</td>
										<td className="lit-test__col">
											{TEST_TYPE_NAMES[type]}
										</td>
										<td className="lit-test__col">
											{fullName || (
												<b>{strings.notdistributed}</b>
											)}
											&nbsp;
										</td>
										<td className="lit-test__col-render">
											{this.renderButton(
												type,
												id,
												status,
												key
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			);
		}
		return (
			<div>
				<p style={{color: 'white'}}>{strings.notestsforcandidate}</p>
			</div>
		);
	}
	evaluateCheckStatus(allCandidates, classInfo) {
		for (let i = 0; i < allCandidates.length; i++) {
			for (let j = 0; j < classInfo.length; j++) {
				if (allCandidates[i].id === classInfo[j].id) {
					if (allCandidates[i].checked === true) {
						classInfo[j].checked = true;
					}
				}
			}
		}
		return classInfo;
	}
	findTestUserNotInClass(allCandidates, classInfo) {
		const arrayOfCandidates = [];
		let existsInclasses = false;
		for (let i = 0; i < allCandidates.length; i++) {
			let candidateId = allCandidates[i].id;
			for (let j = 0; j < classInfo.length; j++) {
				let classWIthCandidates = classInfo[j].classInfo;
				for (let k = 0; k < classWIthCandidates.length; k++) {
					if (candidateId === classWIthCandidates[k].id) {
						existsInclasses = true;
						break;
					}
				}
				if (existsInclasses) break;
			}
			if (!existsInclasses) {
				arrayOfCandidates.push(allCandidates[i]);
			} else {
				existsInclasses = false;
			}
		}
		return arrayOfCandidates;
	}

	getLabelWithStatus(completedAt, usedAt, distributedAt, status) {
		let testDateInfo;
		if (completedAt) {
			testDateInfo = (
				<td className={'lit-test__col'}>
					<div className={'lit-test-status'}>
						<div
							className={classNames({
								'lit-test-status__indicator': true,
								[status.id]: true,
							})}
						/>
						<span className="lit-test-status__label">
							{status.label}
							<br />
							<span className="lit-test-status__margin">
								{moment(completedAt).locale('nb').format('LLL')}
							</span>
						</span>
					</div>
				</td>
			);
		} else if (usedAt) {
			testDateInfo = (
				<td className={'lit-test__col'}>
					<div className={'lit-test-status'}>
						<div
							className={classNames({
								'lit-test-status__indicator': true,
								[status.id]: true,
							})}
						/>
						<span className="lit-test-status__label">
							{status.label}
							<br />
							<span className="lit-test-status__margin">
								{moment(usedAt).locale('nb').format('LLL')}
							</span>
						</span>
					</div>
				</td>
			);
		} else if (distributedAt) {
			testDateInfo = (
				<td className={'lit-test__col'}>
					<div className={'lit-test-status'}>
						<div
							className={classNames({
								'lit-test-status__indicator': true,
								[status.id]: true,
							})}
						/>
						<span className="lit-test-status__label">
							{status.label}
							<br />
							<span className="lit-test-status__margin">
								{moment(distributedAt)
									.locale('nb')
									.format('LLL')}
							</span>
						</span>
					</div>
				</td>
			);
		} else {
			testDateInfo = (
				<td className={'lit-test__col'}>
					<div className={'lit-test-status'}>
						<div
							className={classNames({
								'lit-test-status__indicator': true,
								[status.id]: true,
							})}
						/>
						<p className="lit-test-status__label">{status.label}</p>
					</div>
				</td>
			);
		}

		return testDateInfo;
	}
	returnOptionsHere(collectedOptions) {
		return collectedOptions;
	}

	filterCandidates = (usersArray) => {
		if (this.props.isCandidateDataFirstTimeRendered) {
			this.props.revertIsCandidateDataFirstTimeRendered();
		}
		const filteredTestUsers = usersArray?.filter((testUser) => {
			const searchableFields = [
				'firstName',
				'lastName',
				'phone',
				'email',
			];
			for (const field of searchableFields) {
				if (
					testUser[field] &&
					testUser[field]
						.toLowerCase()
						.indexOf(this.state.searchFilter) !== -1
				) {
					return true;
				}
			}
			return false;
		});
		return filteredTestUsers;
	};

	toggleGroupsDropdown = () => {
		this.setState((prevState) => ({
			isGroupDropDownOpen: !prevState.isGroupDropDownOpen,
		}));
	};

	onHandleGroupDropDownOpen = (e) => {
		if (e === allMyCandidates) {
			const checkedClassData = this.props.testUsers.map((data) => {
				data.checked = false;
				return data;
			});

			this.setState({
				isMainChecked: false,
				selectedTestCandidates: checkedClassData,
				allCandidates: this.props.testUsers,
				filteredTestUsers: this.props.testUsers,
				isClassPanelActive: false,
				isGroupPanelActive: false,
				canEditClass: false,
			});
		} else if (e === 'outsideClass') {
			const checkStatus = this.findTestUserNotInClass(
				this.props.testUsers,
				this.props.classData,
				this.filteredTestUsers
			);

			this.setState({
				allCandidates: checkStatus,
				isClassPanelActive: true,
				isGroupPanelActive: false,
				selectedClass: e,
				selectedClassId: 'outSideClass',
				canEditClass: false,
			});
		} else if (e.includes('groupTitle')) {
			let extractedGroup = e.split('groupTitle');
			const groupName = extractedGroup[extractedGroup.length - 1];
			const chosenGroup = this.props.userGroups.filter(
				(group) => group.name === groupName
			);
			this.getAndSetTestAdminGroupTestResults(chosenGroup[0].id);
		} else {
			const chosenClass = this.props.classData.filter(
				(className) => className.className === e
			);

			this.getAndSetTestAdminClassTestResults(
				chosenClass[0].classId,
				e,
				chosenClass[0].ownsClass
			);
		}
	};
	// eslint-disable-next-line complexity
	render() {
		const {
			hasInValidSubscription,
			testUsers,
			isBasicUser,
			isTrialUser,
			batchedFilteredTestResults,
			userGroups,
			groupedOption,
		} = this.props;
		const {
			selectedTestCandidates,
			isCreatingClass,
			isCreatingCandidate,
			isSendingTest,
			allCandidates,
			isGroupDropDownOpen,
			selectedGroupOption,
		} = this.state;
		let filteredTestUsers = this.filterCandidates(testUsers);
		if (allCandidates.length) {
			filteredTestUsers = this.filterCandidates(allCandidates);
		}
		return (
			<div>
				<div className="lit-tests-view__header">
					<div className="lit-new-test__wrapper">
						<div className="lit-input-tab-grid">
							{Boolean(filteredTestUsers.length) && (
								<button
									className="lit-mytests-header-mytest-btn-primary"
									disabled={
										(hasInValidSubscription ||
											isTrialUser ||
											isBasicUser ||
											!testUsers.length) &&
										!filteredTestUsers.length
									}
									onClick={() => {
										let extractedSelectedCandidates = null;
										if (
											!testUsers.length &&
											!filteredTestUsers.length
										) {
											this.setState({
												renderPaneAlert: true,
												paneMessage:
													strings.nocandidates,
											});
										}
										extractedSelectedCandidates = this.state.allCandidates.filter(
											(data) => {
												if (data.checked) {
													return data;
												}
											}
										);

										if (
											!Boolean(
												extractedSelectedCandidates.length
											)
										) {
											this.setState({
												renderPaneAlert: true,
												paneMessage:
													strings.choosecandidate,
											});
										} else {
											this.setState({
												selectedTestCandidates: extractedSelectedCandidates,
												isSendingTest: true,
											});
										}
									}}
								>
									<FontAwesomeIcon
										className="lit-icon-btns"
										icon={faPaperPlane}
									/>
									<p className="lit-btns-txt">
										{strings.createTest}
									</p>
								</button>
							)}
							{!this.state.isGroupPanelActive &&
								Boolean(filteredTestUsers.length) && (
									<button
										className="lit-mytests-header-mytest-btn-secondary"
										disabled={
											!testUsers.length &&
											!filteredTestUsers.length
										}
										onClick={() => {
											let extractedSelectedCandidates = null;
											if (
												!testUsers.length &&
												!filteredTestUsers.length
											) {
												this.setState({
													renderPaneAlert: true,
													paneMessage:
														strings.nocandidates,
												});
											}

											extractedSelectedCandidates = this.state.allCandidates.filter(
												(data) => {
													if (data.checked) {
														return data;
													}
												}
											);

											if (
												!Boolean(
													extractedSelectedCandidates.length
												)
											) {
												this.setState({
													renderPaneAlert: true,
													paneMessage:
														strings.choosecandidate,
												});
											} else {
												this.setState({
													selectedTestCandidates: extractedSelectedCandidates,
													isCreatingClass: true,
												});
											}
										}}
									>
										<FontAwesomeIcon
											className="lit-icon-btns"
											icon={faPlus}
										/>
										<p className="lit-btns-txt">
											{strings.createclass}
										</p>
									</button>
								)}

							{this.state.canEditClass && (
								<div>
									<button
										className="lit-mytests-header-mytest-btn-ternary"
										onClick={() => {
											let extractSelectedCandidates;
											if (
												!testUsers.length &&
												!filteredTestUsers.length
											) {
												this.setState({
													renderPaneAlert: true,
													paneMessage:
														strings.nocandidates,
												});
											} else {
												extractSelectedCandidates = this.state.allCandidates.filter(
													(data) => {
														if (data.checked) {
															return data;
														}
													}
												);
												const isolatedIds = extractSelectedCandidates.map(
													(userids) => {
														return userids.id;
													}
												);
												if (
													!Boolean(isolatedIds.length)
												) {
													this.setState({
														renderPaneAlert: true,
														paneMessage:
															strings.choosecandidate,
													});
												} else if (
													this.state
														.selectedClassId ===
													'outSideClass'
												) {
													this.setState({
														renderPaneAlert: true,
														paneMessage:
															'Det er ikke tillat a endre noe for det valgte valget',
													});
												} else {
													this.props.onDeletingCandidates(
														isolatedIds,
														this.state
															.selectedClassId,
														this.props.currentUser
															.subscription.id
													);
												}
											}
										}}
									>
										<FontAwesomeIcon
											className="lit-icon-btns"
											icon={faTrash}
										/>
										<p className="lit-btns-txt">
											{strings.removefromclass}
										</p>
									</button>
								</div>
							)}
							{Boolean(testUsers.length) && (
								<input
									className="lit-tf_search"
									type="text"
									placeholder={strings.searchhere}
									id="testkey"
									onChange={(e) => {
										this.setState({
											searchFilter: e.target.value.toLowerCase(),
										});
									}}
								/>
							)}

							{this.state.isClassPanelActive &&
								this.state.isRenamingClass && (
									<input
										className="lit-tf_search"
										autoFocus
										type="text"
										placeholder={`${this.state.selectedClass}`}
										id="newName"
										onChange={(e) => {
											this.setState({
												newClassName: e.target.value,
											});
										}}
									/>
								)}

							{(Boolean(this.props.classData.length) ||
								Boolean(this.props.userGroups.length)) &&
								!this.state.isRenamingClass && (
									<div className="lit-View_tests">
										<div
											className="select-container"
											ref={(node) =>
												(this.dropdownRef = node)
											}
										>
											<div
												className="select-display"
												onClick={
													this.toggleGroupsDropdown
												}
											>
												{selectedGroupOption ||
													allMyCandidates}
												<span className="dropdown-arrow">
													▼
												</span>
											</div>
											{isGroupDropDownOpen && (
												<div className="dropdown-list-withScroller">
													<div
														className="dropdown-item-with-scroller"
														onClick={() => {
															this.setState({
																selectedGroupOption: allMyCandidates,
																isGroupDropDownOpen: false,
															});
															this.onHandleGroupDropDownOpen(
																allMyCandidates
															);
														}}
													>
														{allMyCandidates}
													</div>
													{Boolean(
														this.props.userGroups
															.length
													) && (
														<div
															className="dropdown-item disabled"
															onClick={() => {
																this.setState({
																	isGroupDropDownOpen: false,
																});
															}}
														>
															{
																'Kandidater i grupper:'
															}
														</div>
													)}

													{Boolean(
														this.props.groupedOption
															.length
													) &&
														this.props.groupedOption.map(
															(
																singleOption,
																index
															) => (
																<div
																	key={index}
																	onClick={() => {
																		this.setState(
																			{
																				selectedGroupOption:
																					singleOption.type ===
																					'group'
																						? singleOption.name
																						: `${singleOption.name}`,
																				isGroupDropDownOpen: false,
																			}
																		);
																		this.onHandleGroupDropDownOpen(
																			singleOption.type ===
																				'group'
																				? `groupTitle${singleOption.name}`
																				: singleOption.name
																		);
																	}}
																	className="dropdown-item-with-scroller"
																>
																	{singleOption.type ===
																	'group' ? (
																		<span
																			style={{
																				textDecoration:
																					'',
																			}}
																		>
																			<FontAwesomeIcon
																				style={{
																					backgroundColor:
																						'white',
																					borderRadius:
																						'20px',
																					padding:
																						'2px',
																					marginRight:
																						'5px',
																				}}
																				icon={
																					faUserGroup
																				}
																			/>
																			<i>
																				{`${singleOption.name}`}
																			</i>
																		</span>
																	) : (
																		<span
																			style={{
																				marginLeft:
																					'18px',
																			}}
																		>
																			<FontAwesomeIcon
																				style={{
																					backgroundColor:
																						'white',
																					borderRadius:
																						'20px',
																					padding:
																						'2px',
																					marginRight:
																						'5px',
																				}}
																				icon={
																					faPeopleGroup
																				}
																			/>
																			{`${singleOption.name}`}
																		</span>
																	)}
																</div>
															)
														)}
													{this.props.classData
														.length && (
														<div
															className="dropdown-item disabled"
															onClick={() => {
																this.setState({
																	isGroupDropDownOpen: false,
																});
															}}
														>
															{
																strings.classindicator
															}
														</div>
													)}
													{this.props.classData.map(
														(userClass, index) => (
															<div
																key={index}
																className="dropdown-item-with-scroller"
																onClick={() => {
																	this.setState(
																		{
																			selectedGroupOption:
																				userClass.className,
																			isGroupDropDownOpen: false,
																		}
																	);
																	this.onHandleGroupDropDownOpen(
																		userClass.className
																	);
																}}
															>
																<span
																	style={{
																		marginLeft:
																			'5px',
																	}}
																>
																	<FontAwesomeIcon
																		style={{
																			backgroundColor:
																				'white',
																			borderRadius:
																				'20px',
																			padding:
																				'2px',
																			marginRight:
																				'5px',
																		}}
																		icon={
																			faPeopleGroup
																		}
																	/>
																	{`${userClass.className}`}
																</span>
															</div>
														)
													)}

													<div
														className="dropdown-item disabled"
														onClick={() => {
															this.setState({
																isGroupDropDownOpen: false,
															});
														}}
													>
														{'ikke lagt i klasse:'}
													</div>
													<div
														className="dropdown-item-with-scroller"
														onClick={() => {
															this.setState({
																selectedGroupOption:
																	'kandidater',
																isGroupDropDownOpen: false,
															});
															this.onHandleGroupDropDownOpen(
																'outsideClass'
															);
														}}
													>
														<span
															style={{
																marginLeft:
																	'4px',
															}}
														>
															<FontAwesomeIcon
																style={{
																	backgroundColor:
																		'white',
																	borderRadius:
																		'20px',
																	padding:
																		'2px',
																	marginRight:
																		'5px',
																}}
																icon={faK}
															/>
															{'kandidater'}
														</span>
													</div>
												</div>
											)}
										</div>
									</div>
								)}
							<div className="lit-mytests-candidate-edit-class">
								{this.state.canEditClass && (
									<button
										className="lit-mytests-header-mytest-icon-btn-primary"
										onClick={() => {
											this.setState({
												isEditingClass: !this.state
													.isEditingClass,
												isRenamingClass: !this.state
													.isRenamingClass,
											});
										}}
									>
										<FontAwesomeIcon
											size="lg"
											className="lit-mytests-header-mytest-icon-btn-icon"
											icon={faPenToSquare}
										/>
									</button>
								)}
								{this.state.isClassPanelActive &&
									this.state.isRenamingClass && (
										<>
											<button
												className="lit-mytests-header-mytest-icon-btn-secondary"
												onClick={() => {
													if (
														!this.state.newClassName
													) {
														this.setState({
															renderPaneAlert: true,
															paneMessage:
																strings.providevalidname,
														});
													} else {
														const newTitleAndId = {
															className: this
																.state
																.newClassName,
															adminUser: this
																.props
																.currentUser.id,
															targetClass: this
																.state
																.selectedClass,
														};

														this.props.onRenamingClass(
															newTitleAndId
														);
														this.setState({
															isRenamingClass: false,
														});
													}
												}}
											>
												<FontAwesomeIcon
													size="lg"
													className="lit-mytests-header-mytest-icon-btn-icon"
													icon={faCheck}
												/>
											</button>
											<button
												className="lit-mytests-header-mytest-icon-btn-ternary"
												onClick={() => {
													this.setState(
														{
															allCandidates: this
																.props
																.testUsers,

															isClassPanelActive: true,
															selectedTestCandidates: defaultSelectedTestCandidates,
															selectedClass:
																strings.allmycandidates,
														},
														() => {
															let extractedCandidates;
															this.state.allCandidates.forEach(
																() => {
																	extractedCandidates = this.state.allCandidates.filter(
																		(
																			candidate
																		) =>
																			candidate.checked ===
																			true
																	);
																}
															);

															this.setState({
																selectedTestCandidates: extractedCandidates,
																isClassPanelActive: false,
																isRenamingClass: false,
																isEditingClass: false,
															});
														}
													);
												}}
											>
												<FontAwesomeIcon
													size="lg"
													className="lit-mytests-header-mytest-icon-btn-icon"
													icon={faXmark}
												/>
											</button>
										</>
									)}
							</div>
							{this.state.renderPaneAlert && (
								<InformationPane
									paneMessage={this.state.paneMessage}
									onApproval={() => {
										this.setState({
											renderPaneAlert: false,
										});
									}}
									onCloseWindow={() => {
										this.setState({
											renderPaneAlert: false,
										});
									}}
									onDisproval={() => {
										this.setState({
											renderPaneAlert: false,
										});
									}}
									category={'information'}
								/>
							)}
						</div>
					</div>
				</div>

				<div className="lit-tests-view__contant">
					{!testUsers.length &&
						!userGroups.length &&
						!filteredTestUsers.length && (
							<p>{strings.nocandidates}</p>
						)}
					{(Boolean(testUsers.length) ||
						Boolean(userGroups.length)) && (
						<div
							className={
								filteredTestUsers.length
									? 'lit-main-candidates-contianer'
									: 'lit'
							}
						>
							<div className="lit_table-wrapper">
								{this.renderCustomTableHeader(
									filteredTestUsers
								)}

								{filteredTestUsers.map((testUser, index) => {
									return (
										<div key={index}>
											{this.renderCustomTable(
												testUser,
												filteredTestUsers
											)}
										</div>
									);
								})}
							</div>
						</div>
					)}

					{isCreatingClass && (
						<NewClassModal
							closeWindow={() => {
								this.setState({
									isCreatingClass: false,
								});
							}}
							selectedTestCandidates={selectedTestCandidates}
							currentUser={this.props.currentUser}
							batchedFilteredTestResults={
								batchedFilteredTestResults
							}
							isGroupPanelActive={this.state.isGroupPanelActive}
							userGroups={this.props.userGroups}
							classes={this.props.classes}
							classData={this.props.classData}
							onCreateClass={this.props.onCreateClass}
							adminClasses={this.props.adminClasses}
							onAddingCandidates={this.props.onAddingCandidates}
						/>
					)}

					{isCreatingCandidate && (
						<NewCandidateModal
							closeWindow={() => {
								this.setState({
									isCreatingCandidate: false,
								});
							}}
							testUsers={selectedTestCandidates}
						/>
					)}

					{isSendingTest && (
						<TestDispatchModal
							closeWindow={() => {
								this.setState({
									isSendingTest: false,
								});
							}}
							testUsers={selectedTestCandidates}
							onSaveBulk={this.props.onSaveBulk}
							userStage={this.props.userStage}
							userGroups={this.props.userGroups}
							userLanguages={this.props.userLanguages}
							currentUser={this.props.currentUser}
							usersGroups={this.props.usersGroups}
							batchedFilteredTestResults={
								batchedFilteredTestResults
							}
							hasInValidSubscription={
								this.props.hasInValidSubscription
							}
						/>
					)}
				</div>
			</div>
		);
	}
}
export default MyCandidates;
