import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import updateLogEvent from 'api/updateLogEvent';
import {updateObject} from 'utils/reducerUtils';

import getAgeLabel from 'utils/getAgeLabel';
import getBatchedTestResults, {
	getBestTestResultMoment,
} from 'utils/getBatchedTestResults';
import roundWithDecimals from 'utils/roundWithDecimals';

import {GENDER_LABELS} from 'constants/index';

import './styles.scss';
import strings from 'utils/strings';
import getTestsBySearch from 'api/getTestsBySearch';
import addMomentsToTestResults from 'utils/addMomentsToTestResults';

const defaultFilters = {
	testKey: '',
};

class SearchByTestKey extends React.Component {
	constructor() {
		super();

		this.state = {
			filters: defaultFilters,
			batchedTestResults: [],
			isSearchButtonClicked: false,
		};
		this.handleClick = this.handleClick.bind(this);
		this.handleSubmitTestKey = this.handleSubmitTestKey.bind(this);
	}

	handleClick = (id, key) => {
		this.updateViewLog(id, key);
	};

	updateViewLog(id, key) {
		updateLogEvent('view', id, key, 'Results');
	}

	handleSubmitTestKey() {
		getTestsBySearch(this.state.filters).then((testResults) => {
			this.setState({
				batchedTestResults: testResults,
				isSearchButtonClicked: true,
			});
		});
	}

	render() {
		const {filters, batchedTestResults, isSearchButtonClicked} = this.state;

		const months = batchedTestResults;
		return (
			<div className="lit-all-tests">
				<form
					onSubmit={(event) => {
						event.preventDefault();
						this.handleSubmitTestKey();
					}}
				>
					<div className="lit-filters-row">
						<div className="lit-filters">
							<h4 className="lit-filters__heading">
								{strings.testkey}
							</h4>
							<input
								className="lit_txtf"
								type="text"
								placeholder="Skriv testnøkkel... "
								id="testKey"
								required
								name="testKey"
								onChange={(event) => {
									const value = event.target.value;
									// eslint-disable-next-line no-undefined
									if (value === '') {
										this.setState({
											filters: defaultFilters,
										});
									} else {
										const newFilters = updateObject(
											filters,
											{
												testKey: value,
											}
										);
										this.setState({
											filters: newFilters,
										});
									}
								}}
								value={filters.testKey}
							/>
						</div>
					</div>

					<button
						className="lit-btn lit-btn--small bg-primary"
						type="submit"
					>
						{strings.searchhere}
					</button>
				</form>
				{months.length === 0 && isSearchButtonClicked && (
					<p>{strings.notetsfound}</p>
				)}
				{Boolean(months.length) && (
					<div className="month">
						<table className="lit-list">
							<thead>
								<tr>
									<th>{strings.testdate}</th>
									<th>{strings.test_type}</th>
									<th>{strings.zscore}</th>
									<th>{strings.testkey}</th>
									<th>{strings.sex}</th>
									<th>{strings.age}</th>
									<th>{strings.actions}</th>
								</tr>
							</thead>
							<tbody>
								{months.map((testResult) => {
									const {
										id,
										zScore,
										key,
										type,
										isUsed,
										testUser: {gender, age},
									} = testResult;
									const time =
										getBestTestResultMoment(testResult);
									return (
										<tr className="lit-list__item" key={id}>
											<td className={classNames({})}>
												{moment(time).format('LL')}
											</td>
											<td className={classNames({})}>
												{type}
											</td>
											<td className={classNames({})}>
												<h3 className="score">
													{roundWithDecimals(
														zScore,
														3
													)}
												</h3>
											</td>
											<td>{key}</td>
											<td>
												{gender
													? GENDER_LABELS[gender]
													: '--'}
											</td>
											<td>
												{age && age.years
													? getAgeLabel(age)
													: '--'}
											</td>
											{Boolean(isUsed) && (
												<td>
													<Link
														className="lit-btn lit-btn--small bg-secondary"
														target="_blank"
														rel="noopener noreferrer"
														to={
															// eslint-disable-next-line no-nested-ternary
															type.includes(
																'followup'
															)
																? `/single-child-test/${id}`
																: type.includes(
																		'screening'
																  )
																? `/single-test/${id}`
																: `/single-speech-test/${id}`
														}
														onClick={() =>
															this.handleClick(
																id,
																key
															)
														}
													>
														{strings.seeresults}
													</Link>
												</td>
											)}
											{Boolean(!isUsed) && (
												<td>
													<span
														style={{
															color: 'red',
															fontWeight: 'bold',
														}}
													>
														Ikke brukt
													</span>
												</td>
											)}
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SearchByTestKey);
