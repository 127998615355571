import React from 'react';
import './styles.scss';
import classNames from 'classnames';
import strings from 'utils/strings';
import {updateObject} from 'utils/reducerUtils';

import getTestsForInvoice from 'api/getTestsForInvoice';
import sendInvoice from 'api/sendInvoice';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {
	USER_STAGES_INFO,
	ALL,
	NEEDS_APPROVAL,
	AWAITING_CONFIRMATION,
	TRIAL,
	BASIC,
	NORMING,
	PRODUKSJON,
	PROD_NORMING,
	ACTIVE,
	INACTIVE,
	TEST_TYPES,
	language,
} from 'constants/index';
const NEEDS_APPROVAL_INFO = USER_STAGES_INFO[NEEDS_APPROVAL];
const AWAITING_CONFIRMATION_INFO = USER_STAGES_INFO[AWAITING_CONFIRMATION];
const ACTIVE_INFO = USER_STAGES_INFO[ACTIVE];
const TRIAL_INFO = USER_STAGES_INFO[TRIAL];
const BASIC_INFO = USER_STAGES_INFO[BASIC];
const NORMING_INFO = USER_STAGES_INFO[NORMING];
const PRODUCTION_INFO = USER_STAGES_INFO[PRODUKSJON];
const PROD_NORMING_INFO = USER_STAGES_INFO[PROD_NORMING];

const INACTIVE_INFO = USER_STAGES_INFO[INACTIVE];

const userStageFilters = [
	ALL,
	NEEDS_APPROVAL_INFO,
	AWAITING_CONFIRMATION_INFO,
	TRIAL_INFO,
	BASIC_INFO,
	ACTIVE_INFO,
	NORMING_INFO,
	PRODUCTION_INFO,
	PROD_NORMING_INFO,
	INACTIVE_INFO,
];

const childTypes = [
	TEST_TYPES.CHILD_FOLLOWUP,
	TEST_TYPES.YOUTH_FOLLOWUP,
	TEST_TYPES.ADULT_FOLLOWUP,
];
class TestAdminInvoices extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedUser: null,
			selectedTestAdmin: '',
			testAdminsData: [],
			filteredAdminsData: [],
			invoicedTestData: [],
			allData: null,
			searchFilter: '',
			activeFilter: ALL,
			selectedTestFilter: '',
			testCount: '',
			invoicedTestsCount: '',
			selectedDate: moment().utc().toDate(),
			previousInvoiced: 0,
		};
	}
	componentDidMount() {
		this.getInvoices();
	}
	setUserStage(invoiceData) {
		const {testAdmins} = this.props;
		let stage = null;
		const dormantVar = testAdmins.filter((admin) => {
			if (admin.id === invoiceData.admin) {
				stage = admin.userStage.toString();
				return;
			}
		});
		invoiceData['userStage'] = stage;
	}
	getInvoices() {
		getTestsForInvoice().then((results) => {
			return new Promise((resolve) => {
				if (Boolean(results.length)) {
					for (let i = 0; i < results.length; i++) {
						this.setUserStage(results[i]);
					}
					this.setState(
						{
							invoicedTestData: results,
						},
						resolve
					);
				} else {
					this.setState(
						{
							invoicedTestData: [],
						},
						resolve
					);
				}
			});
		});
	}
	createFaktura(adminUser, invoiceDate) {
		let flag = 0;
		/* const testAdmin = adminUser.map((user) => {
			return {adminUser: user.admin, subscription: user.subsId};
        }); */

		const selectedDate = moment(invoiceDate).utc().format('YYYY-MM-DD');

		if (!adminUser.hasOwnProperty('invoiceHistory')) {
			const invoice = {
				adminUser: adminUser.admin,
				subscription: adminUser.subsId,
				invoicedAt: invoiceDate,
			};

			sendInvoice(invoice).then(() => {
				this.getInvoices();
			});
			return;
		}
		const lastInvoiced = moment(
			adminUser.invoiceHistory[0].invoiceData[0].invoicedOn
		)
			.utc()
			.format('YYYY-MM-DD');
		const testData = adminUser.tempObj;
		for (let i = 0; i < testData.length; i++) {
			if (
				invoiceDate >
					moment(testData[i].completedAt)
						.utc()
						.format('YYYY-MM-DD') &&
				selectedDate > lastInvoiced
			) {
				flag = 1;
				break;
			}
		}
		if (flag) {
			const invoice = {
				adminUser: adminUser.admin,
				subscription: adminUser.subsId,
				invoicedAt: invoiceDate,
			};

			sendInvoice(invoice).then(() => {
				this.getInvoices();
			});
		} else {
			alert(strings.notestsafterdatetoinvoice);
			return;
		}
	}
	getFilteredTestAdmins = () => {
		const {activeFilter} = this.state;
		let filteredInvoicedData = this.state.invoicedTestData;
		if (activeFilter !== ALL) {
			filteredInvoicedData = filteredInvoicedData.filter(
				(testAdmin) => testAdmin.userStage === activeFilter.id
			);
		}
		filteredInvoicedData = filteredInvoicedData.filter((testAdmin) => {
			const searchableFields = ['firstName', 'lastName'];

			for (const field of searchableFields) {
				if (
					testAdmin[field] &&
					testAdmin[field]
						.toLowerCase()
						.indexOf(this.state.searchFilter) !== -1
				) {
					return true;
				}
			}
			return false;
		});

		return filteredInvoicedData;
	};
	renderTableHeader() {
		return (
			<div className="lit-Invoices-header_grid">
				<div className="lit-myClass-header-wrapper">
					<div className="lit-invoive-table-header">
						Fornavn, Etternavn
					</div>
					<div className="lit-invoive-table-header">
						Totalt tester
					</div>
					<div className="lit-invoive-table-header">
						Siden sist fakturert
					</div>
					<div className="lit-invoive-table-header">
						Sist fakturert på
					</div>
					{/* <div className="lit-summary_EmptyButton-table-header">
						&nbsp;
					</div> */}
					<div className="lit-invoive-table-header">
						<div className="lit-list__text">
							<span>{strings.action}</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
	renderTableRows(testAdmin, index) {
		testAdmin.expanded = testAdmin.expanded ? true : false;
		return (
			<div>
				<div
					className={
						testAdmin.expanded &&
						this.state.selectedUser === testAdmin.subsId
							? 'lit-invoices_grid-active'
							: 'lit-invoices_grid'
					}
					id={index}
					onClick={() => {
						if (!testAdmin.expanded) {
							testAdmin.expanded = true;

							this.setState({
								selectedUser: testAdmin.subsId,
								selectedTestFilter: '',
							});
							/* this.determineInvoices(testAdmin.subscription.id); */
						} else if (testAdmin.expanded) {
							testAdmin.expanded = false;

							this.setState({
								selectedUser: testAdmin.subsId,
							});
						}
					}}
				>
					<div className="lit-invoice-table-cell">
						{testAdmin.firstName &&
							`${testAdmin.firstName}, ${testAdmin.lastName}`}
					</div>
					<div className="lit-invoice-table-cell">
						{testAdmin.totalTest}
					</div>
					<div className="lit-invoice-table-cell">
						{testAdmin.invoiceHistory
							? this.getTestCountAfterLastInvoice(
									testAdmin.invoiceHistory
							  )
							: 'Aldri fakturert'}
					</div>
					<div className="lit-invoice-table-cell">
						{testAdmin.invoiceHistory
							? this.getLastInvoiceData(testAdmin.invoiceHistory)
							: 'Aldri fakturert'}
					</div>
					{/* <div className="lit-summary_EmptyButton-table-cell">
						&nbsp;
					</div> */}
					<div
						className="lit-invoice-table-send-invoice-btn-cell"
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<button
							className="lit-btn lit-btn--small bg-primary"
							onClick={(e) => {
								const invoiceDate = moment(
									this.state.selectedDate
								)
									.utc()
									.format('YYYY-MM-DD HH:mm:ss');
								this.createFaktura(testAdmin, invoiceDate);
							}}
						>
							<span>{'Send faktura ➤'}</span>
						</button>
					</div>

					<div className="lit-summary_btn-cell">
						<div className="lit-list__text">
							<button className="lit-btn lit-btn--small bg-primary6">
								{testAdmin.expanded === true &&
								this.state.selectedUser === testAdmin.subsId ? (
									<span>{'▲'}</span>
								) : (
									<span>{'▼'}</span>
								)}
							</button>
						</div>
					</div>
				</div>
				{testAdmin.expanded === true &&
					this.state.selectedUser === testAdmin.subsId &&
					this.renderSubTable(testAdmin)}
			</div>
		);
	}
	getLastInvoiceData(invoiceHistory) {
		if (!Boolean(invoiceHistory.length)) return 'Aldri fakturert';
		else {
			return moment(invoiceHistory[0].invoiceData[0].invoicedOn).format(
				'LL'
			);
		}
	}
	//sinceLastInvoiced
	getTestCountAfterLastInvoice(invoiceHistory) {
		if (!Boolean(invoiceHistory.length)) return 'Aldri fakturert';
		else {
			return invoiceHistory[0].invoiceData[0].sinceLastInvoiced;
		}
	}
	renderSubTable(testAdmin) {
		const olderData = [];
		let previouslyInvoiced;
		let obj = {};
		const invoiceHistory = testAdmin.invoiceHistory
			? testAdmin.invoiceHistory
			: [];
		if (!Boolean(invoiceHistory.length))
			return <p>Denne brukeren har aldri blitt fakturert</p>;
		else {
			for (let i = 0; i < invoiceHistory.length; i++) {
				for (let j = 0; j < invoiceHistory[i].invoiceData.length; j++) {
					obj = {
						invoicedOn: invoiceHistory[i].invoiceData[j].invoicedOn,
						sinceInvoiced:
							invoiceHistory[i].invoiceData[j].sinceLastInvoiced,
					};
				}
				olderData.push(obj);
			}
		}
		return (
			<div className="lit-table_wrapper">
				{Boolean(olderData.length) && (
					<table className="lit-list-user-list">
						<thead>
							<tr>
								<th className="lit-test__header">
									{'Sist fakturert på'}
								</th>

								<th className="lit-test__header">
									{'Fakturerte tester'}
								</th>

								<th className="lit-test__header">
									{'Ufakturerte tester '}
								</th>
							</tr>
						</thead>
						<tbody>
							{olderData.map((data, i, arr) => {
								let previousItem;
								if (i + 1 < arr.length) {
									previousItem =
										arr[i + 1].sinceInvoiced -
										arr[i].sinceInvoiced;
								} else {
									previousItem =
										testAdmin.totalTest -
										data.sinceInvoiced;
								}

								return (
									<tr className="lit-list__item" key={i}>
										<td className="lit-list__col large">
											{data.invoicedOn
												? moment(
														data.invoicedOn
												  ).format('LL')
												: 'ikke tilgjengellig'}
										</td>

										<td className="lit-list__col large">
											{previousItem}
										</td>

										<td className="lit-list__col large">
											{data.sinceInvoiced}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
		);
	}

	render() {
		const filteredTestAdmins = this.getFilteredTestAdmins();
		return (
			<div className="lit-tests-view__header">
				<div className="lit-admin-invoice__wrapper">
					<div className="lit_input-filters-wrapper-invoices">
						<div className="lit-dropdown_filter">
							<select
								className="lit-input__field"
								defaultValue={ALL}
								onChange={(e) => {
									this.setState({
										activeFilter: userStageFilters.filter(
											(filter) =>
												filter.id === e.target.value
										)[0],
									});
								}}
							>
								<option disabled>{strings.status}</option>
								{userStageFilters.map((userStageFilter) => (
									<option
										key={userStageFilter.id}
										value={userStageFilter.id}
									>
										{userStageFilter.label ||
											userStageFilter.text}
									</option>
								))}
							</select>
						</div>

						<input
							className="lit-tf_search"
							type="text"
							placeholder="Søk her... "
							id="testkey"
							onChange={(e) => {
								this.setState({
									searchFilter: e.target.value.toLowerCase(),
								});
							}}
						/>

						<div className="lit-testActivity_selectDate">
							{'Valgte dato: '}

							<DatePicker
								className="lit-input__field"
								minDate={moment().subtract(5, 'years').toDate()}
								maxDate={moment().subtract(0, 'years').toDate()}
								timeFormat="HH:mm:ss"
								dropdownMode="select"
								dateFormat="dd-MM-yyyy"
								name="from"
								onChange={(value) => {
									let formattedValue = moment(value)
										.utc()
										.startOf('day')
										.format('YYYY-MM-DD HH:mm:ss');

									this.setState(
										{
											selectedDate: value,
										} /* ,
											() => {
												this.getSummary();
											} */
									);
								}}
								placeholderText={strings.clickToSelectDate}
								required
								selected={this.state.selectedDate}
								adjustDateOnChange
								showYearDropdown
							/>
						</div>
					</div>

					{Boolean(this.props.testAdmins.length) && (
						<div className="lit_Invoices_table-wrapper">
							<div className="lit-main-invoice-contianer">
								<div className="lit_myClass-invoice-table-wrapper">
									{this.renderTableHeader()}
									<div className="lit-invoice-tableRows-scroll">
										{filteredTestAdmins.map(
											(data, index) => {
												return (
													<div
														key={index}
														className="lit-dynamic-users-tablerows"
													>
														{this.renderTableRows(
															data,
															index
														)}
													</div>
												);
											}
										)}
									</div>
								</div>
							</div>
						</div>
					)}
					{!Boolean(this.props.testAdmins.length) && (
						<div className="lit_Invoices_table-wrapper">
							<p>Under utvikling Invoices...</p>
						</div>
					)}
				</div>
			</div>
		);
	}
}
export default TestAdminInvoices;
