import React from 'react';
import './styles.scss';
import classNames from 'classnames';
import strings from 'utils/strings';
class AddressList extends React.Component {
	formatEmails(theEmails) {
		var txt = JSON.stringify(theEmails).replace(/,/g, '\n');
		return txt.replace(/]|[[]/g, '').replace(/"|""/g, '');
	}
	copyEmailsToClipboard = () => {
		const el = this.textArea;
		el.select();
		document.execCommand('copy');
		this.props.closeWindow();
	};

	render() {
		const theEmails = this.props.selectedEmails;
		return (
			<div
				className="lit-modal"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<div className="lit_copy_email-textarea">
						<h3>Valgt E-post</h3>
						<div>
							<textarea
								className="lit-main_textarea"
								ref={(textarea) => (this.textArea = textarea)}
								value={this.formatEmails(theEmails)}
							/>
						</div>
						<div className="lit_copy_email-btn">
							<button
								className={classNames('print-hide', {
									'lit-btn see-more': true,
									'bg-tertiary': true,
								})}
								onClick={() => this.copyEmailsToClipboard()}
							>
								{strings.copyemails}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default AddressList;
