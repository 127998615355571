export function objectToQueryString(obj) {
	if (Object.keys(obj).length === 0) {
		return '';
	}

	const queryString = Object.keys(obj)
		.map(
			(key) =>
				encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
		)
		.join('&');

	return '?' + queryString;
}
