import React from 'react';
import strings from 'utils/strings';

class ConfirmationModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="lit-modal" onClick={() => this.props.onCancel()}>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<h3>{strings.areyousure}</h3>
					<p>{strings.createnotificationwarning}</p>
					<div>
						<button
							className="lit-btn bg-primary"
							onClick={() => this.props.onConfirm()}
						>
							{strings.confirm}
						</button>

						<button
							className="lit-btn bg-negative"
							onClick={() => this.props.onCancel()}
						>
							{strings.cancel}
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default ConfirmationModal;
