import strings from 'utils/strings';
export const DEVELOPMENT = 'development';
export const PRODUCTION = 'production';
export const TESTING = 'testing';
const validEnvironments = [DEVELOPMENT, TESTING, PRODUCTION];

const environmentFromEnvVars = PRODUCTION;

export const ENVIRONMENT = validEnvironments.includes(environmentFromEnvVars)
	? environmentFromEnvVars
	: PRODUCTION; //Default to production, in case config is missing

if (!validEnvironments.includes(ENVIRONMENT)) {
	console.warn('MISSING ENVIRONMENT SELECTOR');
}

export const timeSinceWCaltered = 1686585143000;
const rootUrls = {
	development: '',
	testing: '',
	production: '',
};
export const ROOT_URL = rootUrls[ENVIRONMENT];

const endpointUrls = {
	development: 'http://localhost:3000',
	testing: 'http://lit-testing.eu-central-1.elasticbeanstalk.com',
	production: 'https://screeningtest.literate.no',
};

export const ENDPOINT_URL = endpointUrls[ENVIRONMENT];

const childtestUrls = {
	development: 'http://localhost:3003',
	testing:
		'http://lit-staging-childtest.s3-website.eu-central-1.amazonaws.com/',
	production: 'http://barnetest.literate.no',
};
export const CHILD_TEST_URL = childtestUrls[ENVIRONMENT];
export const USER_MESSAGE = true;
export const TEST_TYPES = {
	CHILD_SCREENING: 'child-screening',
	CHILD_EXPERIMENTAL: 'child-experimental',
	CHILD_FOLLOWUP: 'child-followup',
	CHILD_NORMING: 'child-norming',
	ADULT_SCREENING: 'adult-screening',
	ADULT_FOLLOWUP: 'adult-followup',
	YOUTH_SCREENING: 'youth-screening',
	YOUTH_FOLLOWUP: 'youth-followup',
};

export const TEST_TYPE_NAMES = {
	'child-screening': 'Screeningtest (Barn)',
	'child-followup': 'Oppfølgingstest (Barn)',
	'child-norming': 'Screeningtest (Barn)',
	'child-experimental': '1-min test (talegjenkjenning)',
	'adult-screening': strings.screeningtest_adult,
	'adult-followup': 'Oppfølgingstest (voksen)',
	'youth-screening': 'Screeningtest (ungdom)',
	'youth-followup': 'Oppfølgingstest (ungdom)',
};

export const ALL = {
	id: 'all',
	label: strings.all,
};

export const SEARCHBYTESTKEY = {
	id: 'search',
	label: strings.searchByTestKey,
};

export const FEIDEDROPDOWN = {
	id: 'feidesearch',
	label: strings.searchFeide,
};
export const VIEWMUNICIPALITY = {
	id: 'view',
	label: strings.viewMunicipality,
};
export const ADDMUNICIPALITY = {
	id: 'create',
	label: strings.addMunicipality,
};

export const CREATENOTIFICATION = {
	id: 'create',
	label: strings.createNotification,
};

export const VIEWNOTIFICATION = {
	id: 'view',
	label: strings.viewNotification,
};

export const STATUS = {
	id: '',
	label: strings.status,
};

export const SATUS = {
	id: 'all',
	label: strings.all,
};

export const LOGGED_IN = {
	id: 'logged_in',
	label: strings.loggedIn,
};
export const BLOCKED = {
	id: 'blocked',
	label: strings.blocked,
};

export const TEST_ACTIVITY = {
	id: 'test',
	label: 'test hendelser',
};
export const USER_ACTIVITY = {
	id: 'user',
	label: 'user hendelser',
};
export const SUPER_ACTIVITY = {
	id: 'super',
	label: 'super hendelser',
};

export const NOT_STARTED = {
	id: 'not_started',
	label: strings.notstarted,
};

export const NOT_DISTRIBUTED = {
	id: 'not_distributed',
	label: strings.notdistributed,
};

export const NOT_INVOICED = {
	id: 'not_invoiced',
	label: 'Ikke fakturert',
};
export const INVOICED = {
	id: 'invoiced',
	label: 'Fakturert',
};
export const DISTRIBUTED = {
	id: 'distributed',
	label: strings.distributed,
};

export const STARTED = {
	id: 'started',
	label: strings.started,
};

export const COMPLETED = {
	id: 'completed',
	label: strings.completed,
};

export const MALE = {
	id: 'm',
	label: strings.male,
};

export const FEMALE = {
	id: 'f',
	label: strings.female,
};
export const YOUNG_MALE = {
	id: 'm',
	label: strings.boy,
};

export const YOUNG_FEMALE = {
	id: 'f',
	label: strings.girl,
};

export const GENDER_LABELS = {
	f: strings.female,
	m: strings.male,
};

export const TEST_USER_INFO_LABELS = {
	juniorHigh: 'ungdomsskole',
	highSchool: 'videregående skole',
	bachelor: 'bachelor',
	master: 'mastergrad/hovedfag',
	phd: 'doktorgrad',
	youthProgram: 'Ungdomsprogram',
	accompanyingMeasures: 'Oppfølgingstiltak',
	workPreparatoryTraining: 'Arbeidsforberedende trening',
	norwegian: 'norsk',
	sapmi: 'samisk',
	russian: 'russisk',
	english: 'engelsk',
	arabic: 'arabisk',
	polish: 'polsk',
	kurdish: 'kurdisk',
	spanish: 'spansk',
	tamil: 'tamil',
	thai: 'thai',
	turkish: 'tyrkisk',
	other: 'annet',
	yes: 'ja',
	no: 'nei',
	doesNotKnow: 'vet ikke',
};

export const BANK_ID = 'BankID';
export const BANK_ID_MOBILE = 'BankIDMobile';
export const FEIDE = 'feide';

export const TEST_PRICE = 50;
export const SUBSCRIPTION_PRICE = 0;
export const CURRENCY_SYMBOL = strings.currencysymbol;

export const Z_SCORE_OFFSET = 4;
export const Z_SCORE_MAX = 8;

export const MINIMUM_AGE = 0;
export const MAXIMUM_AGE = 100;

const ORDDIKTAT = 'ORDDIKTAT';
const ORDKJEDETESTEN = 'ORDKJEDETESTEN';
const TULLEORDTESTEN = 'TULLEORDTESTEN';
const BYTTELYDTESTEN = 'BYTTELYDTESTEN';
const SKRIVEHASTIGHET = 'SKRIVEHASTIGHET';
const LESEPRØVEN = 'LESEPRØVEN';
const ORDFORSTÅELSE = 'ORDFORSTÅELSE';
const WORKING_MEMORY = 'WORKING_MEMORY';
const WORKING_MEMORY_NUMBERS = 'WORKING_MEMORY_NUMBERS';
const WORKING_MEMORY_WORDS = 'WORKING_MEMORY_WORDS';
const RAPID_NOMINATION = 'RAPID_NOMINATION';
const MINUTE = 'MINUTE';
const SPOONERISM = 'SPOONERISM';
const LETTER_KNOWLEDGE = 'LETTER_KNOWLEDGE';
const PHONEME_ISOLATION = 'PHONEME_ISOLATION';
const PHONEME_DELETION = 'PHONEME_DELETION';

export const TEST_STEPS = {
	ORDDIKTAT,
	ORDKJEDETESTEN,
	TULLEORDTESTEN,
	SKRIVEHASTIGHET,
	LESEPRØVEN,
	ORDFORSTÅELSE,
};

export const TEST_STEP_IDS = {
	[ORDDIKTAT]: 1,
	[ORDKJEDETESTEN]: 2,
	[TULLEORDTESTEN]: 3,
	[SKRIVEHASTIGHET]: 4,
	[LESEPRØVEN]: 5,
	[ORDFORSTÅELSE]: 6,
};

export const TEST_STEPS_BY_ID = {
	1: {
		name: ORDDIKTAT,
		questionCount: 45,
		label: 'Orddiktat',
		reportHeading: strings.orddiktatheading,
		reportExplaination: strings.orddiktatadultexplanation,
		reportVariableText: strings.orddiktatvariabletext,
		norms: {
			default: {average: 38.38, standardDeviation: 4.54},
		},
	},
	2: {
		name: ORDKJEDETESTEN,
		questionCount: 56,
		label: 'Ordkjedetesten',
		reportHeading: strings.ordkjedetestenheading,
		reportExplaination: strings.ordkjedetestenexplanation,
		reportVariableText: strings.ordkjedetestenvariabletext,
		norms: {
			default: {average: 15.38, standardDeviation: 5.1},
		},
	},
	3: {
		name: TULLEORDTESTEN,
		questionCount: 25,
		label: 'Tulleordtesten',
		reportHeading: strings.tulleordtestenheading,
		reportExplaination: strings.tulleordtestenexplanation,
		reportVariableText: strings.tulleordtestenvariabletext,
		norms: {
			default: {average: 13.87, standardDeviation: 5.07},
		},
	},
	4: {
		name: SKRIVEHASTIGHET,
		questionCount: 0,
		label: 'Skrivehastighet',
		reportHeading: strings.skrivehastighetheading,
		reportExplaination: strings.skrivehastighetexplanation,
		reportVariableText: strings.skrivehastighetvariabletext,
		norms: {
			default: {average: 20.28, standardDeviation: 3.76},
		},
	},
	5: {
		name: LESEPRØVEN,
		questionCount: 14,
		label: 'Leseprøven',
		reportHeading: strings.leseprøvenheading,
		reportExplaination: strings.leseprøvenexplanation,
		reportVariableText: strings.leseprøvenvariabletext,
		norms: {
			default: {average: 9.21, standardDeviation: 2.68},
		},
	},
	6: {
		name: ORDFORSTÅELSE,
		questionCount: 15,
		label: 'Ordforståelse',
		reportHeading: strings.ordforståelseheading,
		reportExplaination: strings.ordforståelseexplanation,
		reportVariableText: strings.ordforståelsevariabletext,
		norms: {
			default: {average: 10.55, standardDeviation: 2.56},
		},
	},
};
export const YOUTH_TEST_STEP_IDS = {
	[ORDDIKTAT]: 1,
	[ORDKJEDETESTEN]: 2,
	[TULLEORDTESTEN]: 3,
	[SKRIVEHASTIGHET]: 4,
	[LESEPRØVEN]: 5,
	[ORDFORSTÅELSE]: 6,
};

export const YOUTH_TEST_STEPS_BY_ID = {
	1: {
		name: ORDDIKTAT,
		questionCount: 30,
		label: 'Orddiktat',
		reportHeading: strings.orddiktatheading,
		reportExplaination: strings.orddiktatyouthexplanation,
		reportVariableText: strings.orddiktatvariabletext,
		norms: {
			8: {average: 17.41, standardDeviation: 5.8},
			9: {average: 20.59, standardDeviation: 5.11},
			10: {average: 21.63, standardDeviation: 4.56},
		},
	},
	2: {
		name: ORDKJEDETESTEN,
		questionCount: 36,
		label: 'Ordkjedetesten',
		reportHeading: strings.ordkjedetestenheading,
		reportExplaination: strings.ordkjedetestenyouthexplanation,
		reportVariableText: strings.ordkjedetestenvariabletext,
		norms: {
			8: {average: 11.44, standardDeviation: 4.86},
			9: {average: 15.07, standardDeviation: 5.01},
			10: {average: 16.61, standardDeviation: 5.27},
		},
	},
	3: {
		name: TULLEORDTESTEN,
		questionCount: 25,
		label: 'Tulleordtesten',
		reportHeading: strings.tulleordtestenheading,
		reportExplaination: strings.tulleordtestenexplanation,
		reportVariableText: strings.tulleordtestenvariabletext,
		norms: {
			8: {average: 8.4, standardDeviation: 3.2},
			9: {average: 9.25, standardDeviation: 3.59},
			10: {average: 9.77, standardDeviation: 3.3},
		},
	},
	4: {
		name: SKRIVEHASTIGHET,
		questionCount: 0,
		label: 'Skrivehastighet',
		reportHeading: strings.skrivehastighetheading,
		reportExplaination: strings.skrivehastighetexplanation,
		reportVariableText: strings.skrivehastighetvariabletext,
		norms: {
			8: {average: 18.02, standardDeviation: 6.12},
			9: {average: 23.64, standardDeviation: 5.79},
			10: {average: 27.23, standardDeviation: 6.93},
		},
	},
	5: {
		name: LESEPRØVEN,
		questionCount: 9,
		label: 'Leseprøven',
		reportHeading: strings.leseprøvenyouthheading,
		reportExplaination: strings.leseprøvenyouthexplanation,
		reportVariableText: strings.leseprøvenvariabletext,
		norms: {
			8: {average: 3.78, standardDeviation: 2.34},
			9: {average: 4.42, standardDeviation: 1.99},
			10: {average: 5.04, standardDeviation: 1.86},
		},
	},
	6: {
		name: ORDFORSTÅELSE,
		questionCount: 20,
		label: 'Ordforståelse',
		reportHeading: strings.ordforståelseheading,
		reportExplaination: strings.ordforståelseyouthexplanation,
		reportVariableText: strings.ordforståelsevariabletext,
		norms: {
			8: {average: 13.25, standardDeviation: 2.73},
			9: {average: 14.04, standardDeviation: 2.89},
			10: {average: 14.87, standardDeviation: 2.41},
		},
	},
};

export const CHILD_SCRNG_TEST_STEP_IDS = {
	[ORDDIKTAT]: 1,
	[ORDKJEDETESTEN]: 2,
	[BYTTELYDTESTEN]: 3,
	[SKRIVEHASTIGHET]: 4,
	[LESEPRØVEN]: 5,
	[ORDFORSTÅELSE]: 6,
};

export const CHILD_SCRNG_TEST_STEPS_BY_ID = {
	1: {
		name: ORDDIKTAT,
		questionCount: 25,
		label: 'Orddiktat',
		reportHeading: strings.orddiktatheading,
		reportExplaination: strings.orddiktatChildscrexplanation,
		reportVariableText: strings.orddiktatvariabletext,
		norms: {
			2: {average: 14.28, standardDeviation: 4.28},
			3: {average: 18.01, standardDeviation: 3.89},
			4: {average: 21.12, standardDeviation: 2.84},
			5: {average: 14.86, standardDeviation: 4.67},
			6: {average: 15.92, standardDeviation: 4.76},
			7: {average: 17.63, standardDeviation: 3.77},
		},
	},
	2: {
		name: ORDKJEDETESTEN,
		questionCount: 28,
		label: 'Ordkjedetesten',
		reportHeading: strings.ordkjedetestenheading,
		reportExplaination: strings.ordkjedetestenyouthexplanation,
		reportVariableText: strings.ordkjedetestenvariabletext,
		norms: {
			2: {average: 3.04, standardDeviation: 2.11},
			3: {average: 6.5, standardDeviation: 3.49},
			4: {average: 9.08, standardDeviation: 3.43},
			5: {average: 13.62, standardDeviation: 5.51},
			6: {average: 14.73, standardDeviation: 5.27},
			7: {average: 18.14, standardDeviation: 5.23},
		},
	},
	3: {
		name: BYTTELYDTESTEN,
		questionCount: 21,
		label: 'Byttelydtesten',
		reportHeading: strings.byttelydtestheading,
		reportExplaination: strings.byttelydtestexplanation,
		reportVariableText: strings.byttelydtestvariabletext,
		norms: {
			2: {average: 11.94, standardDeviation: 4.14},
			3: {average: 14.26, standardDeviation: 4.3},
			4: {average: 16.32, standardDeviation: 3.34},
			5: {average: 18.98, standardDeviation: 1.66},
			6: {average: 19.08, standardDeviation: 1.52},
			7: {average: 19.81, standardDeviation: 1.16},
		},
	},
	4: {
		name: SKRIVEHASTIGHET,
		questionCount: 0,
		label: 'Skrivehastighet',
		reportHeading: strings.skrivehastighetheading,
		reportExplaination: strings.skrivehastighetexplanation,
		reportVariableText: strings.skrivehastighetvariabletext,
		norms: {
			2: {average: 3.04, standardDeviation: 2.11},
			3: {average: 5.02, standardDeviation: 3.3},
			4: {average: 9.55, standardDeviation: 4.06},
			5: {average: 13.62, standardDeviation: 4.67},
			6: {average: 16.71, standardDeviation: 5.82},
			7: {average: 21.04, standardDeviation: 6.1},
		},
	},
	5: {
		name: LESEPRØVEN,
		questionCount: 8,
		label: 'Leseprøven',
		reportHeading: strings.leseprøvenchildheading,
		reportExplaination: strings.leseprøvenchildexplanation,
		reportVariableText: strings.leseprøvenvariabletext,
		norms: {
			2: {average: 4.48, standardDeviation: 2.12},
			3: {average: 5.84, standardDeviation: 2.0},
			4: {average: 6.71, standardDeviation: 1.7},
			5: {average: 6.97, standardDeviation: 0.84},
			6: {average: 6.73, standardDeviation: 1.35},
			7: {average: 7.5, standardDeviation: 0.66},
		},
	},
	6: {
		name: ORDFORSTÅELSE,
		questionCount: 18,
		label: 'Ordforståelse',
		reportHeading: strings.ordforståelseheading,
		reportExplaination: strings.ordforståelsechildexplanation,
		reportVariableText: strings.ordforståelsevariabletext,
		norms: {
			2: {average: 8.42, standardDeviation: 3.78},
			3: {average: 11.02, standardDeviation: 3.22},
			4: {average: 12.86, standardDeviation: 2.82},
			5: {average: 13.21, standardDeviation: 2.92},
			6: {average: 13.86, standardDeviation: 2.24},
			7: {average: 14.7, standardDeviation: 2.08},
		},
	},
};

export const CHILD_TEST_STEPS = {
	WORKING_MEMORY,
	WORKING_MEMORY_NUMBERS,
	WORKING_MEMORY_WORDS,
	RAPID_NOMINATION,
	MINUTE,
	SPOONERISM,
	LETTER_KNOWLEDGE,
	PHONEME_ISOLATION,
	PHONEME_DELETION,
	ORDDIKTAT,
};

export const CHILD_TEST_STEPS_BY_ID = {
	0: {
		name: WORKING_MEMORY,
		questionCount: 24,
		showTotalCorrect: false,
		label: 'Arbeidsminne',
		reportHeading: strings.arbeidsminneheading,
		reportExplaination: strings.arbeidsminneexplanation,
		reportVariableText: strings.arbeidsminnevariabletext,
		norms: {
			0: {average: 3.79, standardDeviation: 2.84},
			1: {average: 6.37, standardDeviation: 1.87},
			2: {average: 6.95, standardDeviation: 1.63},
			3: {average: 7.23, standardDeviation: 2.15},
			4: {average: 8.24, standardDeviation: 2.14},
			5: {average: 8.62, standardDeviation: 2.09},
			6: {average: 9.08, standardDeviation: 2.45},
			7: {average: 9.25, standardDeviation: 2.27},
			8: {average: 10.5, standardDeviation: 2.67},
			9: {average: 10.5, standardDeviation: 2.67},
			10: {average: 10.5, standardDeviation: 2.67},
			adult: {average: 10.4, standardDeviation: 2.57},
		},
	},
	1: {
		name: WORKING_MEMORY_NUMBERS,
		questionCount: 12,
		showTotalCorrect: false,
		label: 'Arbeidsminne (Tall)',
		reportHeading: strings.arbeidminneheading,
		reportHeading: strings.arbeidsminnenummberheading,
		norms: {
			0: {average: 3.79, standardDeviation: 2.84},
			1: {average: 6.37, standardDeviation: 1.87},
			2: {average: 6.95, standardDeviation: 1.63},
			3: {average: 7.23, standardDeviation: 2.15},
			4: {average: 8.24, standardDeviation: 2.14},
			5: {average: 8.62, standardDeviation: 2.09},
			6: {average: 9.08, standardDeviation: 2.45},
			7: {average: 9.25, standardDeviation: 2.27},
			8: {average: 10.5, standardDeviation: 2.67},
			9: {average: 10.5, standardDeviation: 2.67},
			10: {average: 10.5, standardDeviation: 2.67},
			adult: {average: 10.4, standardDeviation: 2.57},
		},
	},
	2: {
		name: WORKING_MEMORY_WORDS,
		showTotalCorrect: false,
		questionCount: 12,
		label: 'Arbeidsminne (Ord)',
		reportHeading: strings.arbeidsminnenummberheading,
		norms: {
			0: {average: 3.79, standardDeviation: 2.84},
			1: {average: 6.37, standardDeviation: 1.87},
			2: {average: 6.95, standardDeviation: 1.63},
			3: {average: 7.23, standardDeviation: 2.15},
			4: {average: 8.24, standardDeviation: 2.14},
			5: {average: 8.62, standardDeviation: 2.09},
			6: {average: 9.08, standardDeviation: 2.45},
			7: {average: 9.25, standardDeviation: 2.27},
			8: {average: 10.5, standardDeviation: 2.67},
			9: {average: 10.5, standardDeviation: 2.67},
			10: {average: 10.5, standardDeviation: 2.67},
			adult: {average: 10.4, standardDeviation: 2.57},
		},
	},
	3: {
		name: RAPID_NOMINATION,
		showTotalCorrect: false,
		questionCount: 50,
		label: 'Hurtigbenevning',
		reportHeading: strings.hurtigbenevningheading,
		reportExplaination: strings.hurtigbenevningexplanation,
		reportBHexplaination: strings.hurtigbenevningBHexplanation,
		reportVariableText: strings.hurtigbenevningvariabletext,
		norms: {
			0: {average: 0.22, standardDeviation: 0.07},
			1: {average: 0.3, standardDeviation: 0.075},
			2: {average: 0.34, standardDeviation: 0.82},
			3: {average: 0.48, standardDeviation: 0.8},
			4: {average: 0.49, standardDeviation: 0.9},
			5: {average: 0.55, standardDeviation: 0.11},
			6: {average: 0.59, standardDeviation: 0.12},
			7: {average: 0.59, standardDeviation: 0.12},
			8: {average: 0.69, standardDeviation: 0.12},
			9: {average: 0.69, standardDeviation: 0.12},
			10: {average: 0.69, standardDeviation: 0.12},
			adult: {average: 0.79, standardDeviation: 0.11},
		},
	},
	4: {
		name: MINUTE,
		showTotalCorrect: false,
		questionCount: 119,
		label: 'Ettminuttstest',
		reportHeading: strings.ettminuttstestheading,
		reportExplaination: strings.ettminuttstestexplanation,
		reportVariableText: strings.ettminuttstestvariabletext,
		norms: {
			1: {average: 17.07, standardDeviation: 11.58},
			2: {average: 37.99, standardDeviation: 17.81},
			3: {average: 52.68, standardDeviation: 18.96},
			4: {average: 58.36, standardDeviation: 14.86},
			5: {average: 69.12, standardDeviation: 15.2},
			6: {average: 74.18, standardDeviation: 14.36},
			7: {average: 77.17, standardDeviation: 13.69},
			8: {average: 85.75, standardDeviation: 10.33},
			9: {average: 85.75, standardDeviation: 10.33},
			10: {average: 85.75, standardDeviation: 10.33},
			adult: {average: 94.38, standardDeviation: 9.17},
		},
	},
	5: {
		name: SPOONERISM,
		showTotalCorrect: true,
		questionCount: 20,
		label: 'Spoonerism',
		reportHeading: strings.spoonerismheading,
		reportExplaination: strings.spoonerismexplanation,
		reportVariableText: strings.spoonerismvariabletext,
		norms: {
			8: {average: 0.12, standardDeviation: 0.06},
			9: {average: 0.12, standardDeviation: 0.06},
			10: {average: 0.12, standardDeviation: 0.06},
			adult: {average: 0.147, standardDeviation: 0.06},
		},
	},
	6: {
		name: LETTER_KNOWLEDGE,
		showTotalCorrect: true,
		questionCount: 30,
		label: 'Bokstavkunnskap',
		reportHeading: strings.bokstavkunnskapheading,
		reportExplaination: strings.bokstavkunnskapexplanation,
		reportVariableText: strings.bokstavkunnskapvariabletext,
		reportVariableTextBH: strings.bokstavkunnskapvariabletextBH,
		norms: {
			1: {average: 22.86, standardDeviation: 7.65},
			2: {average: 11.37, standardDeviation: 1.12},
		},
	},
	7: {
		name: PHONEME_ISOLATION,
		showTotalCorrect: true,
		questionCount: 16,
		label: 'Fonemisolasjon',
		reportHeading: strings.fonemisolasjonheading,
		reportExplaination: strings.fonemisolasjonexplanation,
		reportVariableText: strings.fonemisolasjonvariabletext,
		norms: {
			0: {average: 8.16, standardDeviation: 4.01},
			1: {average: 12.52, standardDeviation: 2.52},
			2: {average: 6.34, standardDeviation: 1.03},
		},
	},
	8: {
		name: PHONEME_DELETION,
		showTotalCorrect: true,
		questionCount: 16,
		label: 'Fonemutelatelse',
		reportHeading: strings.fonemutelatelseheading,
		reportExplaination: strings.fonemutelatelseexplanation,
		reportVariableText: strings.fonemutelatelsevariabletext,
		norms: {
			2: {average: 10.53, standardDeviation: 4.28},
			3: {average: 12.22, standardDeviation: 3.46},
			4: {average: 12.86, standardDeviation: 2.98},
			5: {average: 14.02, standardDeviation: 2.26},
			6: {average: 14.45, standardDeviation: 2.16},
			7: {average: 14.96, standardDeviation: 1.54},
		},
	},
	9: {
		name: ORDDIKTAT,
		showTotalCorrect: true,
		questionCount: 13,
		label: 'Orddiktat',
		reportHeading: strings.orddiktatheading,
		reportExplaination: strings.orddiktatchildexplanation,
		reportVariableText: strings.orddiktatvariabletext,
		norms: {
			3: {average: 6.81, standardDeviation: 2.19},
			4: {average: 7.4, standardDeviation: 3.23},
			5: {average: 9.07, standardDeviation: 2.6},
			6: {average: 9.4, standardDeviation: 3.06},
			7: {average: 9.81, standardDeviation: 2.22},
		},
	},
	12: {
		name: MINUTE,
		showTotalCorrect: false,
		questionCount: 120,
		label: 'Ettminuttstest',
		reportHeading: strings.ettminuttstestheading,
		reportExplaination: strings.ettminuttstestexplanation,
		reportVariableText: strings.ettminuttstestvariabletext,
		norms: {
			1: {average: 17.07, standardDeviation: 11.58},
			2: {average: 37.99, standardDeviation: 17.81},
			3: {average: 52.68, standardDeviation: 18.96},
			4: {average: 58.36, standardDeviation: 14.86},
			5: {average: 69.12, standardDeviation: 15.2},
			6: {average: 74.18, standardDeviation: 14.36},
			7: {average: 77.17, standardDeviation: 13.69},
			8: {average: 85.75, standardDeviation: 10.336},
			9: {average: 85.75, standardDeviation: 10.336},
			10: {average: 85.75, standardDeviation: 10.336},
		},
	},
};

// User stages
export const TRIAL_TEST_LIMIT = 2;

export const NEEDS_APPROVAL = 'NEEDS_APPROVAL';
export const AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION';
export const TRIAL = 'TRIAL';
export const EXPIRED_TRIAL = 'EXPIRED_TRIAL';
export const BASIC = 'BASIC';
export const FULL = 'FULL';

export const ADULT = 'ADULT';
export const CHILD = 'CHILD';
export const YOUTH = 'YOUTH';
export const INACTIVE = 'INACTIVE';
export const ACTIVE = 'ACTIVE';
export const NORMING = 'NORMING';
export const PRODUKSJON = 'PRODUKSJON';
export const PROD_NORMING = 'PROD_NORMING';
export const CHILD_YOUTH = 'CHILD_YOUTH';
export const CHILD_ADULT = 'CHILD_ADULD';
export const YOUTH_ADULT = 'YOUTH_ADULT';
export const CHILD_YOUTH_ADULT = 'CHILD_YOUTH_ADULT';

export const svensk = {
	id: 2,
	language: 'swedish',
	lang: 'Svensk',
	active: false,
	hideText: 'Svensk ▲',
	revealText: 'Svensk ▼',
	default: false,
	labelId: null,
};

export const norsk = {
	id: 1,
	language: 'norwegian',
	lang: 'Norsk',
	active: false,
	hideText: 'Norsk ▲',
	revealText: 'Norsk ▼',
	default: false,
	labelId: null,
};
export const english = {
	id: 3,
	language: 'english',
	lang: 'Engelsk',
	active: false,
	hideText: 'Engelsk ▲',
	revealText: 'Engelsk ▼',
	default: false,
	labelId: null,
};

export const dutch = {
	id: 4,
	language: 'dutch',
	lang: 'Dutch',
	active: false,
	hideText: 'Dutch ▲',
	revealText: 'Dutch ▼',
	default: false,
	labelId: null,
};

export const german = {
	id: 5,
	language: 'german',
	lang: 'Tysk',
	active: false,
	hideText: 'Tysk ▲',
	revealText: 'Tysk ▼',
	default: false,
	labelId: null,
};

export const language = [norsk, svensk, english, dutch, german];

export const ENGLISH = 'Engelsk';
export const SWEDISH = 'Svensk';
export const NORWEGIAN = 'Norsk';
export const DUTCH = 'Dutch';
export const GERMAN = 'Tysk';

export const USER_SUBSCRIPTIONS_INFO = {
	[ENGLISH]: {
		subscriptionUpdate: {
			language: 'english',
			child: 0,
			youth: 0,
			adult: 0,
			child_followup: 0,
			youth_followup: 0,
			adult_followup: 0,
			norming_child: 0,
			norming_youth: 0,
			norming_adult: 0,
			basic: 0,
		},
	},
	[SWEDISH]: {
		subscriptionUpdate: {
			language: 'swedish',
			child: 0,
			youth: 0,
			adult: 0,
			child_followup: 0,
			youth_followup: 0,
			adult_followup: 0,
			norming_child: 0,
			norming_youth: 0,
			norming_adult: 0,
			basic: 0,
		},
	},
	[NORWEGIAN]: {
		subscriptionUpdate: {
			language: 'norwegian',
			child: 0,
			youth: 0,
			adult: 0,
			child_followup: 0,
			youth_followup: 0,
			adult_followup: 0,
			norming_child: 0,
			norming_youth: 0,
			norming_adult: 0,
			basic: 0,
		},
	},
	[DUTCH]: {
		subscriptionUpdate: {
			language: 'dutch',
			child: 0,
			youth: 0,
			adult: 0,
			child_followup: 0,
			youth_followup: 0,
			adult_followup: 0,
			norming_child: 0,
			norming_youth: 0,
			norming_adult: 0,
			basic: 0,
		},
	},
	[GERMAN]: {
		subscriptionUpdate: {
			language: 'german',
			child: 0,
			youth: 0,
			adult: 0,
			child_followup: 0,
			youth_followup: 0,
			adult_followup: 0,
			norming_child: 0,
			norming_youth: 0,
			norming_adult: 0,
			basic: 0,
		},
	},
};

export const USER_STAGES_INFO = {
	[NEEDS_APPROVAL]: {
		id: NEEDS_APPROVAL,
		className: 'needs-approval',
		longText: 'Må godkjennes av administrator',
		text: 'Trenger godkjenning',
	},
	[AWAITING_CONFIRMATION]: {
		id: AWAITING_CONFIRMATION,
		className: 'awaiting',
		longText: 'Bruker er godkjent og e-post er sendt med invitasjon',
		text: 'Ikke fullført registrering',
	},
	[TRIAL]: {
		id: TRIAL,
		className: 'trial',
		longText: 'Har kun tilgang til totalt 8 tester',
		text: 'Prøveperiode',
	},
	[BASIC]: {
		id: BASIC,
		className: 'basic',
		longText: 'Har kun tilgang til å se testresultater',
		text: 'Basis abonnement',
		subscriptionUpdates: {
			active: true,
			full: false,
			trial: false,
			child: false,
			youth: false,
			adult: false,
			norming: false,
		},
	},
	[ACTIVE]: {
		id: ACTIVE,
		className: 'active',
		longText: 'Har tilgang til å lage nye tester og se testresultater',
		text: 'Active abonnement',
		subscriptionUpdates: {
			active: true,
			full: false,
			trial: false,
			child: false,
			youth: false,
			adult: false,
			norming: false,
		},
	},
	[NORMING]: {
		id: NORMING,
		className: 'active',
		longText:
			'Har tilgang til å lage normering tester og se testresultater',
		text: 'Normering',
		subscriptionUpdates: {
			active: true,
			full: false,
			trial: false,
			child: false,
			youth: false,
			adult: false,
			norming: false,
		},
	},
	[PRODUKSJON]: {
		id: PRODUKSJON,
		className: 'active',
		longText: 'Har tilgang til å lage tester og se testresultater',
		text: 'Abonnement',
		subscriptionUpdates: {
			active: true,
			full: false,
			trial: false,
			child: false,
			youth: false,
			adult: false,
			norming: false,
		},
	},
	[PROD_NORMING]: {
		id: PROD_NORMING,
		className: 'active',
		longText: 'Har full tilgang til å lage nye tester og se testresultater',
		text: 'Fullt abonnement',
		subscriptionUpdates: {
			active: true,
			full: false,
			trial: false,
			child: false,
			youth: false,
			adult: false,
			norming: false,
		},
	},
	[INACTIVE]: {
		id: INACTIVE,
		className: 'inactive',
		longText:
			'Har ikke tilgang til å lage nye tester eller se testresultater',
		text: 'Inaktiv',
		subscriptionUpdates: {
			active: false,
			full: false,
			trial: false,
			child: false,
			youth: false,
			adult: false,
			norming: false,
		},
	},
};

export const controlPanel = '/control-panel';
export const notifications = '/notifications';
