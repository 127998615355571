/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Chart from 'chart.js';

import {
	getChartData,
	getChildChartData,
	getChildScrChartData,
} from 'utils/getChartData';

import './styles.scss';
const neutralBackgroundAreaColor = `rgb(241, 224, 167)`;

class HorizontalBarChart extends React.Component {
	constructor() {
		super();

		this.state = {
			showTestUserInfo: false,
			testResult: null,
		};
	}

	componentDidMount() {
		const {subtests, isChild, testType} = this.props;

		const chartData = isChild
			? getChildChartData(subtests, false)
			: testType === 'child-screening'
			? getChildScrChartData(subtests, false)
			: getChartData(subtests, false);
		const printChartData = isChild
			? getChildChartData(subtests, true)
			: testType === 'child-screening'
			? getChildScrChartData(subtests, false)
			: getChartData(subtests, true);

		const el = ReactDOM.findDOMNode(this.visible);
		const el2 = ReactDOM.findDOMNode(this.visible2);
		const ctx = el.getContext('2d');
		const ctx2 = el2.getContext('2d');

		const cent = 12;
		const hcent = 6;
		ctx2.beginPath();
		ctx2.fillStyle = neutralBackgroundAreaColor;
		ctx2.rect(38 * cent, hcent, 24.5 * cent, 94 * hcent);
		ctx2.fill();

		this.chart = this.renderChart(ctx, chartData);

		const printElem = ReactDOM.findDOMNode(this.printable);
		const printCtx = printElem.getContext('2d');
		this.printChart = this.renderChart(printCtx, printChartData);
	}

	componentWillUnmount() {
		if (this.chart) {
			this.chart.destroy();
		}
		if (this.printChart) {
			this.printChart.destroy();
		}
	}

	renderChart(ctx, data) {
		const chart = new Chart(ctx, {
			type: 'horizontalBar',
			data,
			options: {
				scales: {
					xAxes: [
						{
							ticks: {
								max: 4,
								min: -4,
							},
						},
					],
				},
				legend: {
					labels: {
						filter: (legendItem) => {
							if (legendItem.text !== 'Z-skåre') {
								return legendItem;
							}

							return null;
						},
					},
				},
				tooltips: {
					yAlign: 'bottom',
				},
			},
		});

		return chart;
	}
	render() {
		return (
			<div style={{position: 'relative'}}>
				<canvas
					className="non-printable"
					ref={(node) => (this.visible = node)}
				/>
				<canvas
					className="printable"
					ref={(node) => (this.printable = node)}
				/>
				<canvas
					width="1200"
					height="600"
					className="non-printable detail"
					ref={(node) => (this.visible2 = node)}
				/>
			</div>
		);
	}
}

HorizontalBarChart.defaultProps = {
	isChild: false,
};

HorizontalBarChart.propTypes = {
	isChild: PropTypes.bool,
	subtests: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default HorizontalBarChart;
