import React from 'react';
import strings from 'utils/strings';

import {TEST_TYPES, TEST_TYPE_NAMES} from 'constants/index';
const testsWithInstruction = ['child-screening'];
class TestDispatchModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isCreatingTests: false,
			groupId: '',
			hasConfirmedCost: false,
			childSelfStarting: false,
			infoCandidateAdmin: true,
			newTestCount: 1,
			type: '',
			availableTestTypes: [],
			isAddingGroup: false,
			availableLanguage: [],
			selectedLanguage: null,
		};
		this.setAvailableTypes = this.setAvailableTypes.bind(this);
	}
	componentDidMount() {
		const userLanguages = this.props.userLanguages[0];
		const tempObj = [];
		let initialTestType = null;
		const searchAbaleLanguageFields = [
			'norwegian',
			'swedish',
			'english',
			'dutch',
			'german',
		];
		const searchAbaleSubscriptionFields = [
			'child',
			'youth',
			'adult',
			'norming_youth',
			'norming_child',
			'child_followup',
			'adult_followup',
			'youth_followup',
		];
		for (const field of searchAbaleLanguageFields) {
			if (userLanguages.hasOwnProperty(field)) {
				for (const subType of searchAbaleSubscriptionFields) {
					if (userLanguages[field][subType] === 1) {
						if (initialTestType === null) {
							initialTestType = userLanguages[field];
						}
						tempObj.push(userLanguages[field].language);
						break;
					}
				}
			}
		}
		this.setAvailableTypes(initialTestType);
		this.setState({
			availableLanguage: tempObj,
		});
	}
	setAvailableTypes(availableLanguages) {
		const availableTests = [];
		const searchAbaleSubscriptionFields = [
			'adult',
			'youth',
			'norming_youth',
			'norming_child',
			'child',
			'youth_followup',
			'child_followup',
			'adult_followup',
		];
		for (const field of searchAbaleSubscriptionFields) {
			if (availableLanguages[field] === 1) {
				if (field === 'norming_youth' || field === 'youth') {
					availableTests.push(TEST_TYPES.YOUTH_SCREENING);
				}

				if (field === 'adult') {
					availableTests.push(TEST_TYPES.ADULT_SCREENING);
				}
				if (field === 'youth_followup') {
					availableTests.push(TEST_TYPES.YOUTH_FOLLOWUP);
				}
				if (field === 'adult_followup') {
					availableTests.push(TEST_TYPES.ADULT_FOLLOWUP);
				}
				if (field === 'child_followup') {
					availableTests.push(TEST_TYPES.CHILD_FOLLOWUP);
				}

				if (field === 'child') {
					availableTests.push(
						TEST_TYPES.CHILD_SCREENING,
						TEST_TYPES.CHILD_EXPERIMENTAL
					);
				}
			}
		}

		this.setState({
			availableTestTypes: availableTests,
		});
	}
	determineBulkTestKeys(selectedType, currentTestUser, filteredTestResults) {
		for (let i = 0; i < filteredTestResults.length; i++) {
			const testsResults = filteredTestResults[i].testResults;
			for (let j = 0; j < testsResults.length; j++) {
				if (testsResults[j].testUser) {
					if (
						testsResults[j].testUser.testUserId ===
							currentTestUser.id &&
						testsResults[j].info
					) {
						if (testsResults[j].type === 'adult-screening') {
							const information = JSON.parse(
								testsResults[j].info
							);

							return {
								type: selectedType, //testsResults[j].type,
								email:
									information.email ||
									currentTestUser.email ||
									null,
								id: testsResults[j].testUser.testUserId,
							}; /* 'adult-screening'; */
						} else if (testsResults[j].type === 'youth-screening') {
							const information = JSON.parse(
								testsResults[j].info
							);
							return {
								type: selectedType, //testsResults[j].type,
								email:
									information.email ||
									currentTestUser.email ||
									null,
								id: testsResults[j].testUser.testUserId,
							}; /* 'youth-screening'; */
						} else if (testsResults[j].type === 'child') {
							const information = JSON.parse(
								testsResults[j].info
							);
							return {
								type: selectedType, //testsResults[j].type,
								id: testsResults[j].testUser.testUserId,
								email:
									information.email ||
									currentTestUser.email ||
									null,
							}; /* 'child'; */
						}
					} else if (
						testsResults[j].testUser.testUserId ===
							currentTestUser.id &&
						testsResults[j].recipient
					) {
						return {
							type: selectedType, //testsResults[j].type,
							email: testsResults[j].recipient,
							id: testsResults[j].testUser.testUserId,
						};
					}
				}
			}
		}
		return {
			type: selectedType, //testsResults[j].type,
			email: currentTestUser.email || null,
			id: currentTestUser.id,
		};
	}
	determineGender(currentTestUser, allTestUsers) {
		for (let i = 0; i < allTestUsers.length; i++) {
			const testsResults = allTestUsers[i].testResults;
			for (let j = 0; j < testsResults.length; j++) {
				if (testsResults[j].testUser) {
					if (
						testsResults[j].testUser.testUserId ===
							currentTestUser &&
						testsResults[j].info
					) {
						const information = JSON.parse(testsResults[j].info);
						if (testsResults[j].type !== 'child') {
							if (information.gender === 'm') {
								return (
									<td className="lit-list__col small">
										{strings.male}
									</td>
								);
							} else if (information.gender === 'f') {
								return (
									<td className="lit-list__col small">
										{strings.female}
									</td>
								);
							}
						} else if (information.gender === 'm') {
							return (
								<td className="lit-list__col small">
									{strings.boy}
								</td>
							);
						} else if (information.gender === 'f') {
							return (
								<td className="lit-list__col small">
									{strings.girl}
								</td>
							);
						}
					}
				}
			}
		}
		return null;
	}

	render() {
		const {onSaveBulk} = this.props;
		const UsersGroups = this.props.userGroups;
		const userLanguages = this.props.userLanguages[0];
		const groups = UsersGroups.map((userGroup) => {
			return userGroup.name;
		});
		const filteredTestUsers = this.props.testUsers;
		const {
			hasConfirmedCost,
			newTestCount,
			availableTestTypes,
			isAddingGroup,
		} = this.state;
		const cancelButton = (
			<button
				className="lit-btn bg-negative2"
				onClick={() => onSaveBulk()}
			>
				{strings.cancel}
			</button>
		);
		return (
			<div
				className="lit-modal"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<h3>Valgt Brukere</h3>
					<table id="testUsers_table" className="lit-list-user-list">
						<thead>
							<tr>
								<th>
									{strings.firstname +
										', ' +
										strings.lastname}
								</th>
								<th>{strings.email}</th>
								<th>{strings.telephone}</th>
								<th>{strings.gender}</th>
							</tr>
						</thead>
						<tbody>
							{filteredTestUsers.map((testUser) => {
								return (
									<tr
										className="lit-list__item"
										key={testUser.id}
									>
										<td className="lit-list__col small">
											{testUser.firstName &&
												`${testUser.firstName}, ${testUser.lastName}`}
										</td>
										<td className="lit-list__col small">
											{testUser.email}
										</td>
										<td className="lit-list__col small">
											{testUser.phone}
										</td>
										{this.determineGender(
											testUser.id,
											this.props
												.batchedFilteredTestResults
										)}
									</tr>
								);
							})}
						</tbody>
					</table>

					{!hasConfirmedCost && (
						/* isCreatingTests && */ <div className="lit_confirm-cost">
							<button
								className="lit-btn bg-primary"
								onClick={() => {
									if (
										Boolean(groups.length) &&
										!isAddingGroup
									) {
										this.setState({
											groupId: null,
											hasConfirmedCost: true,
											selectedLanguage:
												this.state.availableLanguage[0],
											type: this.state
												.availableTestTypes[0],
										});
									} else if (
										!groups.length &&
										!isAddingGroup
									) {
										this.setState({
											groupId: null,
											hasConfirmedCost: true,
											selectedLanguage:
												this.state.availableLanguage[0],
											type: this.state
												.availableTestTypes[0],
										});
									} else {
										this.setState({
											hasConfirmedCost: true,
										});
									}
								}}
							>
								{strings.confirm}
							</button>
							{/* {cancelButton} */}

							<button
								className="lit-btn bg-negative2"
								onClick={() => this.props.closeWindow()}
							>
								{strings.cancel}
							</button>
						</div>
					)}
					{hasConfirmedCost && (
						/* isCreatingTests && */ <form
							className="lit-form register-form"
							onSubmit={(event) => {
								event.preventDefault();
								const testTypes = [];
								let flag = 0;
								for (
									let i = 0;
									i < this.props.testUsers.length;
									i++
								) {
									const determineData =
										this.determineBulkTestKeys(
											this.state.type,
											this.props.testUsers[i],
											this.props
												.batchedFilteredTestResults
										);
									if (determineData) {
										testTypes.push(determineData);
									}

									flag = 1;
									/* if (testTypes[i].type !== this.state.type) {
										flag = 1;
									} */
								}

								if (flag === 1) {
									if (groups.length) {
										onSaveBulk(
											this.state.type,
											1,
											this.state.groupId,
											this.state.selectedLanguage,
											this.props.testUsers,
											testTypes,
											this.state.childSelfStarting
										);
									} else {
										onSaveBulk(
											this.state.type,
											1,
											null,
											this.state.selectedLanguage,
											this.props.testUsers,
											testTypes,
											this.state.childSelfStarting
										);
									}
								}
							}}
						>
							<div className="lit-input">
								{this.state.availableLanguage.length > 1 && (
									<div className="lit-input inline">
										<label className="lit-input__groupname-label">
											{strings.chooselanguage}
										</label>
										<select
											className="lit-input__field"
											defaultValue={
												this.state.availableLanguage[0]
											}
											name="language"
											onChange={(event) => {
												this.setState({
													selectedLanguage:
														event.target.value,
												});
												this.setAvailableTypes(
													userLanguages[
														event.target.value
													]
												);
											}}
										>
											{this.state.availableLanguage.map(
												(language) => (
													<option
														key={language}
														value={language}
													>
														{language}
													</option>
												)
											)}
										</select>
									</div>
								)}

								<div className="lit-input inline">
									<label className="lit-input__label">
										{strings.test_type}
									</label>
									<select
										className="lit-input__field"
										defaultValue={this.state.type}
										name="type"
										onChange={(event) => {
											this.setState({
												type: event.target.value,
											});
										}}
									>
										{Object.entries(TEST_TYPES)
											.filter(([key, value]) =>
												availableTestTypes.includes(
													value
												)
											)
											.map(([key, value]) => (
												<option key={key} value={value}>
													{TEST_TYPE_NAMES[value]}
												</option>
											))}
									</select>
								</div>

								{Boolean(groups.length) && (
									<div className="lit-input inline">
										<label className="lit-input__label">
											{strings.choosegroup}
										</label>
										<input
											type="checkbox"
											className="lit-input_checkbox"
											name="velg gruppet"
											onChange={(e) => {
												if (e.target.checked) {
													this.setState({
														isAddingGroup: true,
														groupId:
															UsersGroups[0].id,
													});
												} else {
													this.setState({
														groupId: 0,
														isAddingGroup: false,
													});
												}
											}}
										/>
									</div>
								)}

								<div>
									{isAddingGroup && (
										<div className="lit-input inline">
											<label className="lit-input__groupname-label">
												{'Gruppenavn'}
											</label>
											<select
												className="lit-input__field"
												defaultValue={groups[0]}
												name="group"
												onChange={(event) => {
													var chosenGroup =
														UsersGroups.filter(
															(userGroup) =>
																userGroup.name ===
																event.target
																	.value
														);

													this.setState({
														groupId:
															chosenGroup[0].id,
													});
												}}
											>
												{groups.map((group) => (
													<option
														key={group}
														value={group}
													>
														{group}
													</option>
												))}
											</select>
										</div>
									)}
								</div>
							</div>

							{testsWithInstruction.includes(this.state.type) && (
								<div>
									<div className="lit-input-marginal">
										<div className="lit-input inline">
											<label className="lit-input__label">
												<b>
													{
														strings.fillSelfCandidateInfo
													}
												</b>
											</label>
											<input
												checked={
													this.state
														.infoCandidateAdmin
												}
												type="radio"
												className="lit-input_checkbox"
												name="velg anamnese"
												onChange={(e) => {
													if (e.target.checked) {
														this.setState({
															infoCandidateAdmin: true,
															childSelfStarting: false,
														});
													}
												}}
											/>
										</div>
										<div>
											<p>
												{
													strings.fillKandidateInformation1
												}
											</p>
										</div>
										<div className="lit-input inline">
											<label className="lit-input__label">
												<b>
													{
														strings.letCandidateFillInfo
													}
												</b>
											</label>
											<input
												checked={
													this.state.childSelfStarting
												}
												type="radio"
												className="lit-input_checkbox"
												name="velg anamnese"
												onChange={(e) => {
													if (e.target.checked) {
														this.setState({
															childSelfStarting: true,
															infoCandidateAdmin: false,
														});
													}
												}}
											/>
										</div>
										<div>
											<p>
												{
													strings.fillKandidateInformationSelf1
												}
											</p>
										</div>
										<div>
											<p>
												{
													strings.fillKandidateInformationSelf2
												}
											</p>
										</div>
									</div>
									<div>
										<p>{strings.candidateInfoCommon}</p>
										<p>{strings.candidateInfoReminder}</p>
									</div>
								</div>
							)}
							<div className="lit-input">
								<div>
									<button
										className="lit-btn bg-primary"
										disabled={
											//TODO: Enable after the max number of tests are determined
											newTestCount !== 1
										}
									>
										{`${
											newTestCount > 1
												? strings.create_tests
												: strings.createnewtest
										}`}
									</button>
									{cancelButton}
								</div>
							</div>
						</form>
					)}
				</div>
			</div>
		);
	}
}
export default TestDispatchModal;
