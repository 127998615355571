import React from 'react';
import {connect} from 'react-redux';
import {SEARCHBYTESTKEY, FEIDEDROPDOWN} from 'constants/index';
import './styles.scss';
import SearchByTestKey from 'pages/SearchByTestKey';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';
import strings from 'utils/strings';
import DumpTests from 'pages/MyTests/DumpTests';
import FeideTwo from 'components/FeideTwo';

const testsFilters = [SEARCHBYTESTKEY, FEIDEDROPDOWN];

class Tests extends React.Component {
	constructor() {
		super();

		this.state = {
			activeFilter: SEARCHBYTESTKEY,
			isDumpingTests: false,
		};
	}

	render() {
		const {activeFilter, isDumpingTests} = this.state;

		return (
			<div>
				<div className="lit-test-section-drop-down">
					<div className="lit-dropdown_filter">
						<select
							className="lit-input__field"
							defaultValue={SEARCHBYTESTKEY}
							onChange={(e) => {
								this.setState({
									activeFilter: testsFilters.filter(
										(filter) => filter.id === e.target.value
									)[0],
								});
							}}
						>
							{testsFilters.map((userStageFilter) => (
								<option
									key={userStageFilter.id}
									value={userStageFilter.id}
								>
									{userStageFilter.label ||
										userStageFilter.text}
								</option>
							))}
						</select>
					</div>
					<div>
						<button
							className="lit-mytests-header-mytest-btn-ternary"
							onClick={() => {
								this.setState({
									isDumpingTests: true,
								});
							}}
						>
							<FontAwesomeIcon
								className="lit-icon-btns"
								icon={faXmark}
							/>
							<p className="lit-btns-txt">
								{strings.deleteoldtests}
							</p>
						</button>
					</div>
				</div>

				{activeFilter === SEARCHBYTESTKEY && (
					<div className="lit-search-by-test-key">
						<SearchByTestKey />
					</div>
				)}
				{activeFilter === FEIDEDROPDOWN && (
					<div className="lit-search-by-test-key">
						<FeideTwo />
					</div>
				)}
				{isDumpingTests && (
					<DumpTests
						closeWindow={() => {
							this.setState({
								isDumpingTests: false,
							});
						}}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Tests);
