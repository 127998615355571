import moment from 'moment';
import {ENDPOINT_URL} from 'constants/index';
import getFetchOptions from 'utils/getFetchOptions';
import {apiErrorHandling} from 'utils/apiUtils';

export default function getSingleChildTestResult(testResultId) {
	return fetch(
		`${ENDPOINT_URL}/childtest/${testResultId}/results`,
		getFetchOptions({
			method: 'GET',
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.then((testResult) => {
			const testUser = testResult.testUser;
			if (testUser.hasOwnProperty('birthdate')) {
				let totalmonths;
				if (testResult.completedAt) {
					totalmonths = moment(testResult.completedAt).diff(
						moment(testUser.birthdate),
						'months'
					);
				} else {
					totalmonths = moment(testResult.usedAt).diff(
						moment(testUser.birthdate),
						'months'
					);
				}

				const years = Math.floor(totalmonths / 12);
				const months = totalmonths % 12;
				testUser.age = {years, months};

				return Promise.resolve(testResult);
			} else if (testUser.age && testUser.age.years) {
				const years = testUser.age.years;
				const months = testUser.age.months;
				testUser.age = {years, months};

				return Promise.resolve(testResult);
			} else {
				const years = '--';
				const months = '--';
				testUser.age = {years, months};

				return Promise.resolve(testResult);
			}
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
