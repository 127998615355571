import React from 'react';
import './styles.scss';
import strings from 'utils/strings';
import _ from 'lodash';
import getTestAdminActivity from 'api/getTestAdminActivity';
import moment from 'moment';
import updateLogEvent from 'api/updateLogEvent';
import {Link} from 'react-router-dom';

import {
	USER_STAGES_INFO,
	ALL,
	NEEDS_APPROVAL,
	AWAITING_CONFIRMATION,
	TRIAL,
	BASIC,
	NORMING,
	PRODUKSJON,
	PROD_NORMING,
	ACTIVE,
	INACTIVE,
	TEST_TYPES,
	language,
} from 'constants/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faXmark} from '@fortawesome/free-solid-svg-icons';
const NEEDS_APPROVAL_INFO = USER_STAGES_INFO[NEEDS_APPROVAL];
const AWAITING_CONFIRMATION_INFO = USER_STAGES_INFO[AWAITING_CONFIRMATION];
const ACTIVE_INFO = USER_STAGES_INFO[ACTIVE];
const TRIAL_INFO = USER_STAGES_INFO[TRIAL];
const BASIC_INFO = USER_STAGES_INFO[BASIC];
const NORMING_INFO = USER_STAGES_INFO[NORMING];
const PRODUCTION_INFO = USER_STAGES_INFO[PRODUKSJON];
const PROD_NORMING_INFO = USER_STAGES_INFO[PROD_NORMING];

const INACTIVE_INFO = USER_STAGES_INFO[INACTIVE];

const userStageFilters = [
	ALL,
	NEEDS_APPROVAL_INFO,
	AWAITING_CONFIRMATION_INFO,
	TRIAL_INFO,
	BASIC_INFO,
	ACTIVE_INFO,
	NORMING_INFO,
	PRODUCTION_INFO,
	PROD_NORMING_INFO,
	INACTIVE_INFO,
];

const childTypes = [
	TEST_TYPES.CHILD_FOLLOWUP,
	TEST_TYPES.YOUTH_FOLLOWUP,
	TEST_TYPES.ADULT_FOLLOWUP,
];
class TestAdminActivity extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			/* allAdminUsers: this.props.testAdmins, */
			selectedUser: '',
			testAdminsData: [],
			filteredAdminsData: [],
			allData: null,
			searchFilter: '',
			activeFilter: ALL,
			selectedTestFilter: '',
			testCount: '',
			completedTestsCount: '',
			selectedActivityOption: '',
			isActivityDropDownOpen: false,
		};
	}
	componentDidMount() {
		document.addEventListener('click', this.handleOutsideClick);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleOutsideClick);
	}
	handleOutsideClick = (event) => {
		if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
			this.setState({isActivityDropDownOpen: false});
		}
	};
	getAndSetActivity = async (subscription) => {
		getTestAdminActivity(subscription).then((results) => {
			return new Promise((resolve) => {
				if (Boolean(results.length)) {
					const values = results.filter((data) => {
						if (data.isCompleted === 1) return data;
					});
					this.setState(
						{
							testAdminsData: results,
							filteredAdminsData: results,
							testCount: results.length,
							completedTestsCount: values.length,
						},
						resolve
					);
				} else {
					this.setState(
						{
							testAdminsData: [],
							filteredAdminsData: [],
							testCount: results.length,
							completedTestsCount: results.length,
						},
						resolve
					);
				}
			});
		});
	};
	renderButton(type, id, testKey) {
		const isChildType = childTypes.includes(type);

		const destination = isChildType
			? `/single-child-test/${id}`
			: `/single-test/${id}`;

		return (
			<div className="lit-rendered_seeTest">
				<Link
					className="lit-btn lit-btn--small bg-secondary"
					to={destination}
					onClick={() => this.handleClick(id, testKey)}
					target="_blank"
					rel="noopener noreferrer"
				>
					{strings.seeresults}
				</Link>
			</div>
		);
	}
	handleClick = (id, key) => {
		this.updateViewLog(id, key);
	};

	updateViewLog(id, key) {
		updateLogEvent('view', id, key, 'Results');
	}
	renderTableHeader() {
		return (
			<div className="lit-myClass-activity-header-grid">
				<div className="lit-myClass-activity-header-wrapper">
					<div className="myClass_activity-table-header">
						{`${strings.firstname}, ${strings.lastname}`}
					</div>
					<div className="myClass_activity-table-header">
						{strings.organization}
					</div>
					<div className="myClass_activity-table-header">
						{strings.email}
					</div>
					<div className="myClass_activity-table-header">
						{strings.telephone}
					</div>
					<div className="custom_btn-cell" />
				</div>
			</div>
		);
	}
	renderTableRows(testAdmin) {
		testAdmin.expanded = testAdmin.expanded ? true : false;
		return (
			<div>
				<div
					className={
						testAdmin.expanded &&
						this.state.selectedUser === testAdmin.subscription.id
							? 'lit-activity_grid-active'
							: 'lit-activity_grid'
					}
					id={testAdmin.id}
					onClick={() => {
						if (!testAdmin.expanded) {
							testAdmin.expanded = true;

							this.setState({
								selectedUser: testAdmin.subscription.id,
								selectedTestFilter: '',
								/* allAdminUsers: this.props.testAdmins, */
							});
							this.getAndSetActivity(testAdmin.subscription.id);
						} else if (testAdmin.expanded) {
							testAdmin.expanded = false;

							this.setState({
								selectedUser: testAdmin.subscription.id,
								/* 	allAdminUsers: this.props.testAdmins, */
							});
						}
					}}
				>
					<div className="myClass-activity-table-cell">
						{(testAdmin.firstName &&
							`${testAdmin.firstName}, ${testAdmin.lastName}`) ||
							'-'}
					</div>
					<div className="myClass-activity-table-cell">
						{testAdmin.organization || '-'}
					</div>
					<div className="myClass-activity-table-cell">
						{testAdmin.email || '-'}
					</div>
					<div className="myClass-activity-table-cell">
						{testAdmin.phone || '-'}
					</div>
					<div className="custom_btn-cell">
						<button
							className={
								testAdmin.expanded === true
									? 'lit-btn lit-btn--small bg-primary3-red'
									: 'lit-btn lit-btn--small bg-primary3'
							}
						>
							{testAdmin.expanded === true &&
							this.state.selectedUser ===
								testAdmin.subscription.id ? (
								<span>{strings.hideWithArrow}</span>
							) : (
								<span>{strings.showtests}</span>
							)}
						</button>
					</div>
				</div>
				{testAdmin.expanded === true &&
					this.state.selectedUser === testAdmin.subscription.id &&
					this.renderSubTable()}
			</div>
		);
	}
	renderSubTable() {
		const stateData = _.cloneDeep(this.state.filteredAdminsData);
		return (
			<div className="lit-activity-table-container">
				<div className="lit-input-testType">
					<div className="lit-activity-subtable-input-wrapper">
						<div>
							<select
								className="lit-input__field"
								defaultValue={strings.all}
								onChange={(e) => {
									if (e.target.value !== strings.all)
										this.setState({
											selectedTestFilter: e.target.value,
										});
									this.getFilteredTests(e.target.value);
								}}
							>
								<option disabled value={strings.type}>
									{strings.type}
								</option>
								<option key={0} value={strings.all}>
									{strings.all}
								</option>
								<option key={1} value={'oppfølging'}>
									{'Oppfølging (alle)'}
								</option>
								<option key={2} value={'screening'}>
									{'Screening (alle)'}
								</option>
								<option key={3} value={'child-followup'}>
									{'Child-followup'}
								</option>
								<option key={4} value={'child-screening'}>
									{'Child-screening'}
								</option>
								<option key={5} value={'youth-screening'}>
									{'Youth-screening'}
								</option>
								<option key={6} value={'youth-followup'}>
									{'Youth-followup'}
								</option>
								<option key={7} value={'adult-screening'}>
									{'Adult-screening'}
								</option>
								<option key={8} value={'adult-followup'}>
									{'Adult-followup'}
								</option>
							</select>
						</div>
					</div>
					<div className="lit-subTable_identifiers">
						<div className="lit-input-testCount-heading">
							<label>{strings.used}</label>
						</div>
						<div className="lit-input-testCount-info">
							<label>{this.state.testCount}</label>
						</div>
						<div className="lit-input-testCount-heading">
							<label>{strings.completeplural} </label>
						</div>
						<div className="lit-input-testCount-info">
							<label>{this.state.completedTestsCount}</label>
						</div>
					</div>
				</div>

				<div className="lit-individual-test">
					{Boolean(stateData.length) && (
						<table className="lit-list-user-activity-subtable-list">
							<thead>
								<tr>
									<th className="lit-test-activity-subtable-header">
										{strings.testkey}
									</th>
									<th className="lit-test-activity-subtable-header">
										{strings.type}
									</th>
									<th className="lit-test-activity-subtable-header">
										{strings.completed}
									</th>
									<th className="lit-test-activity-subtable-header">
										{strings.date}
									</th>
									<th className="lit-test-activity-subtable-handler">
										{strings.action}
									</th>
								</tr>
							</thead>
							<tbody>
								{stateData.map((candidate) => {
									const {
										id,
										testKey,
										isCompleted,
										type,
										completedAt,
										usedAt,
									} = candidate;
									return (
										<tr
											className="lit-test-activity-subtable-row"
											key={id}
										>
											<td className="lit-test-activity-subtable-data">
												{testKey}
											</td>

											<td className="lit-test-activity-subtable-data">
												{type}
											</td>
											{isCompleted ? (
												<td className="lit-test-activity-subtable-data">
													<div className="lit-activity-subtable-status-mark">
														<FontAwesomeIcon
															className="lit-icon-btns"
															icon={faCheck}
															color="green"
														/>
													</div>
												</td>
											) : (
												<td className="lit-test-activity-subtable-data">
													<div className="lit-activity-subtable-status-mark">
														<FontAwesomeIcon
															className="lit-icon-btns"
															icon={faXmark}
															color="red"
														/>
													</div>
												</td>
											)}
											<td className="lit-test-activity-subtable-data">
												{completedAt
													? moment(
															completedAt
													  ).format('LL')
													: moment(usedAt).format(
															'LL'
													  )}
											</td>
											<td className="lit-test-activity-subtable-handler">
												<div className="lit-activity__field">
													{this.renderButton(
														type,
														id,
														testKey
													)}
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					)}
					{!stateData.length && (
						<div className="lit-individual-test-not-available">
							<p>
								{strings.formatString(
									strings.nousedtests,
									`${this.state.selectedTestFilter}`
								)}
							</p>
						</div>
					)}
				</div>
			</div>
		);
	}

	getFilteredTests = (filter) => {
		if (filter !== 'Alle') {
			if (filter === 'oppfølging') {
				const values = this.state.testAdminsData.filter((data) => {
					if (data.type.includes('followup')) return data;
				});

				const completedCount = values.filter((data) => {
					if (data.isCompleted) return data;
				});
				if (values.length) {
					this.setState({
						filteredAdminsData: values,
						testCount: values.length,
						completedTestsCount: completedCount.length,
					});
				} else {
					this.setState({
						filteredAdminsData: [],
						testCount: values.length,
						completedTestsCount: completedCount.length,
					});
				}
			} else if (filter === 'screening') {
				const values = this.state.testAdminsData.filter((data) => {
					if (data.type.includes('screening')) return data;
				});
				const completedCount = values.filter((data) => {
					if (data.isCompleted) return data;
				});
				if (values.length) {
					this.setState({
						filteredAdminsData: values,
						testCount: values.length,
						completedTestsCount: completedCount.length,
					});
				} else {
					this.setState({
						filteredAdminsData: [],
						testCount: values.length,
						completedTestsCount: completedCount.length,
					});
				}
			} else {
				const values = this.state.testAdminsData.filter((data) => {
					if (data.type === filter) return data;
				});
				const completedCount = values.filter((data) => {
					if (data.isCompleted) return data;
				});
				if (values.length) {
					this.setState({
						filteredAdminsData: values,
						testCount: values.length,
						completedTestsCount: completedCount.length,
					});
				} else {
					this.setState({
						filteredAdminsData: [],
						testCount: values.length,
						completedTestsCount: completedCount.length,
					});
				}
			}
		} else {
			const completedCount = this.state.testAdminsData.filter((data) => {
				if (data.isCompleted) return data;
			});
			this.setState({
				filteredAdminsData: this.state.testAdminsData,
				testCount: this.state.testAdminsData.length,
				completedTestsCount: completedCount.length,
			});
		}
	};
	getFilteredTestAdmins = () => {
		const {activeFilter} = this.state;
		const {testAdmins} = this.props;

		let filteredTestAdmins = this.props.selectedUsers.length
			? this.props.selectedUsers
			: testAdmins;

		if (activeFilter !== ALL) {
			filteredTestAdmins = testAdmins.filter(
				(testAdmin) => testAdmin.userStage === activeFilter.id
			);
		}

		filteredTestAdmins = filteredTestAdmins.filter((testAdmin) => {
			const searchableFields = [
				'firstName',
				'lastName',
				'phone',
				'email',
				'organization',
			];

			for (const field of searchableFields) {
				if (
					testAdmin[field] &&
					testAdmin[field]
						.toLowerCase()
						.indexOf(this.state.searchFilter) !== -1
				) {
					return true;
				}
			}
			return false;
		});

		return filteredTestAdmins;
	};
	toggleActivityDropdown = () => {
		this.setState((prevState) => ({
			isActivityDropDownOpen: !prevState.isActivityDropDownOpen,
		}));
	};

	render() {
		const {selectedActivityOption, isActivityDropDownOpen} = this.state;

		const filteredTestAdmins = this.getFilteredTestAdmins();
		return (
			<div className="lit-tests-view__header">
				<div className="lit-new-test-activity__wrapper">
					<div className="lit-input-tab-grid">
						<div className="lit-View_tests">
							<div
								className="select-container"
								ref={(node) => (this.dropdownRef = node)}
							>
								<div
									className="select-display"
									style={{width: '235px'}}
									onClick={this.toggleActivityDropdown}
								>
									{selectedActivityOption || ALL.label}
									<span className="dropdown-arrow">▼</span>
								</div>
								{isActivityDropDownOpen && (
									<div className="dropdown-list">
										{userStageFilters.map(
											(userStageFilter, index) => (
												<div
													className={`dropdown-item ${
														userStageFilter.label ===
														ALL.label
															? 'disabled'
															: ''
													}`}
													onClick={() => {
														this.setState({
															selectedActivityOption:
																userStageFilter.label ||
																userStageFilter.text,
															isActivityDropDownOpen: false,
															activeFilter:
																userStageFilters.filter(
																	(filter) =>
																		filter.id ===
																		userStageFilter.id
																)[0],
														});
													}}
													key={index}
												>
													{userStageFilter.label ||
														userStageFilter.text}
												</div>
											)
										)}
									</div>
								)}
							</div>
							<input
								className="lit-tf_search"
								type="text"
								placeholder={strings.searchhere}
								id="testkey"
								onChange={(e) => {
									this.setState({
										searchFilter:
											e.target.value.toLowerCase(),
									});
								}}
							/>
						</div>
					</div>
					<div className="lit-btn-activity-grid">
						{!filteredTestAdmins.length && (
							<p>{strings.message_noclasses}</p>
						)}
						{Boolean(filteredTestAdmins.length) && (
							<div className="lit-main-activity-contianer">
								<div className="lit_myClass-activity-table-wrapper">
									{this.renderTableHeader()}
									<div className="lit-activity-tableRows-scroll">
										{filteredTestAdmins.map(
											(data, index) => {
												return (
													<div
														key={index}
														className="lit-dynamic-users-tablerows"
													>
														{this.renderTableRows(
															data
														)}
													</div>
												);
											}
										)}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
export default TestAdminActivity;
