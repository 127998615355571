import {ENDPOINT_URL} from 'constants/index';
import {apiErrorHandling} from 'utils/apiUtils';
import getFetchOptions from 'utils/getFetchOptions';

export default function getEventLog(pageNumber) {
	return fetch(
		`${ENDPOINT_URL}/eventlog?page=${pageNumber}`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify({
				pageNumber,
			}),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
