import React, {Component} from 'react';
import './styles.scss';

class AddNotesModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addNotes: false,
		};
	}

	handleYes = () => {
		this.setState({addNotes: true});
	};

	handleNo = () => {
		this.props.toggleModal();
		window.print();
	};

	handleInputChange = (e) => {
		this.props.setReportNotes(e.target.value);
	};

	handlePrintWithNotes = () => {
		this.props.toggleModal();
		window.print();
	};

	render() {
		const {addNotes} = this.state;
		const {reportNotes} = this.props;
		return (
			<div
				className="modal-overlay"
				onClick={() => {
					this.props.toggleModal();
				}}
			>
				<div className="lit-options-container">
					<div
						className="modal-content"
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						{!addNotes ? (
							<>
								<h3>Vil du legge til notater før utskrift?</h3>
								<div className="modal-buttons-div">
									<div className="lit-option-pane">
										<button
											style={{color: 'black'}}
											className="__approve"
											onClick={this.handleYes}
										>
											Ja
										</button>
									</div>

									<div className="lit-option-pane">
										<button
											className="__disprove"
											onClick={this.handleNo}
										>
											Nei
										</button>
									</div>
								</div>
							</>
						) : (
							<>
								<h3>Legg til notatene dine</h3>
								<textarea
									className="notes-input"
									placeholder="Skriv inn notatene dine her..."
									value={reportNotes}
									onChange={this.handleInputChange}
								/>
								<div className="modal-buttons-div">
									<div className="modal-button">
										<button
											disabled={!reportNotes}
											className="btn-primary"
											onClick={this.handlePrintWithNotes}
										>
											Skriv ut med notater
										</button>
									</div>
									<div className="modal-button">
										<button
											className="btn-secondary"
											onClick={this.props.toggleModal}
										>
											Kansellere
										</button>
									</div>
								</div>
							</>
						)}
					</div>
				</div>{' '}
			</div>
		);
	}
}

export default AddNotesModal;
